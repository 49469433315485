import { Link } from "react-router-dom";

const SponsorCard = ({ featured }) => {
  return (
    <div className="text-formisPeach bg-formisBlack">
      <h5 className="text-sm font-bold mb-6 px-4">
        Curated Blogs Just for You.
      </h5>

      <div className="flex items-center overflow-x-scroll gap-4 pb-5 scrollbar-hide px-4">
        {featured?.slice(0, 2).map((result) => {
          const formatTitle = (title) => {
            return title
              .toLowerCase()
              .replace(/\s+/g, "-")
              .replace(/[^\w\-]+/g, "");
          };

          const formattedTitle = formatTitle(result.title);

          return (
            <Link
              to={`/blog/${formattedTitle}`}
              className="relative w-full shrink-0 border border-[#666666] h-[200px] rounded-[10px] shadow-peachBox bg-cover bg-no-repeat object-cover"
              key={result.id}
            >
              <div className="absolute h-full w-full rounded-[inherit] top-0 left-0 bg-formisBlack/70 flex flex-col justify-between p-3">
                <p className="text-sm font-bold place-self-end">
                  {result.title}
                </p>
                <button className="place-self-end text-formisBlack relative font-[Hind] font-bold text-[11px] bg-formisPeach h-[28px] flex items-center justify-center px-4 rounded-[8px] shadow-md shadow-formisOrange">Read</button>
              </div>
              <img
                src={result.media_file}
                alt=""
                className="w-full h-full rounded-[inherit] object-cover"
              />
            </Link>
          );
        })}
      </div>

      <div className="flex items-center overflow-x-scroll gap-4 scrollbar-hide px-4 pb-5">
        {featured?.slice(2, 4).map((result) => {
          const formatTitle = (title) => {
            return title
              .toLowerCase()
              .replace(/\s+/g, "-")
              .replace(/[^\w\-]+/g, "");
          };

          const formattedTitle = formatTitle(result.title);

          return (
            <Link
              to={`/blog/${formattedTitle}`}
              className="relative w-full shrink-0 border border-[#666666] h-[200px] rounded-[10px] shadow-peachBox bg-cover bg-no-repeat object-cover"
              key={result.id}
            >
              <div className="absolute h-full w-full rounded-[inherit] top-0 left-0 bg-formisBlack/70 flex flex-col justify-between p-3">
                <p className="text-sm font-bold place-self-end">
                  {result.title}
                </p>
                <button className="place-self-end text-formisBlack relative font-[Hind] font-bold text-[11px] bg-formisPeach h-[28px] flex items-center justify-center px-4 rounded-[8px] shadow-md shadow-formisOrange">Read</button>
              </div>
              <img
                src={result.media_file}
                alt=""
                className="w-full h-full rounded-[inherit] object-cover"
              />
            </Link>
          );
        })}
      </div>

    </div>
  );
};

export default SponsorCard;
