import { BiTargetLock } from "react-icons/bi";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import {
  setAddressStep,
  setCoordinates,
  setLocationPermission,
} from "../../features/mapSlice";
import { useGeolocation } from "../../hooks/useGeolocation";
import { useEffect, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { PiBuildingApartmentFill } from "react-icons/pi";

const LocationPageButtons = () => {
  const isAuthenticated = localStorage.getItem("isAuthenticated") === "true";
  const dispatch = useDispatch();
  const { addressStep } = useSelector((state) => state.googleMapLocation);
  const { getGeolocation, locationInfo, locationError } = useGeolocation();

  const [loading, setLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [changeMessage, setChangeMessage] = useState(false);
  const [allowClicked, setAllowClicked] = useState(false);
  const [showSteps, setShowSteps] = useState(false);

  useEffect(() => {
    const clicked = localStorage.getItem("allowClicked") === "true";
    setAllowClicked(clicked);
  }, []);

  const waitForLocation = () => {
    if (locationInfo) {
      dispatch(
        setCoordinates({
          lat: locationInfo.latitude,
          lng: locationInfo.longitude,
        })
      );
      dispatch(setAddressStep(1));
      setLoading(false);
    }
    setShowPopup(false);
  };

  const handleCurrentLocation = () => {
    setLoading(true);
    setChangeMessage(false);
    setAllowClicked(true);
    localStorage.setItem("allowClicked", "true");
    getGeolocation();
  };

  useEffect(() => {
    waitForLocation();
    setLoading(false);
  }, [locationInfo]);

  useEffect(() => {
    if (allowClicked) {
      navigator.permissions.query({ name: "geolocation" }).then((result) => {
        if (result.state === "denied") {
          setLoading(false);
          setShowSteps(true);
          dispatch(setLocationPermission(false));
        }
      });
    }
  }, [allowClicked, locationInfo, locationError]);

  return (
    <div className="bg-formisWhite text-formisBlack mx-3 rounded-[10px]">
      <div>
        <button
          className="p-3 flex items-center justify-between w-full"
          onClick={() => setShowPopup(!showPopup)}
        >
          <div className="flex items-center gap-5 font-[600]">
            <BiTargetLock className="text-xl" />
            <div>
              {loading ? (
                <div className="loader2"></div>
              ) : (
                <p className="text-xs">Use Your Current Location</p>
              )}
            </div>
          </div>
          <MdOutlineKeyboardArrowRight className="text-2xl" />
        </button>

        {/* POPUP TO ASK IF USER WILL ALLOW LOCATION ACCESS */}
        <div
          className={`text-xs transition-all duration-300 shadow-custom rounded-[10px] ${
            showPopup
              ? "opacity-100 max-h-40 mx-3 p-3 mb-3"
              : "opacity-0 max-h-0 pointer-events-none"
          }`}
        >
          <p className="font-bold text-center mb-2">Allow Location Access</p>
          {!changeMessage ? (
            <p className="text-center mb-2 font-[500]">
              To provide services tailored to your area, please click{" "}
              <b>"Allow"</b> when prompted.
            </p>
          ) : (
            <p className="text-center mb-2 font-[600]">
              Are you sure you don’t want to allow access? Without it, you'll
              need to manually search and set your location.
            </p>
          )}
          <div className="flex gap-3 items-center justify-center">
            <button
              className="bg-[#203040] text-formisPeach px-4 py-2 rounded-[10px] font-bold"
              onClick={handleCurrentLocation}
            >
              Allow
            </button>
            <button
              className="bg-[#203040] text-formisPeach px-4 py-2 rounded-[10px] font-bold"
              onClick={() => {
                if (changeMessage) {
                  setShowPopup(false);
                  setChangeMessage(false);
                  dispatch(setAddressStep(3));
                } else {
                  setChangeMessage(true);
                }
              }}
            >
              Don't Allow
            </button>
          </div>
        </div>
      </div>

      <hr className="seperator blur-0" />

      <button
        className="p-3 flex items-center justify-between w-full"
        onClick={() => dispatch(setAddressStep(1))}
      >
        <div className="flex items-center gap-5 font-[600]">
          <AiOutlinePlus className="text-xl" />
          <p className="text-xs">Add New Address</p>
        </div>
        <MdOutlineKeyboardArrowRight className="text-2xl" />
      </button>

      <hr className="seperator blur-0" />

      {addressStep !== 2 && isAuthenticated && (
        <button
          className="p-3 flex items-center justify-between w-full"
          onClick={() => dispatch(setAddressStep(2))}
        >
          <div className="flex items-center gap-5 font-[600]">
            <PiBuildingApartmentFill className="text-xl" />
            <p className="text-xs">View/Manage Saved Addresses</p>
          </div>
          <MdOutlineKeyboardArrowRight className="text-2xl" />
        </button>
      )}

      {/* If Never Allow Clicked */}
      {showSteps && (
        <div className="absolute h-full w-full top-0 left-0">
          <div
            className="absolute top-0 left-0 bg-formisBlack/40 h-full w-full"
            onClick={() => {
              setShowSteps(false);
              dispatch(setAddressStep(3));
              localStorage.setItem("allowClicked", "false");
            }}
          />
          <StepsToEnableLocation setShowSteps={setShowSteps} />
        </div>
      )}
    </div>
  );
};

export default LocationPageButtons;

const StepsToEnableLocation = ({ setShowSteps }) => {
  const dispatch = useDispatch();
  return (
    <div className="relative top-5 z-10 bg-[#203040] text-xs text-formisPeach mx-3 px-3 py-6 rounded-[10px]">
      <h6 className="font-bold mb-2">
        You previously denied location access. To enable it again, please follow
        the instructions below.
      </h6>
      <p className="mb-5 font-[500]">No problem! Here are your options:</p>

      <div className="pl-5 mb-5">
        <p className="font-bold">1. Enable Location Access</p>
        <p className="font-medium">Follow these steps based on your browser:</p>
        <ul className="pl-5">
          <li>
            <p className="font-bold mb-[6px]">
              <span className="text-xl font-bold leading-none">•</span> Gooogle
              Chrome:
            </p>
            <div className="pl-5 font-[Hind]">
              <p className="leading-none">
                <span className="text-xl font-bold leading-none">•</span> Click
                the <b>Lock Icon</b> in the address bar.
              </p>
              <p className="leading-none">
                <span className="text-xl font-bold leading-none">•</span> Select{" "}
                <b>Site Settings</b> &gt; Under "Permissions," set{" "}
                <b>Location</b> to <b>Allow.</b>
              </p>
              <p className="leading-none">
                <span className="text-xl font-bold leading-none">•</span>{" "}
                Refresh the page.
              </p>
            </div>
          </li>
          <li>
            <p className="font-bold mb-[6px]">
              <span className="text-xl font-bold leading-none">•</span> Safari
              (Mac/iPhone):
            </p>
            <div className="pl-5 font-[Hind]">
              <p className="leading-none">
                <span className="text-xl font-bold leading-none">•</span> Go to{" "}
                <b>Settings &gt; Privacy & Security &gt; Location Services.</b>
              </p>
              <p className="leading-none">
                <span className="text-xl font-bold leading-none">•</span> Find
                your browser (Safari) and enable location for the website.
              </p>
            </div>
          </li>
          <li>
            <p className="font-bold mb-[6px]">
              <span className="text-xl font-bold leading-none">•</span> Firefox:
            </p>
            <div className="pl-5 font-[Hind]">
              <p className="leading-none">
                <span className="text-xl font-bold leading-none">•</span> Click
                the <b>Shield Icon</b> in the address bar.
              </p>
              <p className="leading-none">
                <span className="text-xl font-bold leading-none">•</span> Select{" "}
                <b>Permissions &gt; Allow Location Access.</b>
              </p>
              <p className="leading-none">
                <span className="text-xl font-bold leading-none">•</span>{" "}
                Refresh the page.
              </p>
            </div>
          </li>
          <li>
            <p className="font-bold mb-[6px]">
              <span className="text-xl font-bold leading-none">•</span>{" "}
              Microsoft Edge
            </p>
            <div className="pl-5 font-[Hind]">
              <p className="leading-none">
                <span className="text-xl font-bold leading-none">•</span> Click
                the <b>Lock Icon</b> in the address bar.
              </p>
              <p className="leading-none">
                <span className="text-xl font-bold leading-none">•</span> Go to{" "}
                <b>Site Permissions &gt; Set Location to Allow.</b>
              </p>
              <p className="leading-none">
                <span className="text-xl font-bold leading-none">•</span>{" "}
                Refresh the page.
              </p>
            </div>
          </li>
        </ul>
      </div>
      <div className="pl-5">
        <p className="font-bold">2. Manually Search and Set Your Location</p>
        <p className="font-medium mb-2">
          If you’d rather not enable location access, you can manually search
          and set your location:
        </p>
        <ul className="pl-5 font-[Hind]">
          <li>
            <span className="text-xl font-bold leading-none">•</span> Use the
            search bar to enter your city or area.
          </li>
          <li>
            <span className="text-xl font-bold leading-none">•</span> Drop a pin
            on the map to confirm your exact location.
          </li>
        </ul>
      </div>

      <div className="flex items-center justify-center mt-5">
        <button
          onClick={() => {
            setShowSteps(false);
            localStorage.setItem("allowClicked", "false");
            dispatch(setAddressStep(3));
          }}
          className="border border-[#666666] rounded-[10px] px-5 py-2 font-bold"
        >
          Close
        </button>
      </div>
    </div>
  );
};
