import { lazy, Suspense, useEffect, useState } from "react";

import { PiMapPinLineFill } from "react-icons/pi";
import heart from "../assets/icons/heart.svg";
import MobileFooter from "../components/MobileFooterMenu";

import bookmark from "../assets/icons/bookmark.svg";
import home from "../assets/icons/home-icon.svg";
import back from "../assets/icons/back-peach.svg";
import { Link } from "react-router-dom";
import Button from "../components/Order Track Components/Button";

import ProductCard from "../components/Order Track Components/ProductCard";
import { featuredSectionsApi } from "../api";
import SponsorCard from "../components/Main Page Components/SponsorCard";

const Search = lazy(() => import("../components/Search"));
const Profile = lazy(() => import("../components/Profile"));

const OrderTracking = () => {
  const [featured, setFeatured] = useState([]);
  const [updates, setUpdates] = useState();

  const featuredBlogs = async () => {
    try {
      const response = await fetch(featuredSectionsApi, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      if (response.ok) {
        setFeatured(data);
        // console.log(data);
      }
    } catch {}
  };

  const fetchUpdates = async () => {
    const access = localStorage.getItem("accessToken")
    try {
      const response = await fetch(
        "https://kitsa.co.in/api/v1/order-update/ZETA-0971-7693",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access}`,
          },
        }
      );
      const data = await response.json();
      if (response.ok) {
        setUpdates(data);
        console.log("updates", data);
      } else if (response.status === 401) {
        localStorage.setItem("isAuthenticated", false);
      } else {
      }
    } catch (err) {
      console.error(err)
    }
  };
  //   try {
  //     const response = await fetch(
  //       "https://raven-up-killdeer.ngrok-free.app/api/v1/order-update/ZETA-0411-1970",
  //       {
  //         method: "GET",
  //         headers: {
  //           Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzM3OTY2OTA0LCJpYXQiOjE3MzY3NTczMDQsImp0aSI6IjI4ODU2YzdlMmUwODRlN2Y5Mjc1NjljMzEzNGFlNmM1IiwidXNlcl9pZCI6Ijk5ZThkZDE4LWMwNmMtNDg5Mi04ZTc4LWQwYjYzZWVkYTNiMSJ9.ARihC_BdI7CcPD8UyfSzcPsQIzTDwvjtWzYo1XQF9TE`, 
  //         },
  //       }
  //     );
   
  //     let data = null;
  //     try {
  //       data = await response.json();
  //     } catch (jsonError) {
  //       console.error("Error parsing JSON:", jsonError);
  //     }
   
  //     if (response.ok) {
  //       setUpdates(data);
  //       console.log("Updates fetched successfully:", data);
  //     } else if (response.status === 401) {
  //       localStorage.setItem("isAuthenticated", false);
  //       console.warn("Unauthorized access - Token invalid or expired");
  //     } else if (response.status >= 500) {
  //       console.error("Server error - Please try again later.");
  //     } else {
  //       console.warn(`Unexpected response (${response.status}):`, data);
  //     }
  //   } catch (err) {
  //     console.error("Network or fetch error:", err);
  //   }
  // };

  // const fetchUpdates = async () => {
  //   try {
  //     const response = await fetch(
  //       "https://raven-up-killdeer.ngrok-free.app/api/v1/order-update/ZETA-0411-1970",
  //       {
  //         method: "GET",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzM3OTY2OTA0LCJpYXQiOjE3MzY3NTczMDQsImp0aSI6IjI4ODU2YzdlMmUwODRlN2Y5Mjc1NjljMzEzNGFlNmM1IiwidXNlcl9pZCI6Ijk5ZThkZDE4LWMwNmMtNDg5Mi04ZTc4LWQwYjYzZWVkYTNiMSJ9.ARihC_BdI7CcPD8UyfSzcPsQIzTDwvjtWzYo1XQF9TE`,
  //         },
  //       }
  //     );
   
  //     const contentType = response.headers.get("content-type");
   
  //     if (response.ok) {
  //       if (contentType && contentType.includes("application/json")) {
  //         const data = await response.text();
  //         setUpdates(data);
  //         console.log("Updates fetched successfully:", data);
  //       } else {
  //         console.error("Unexpected content type:", contentType);
  //       }

  //       await console.log(response.text())
  //     } else {
  //       console.error(
  //         `Request failed with status ${response.status}: ${response.statusText}`
  //       );
  //       if (contentType && contentType.includes("text/html")) {
  //         const errorPage = await response.text();
  //         console.error("Error page content:", errorPage);
  //       }
  //     }
  //   } catch (err) {
  //     console.error("Network or fetch error:", err);
  //   }
  // };

  useEffect(() => {
    featuredBlogs();
    fetchUpdates();
  }, []);

  return (
    <div className="text-formisPeach bg-gradient-to-b from-formisBlack via-[#203040] to-formisBlack overflow-y-scroll min-h-screen pb-80">
      <div className="h-12 bg-formisBlack font-[Hind] font-bold text-sm tracking-tighter flex items-center justify-center mb-[28px]">
        <p>Your Shipment is Packed. Rider is on it’s way to pick it up.</p>
      </div>
      <SponsorCard featured={featured.slice(0, 2)} />

      {/* <div className="mt-5 mb-[15px]">
        <div className="bg-[#203040] font-semibold text-xs h-[38px] border border-formisPeach flex items-center justify-between w-full rounded-[10px] px-[6px]">
          <p className="text-xs font-[Hind] font-semibold text-formisPeach">
            You have earned a voucher
          </p>
          <div className="font-[Hind] font-bold text-[11px] bg-formisPeach h-[28px] flex items-center justify-center w-[111px] rounded-[8px] shadow-md shadow-formisOrange text-formisBlack">
            Download
          </div>
        </div>
      </div> */}

      {/* <div className="bg-formisBlack border border-formisPeach h-[452px] rounded-[10px]" /> */}

      <div className="px-3">
        <div className="">
          <h4 className="text-sm font-bold mb-3">Leave a feedback</h4>
          <div>
            <div className="bg-[#203040] font-semibold text-xs h-[38px] border border-formisPeach flex items-center justify-between w-full rounded-[10px] px-[6px]">
              <p className="text-xs font-[Hind] font-semibold text-formisPeach">
                Rate your ordering experience
              </p>
              <Button>Rate it</Button>
            </div>
          </div>
        </div>
        <div className="mt-4">
          <h4 className="text-sm font-bold mb-3">Refer and earn*</h4>
          <div>
            <div className="bg-[#203040] font-semibold text-xs h-[38px] border border-formisPeach flex items-center justify-between w-full rounded-[10px] px-[6px]">
              <p className="text-xs font-[Hind] font-semibold text-formisPeach">
                Refer a friend and earn ₹50.00/- cashback
              </p>
              <Button>Refer Now</Button>
            </div>
          </div>
        </div>
        <div className="mt-4 mb-3">
          <h4 className="text-sm font-bold mb-3">Your order information</h4>
          <div>
            <div className="bg-[#203040] font-semibold text-xs h-[38px] border border-formisPeach flex items-center justify-between w-full rounded-[10px] px-[6px]">
              <p className="text-xs font-[Hind] font-semibold text-formisPeach">
                Get your invoice copy
              </p>
              <Button>Downlod</Button>
            </div>
          </div>
        </div>
        <div className="bg-[#203040] border border-formisPeach text-formisPeach px-2 pt-4 pb-10 rounded-[10px] mb-3">
          <p className="text-xs font-[700] mb-4">
            Delivering your order to "Home"
          </p>

          <div className="flex items-center justify-between rounded-[10px] mb-2">
            <div className="flex items-center gap-7">
              <PiMapPinLineFill className="text-3xl shrink-0" />
              <div className="text-xs">
                <p className="font-[700]">DLF Phase 3</p>
                <p className="font-[500]">Sector 24, Gurugram</p>
              </div>
            </div>
            <Button>Change</Button>
          </div>
        </div>
        <div className="bg-[#203040] font-semibold text-xs h-[38px] border border-formisPeach flex items-center justify-between w-full rounded-[10px] px-[6px] mb-3">
          <p className="text-xs font-[Hind] font-semibold text-formisPeach">
            Yay! you chose to pay now
          </p>
          <Button>Paid</Button>
        </div>
        {/* Order Items */}
        <div className="border border-formisPeach rounded-[10px] px-[6px] py-2 mb-3">
          <div className="flex items-center justify-between">
            <div className="font-[Hind] font-bold">
              <p className="text-xs">Shipment of 12 essentials</p>
              <div className="text-[10px] flex items-center gap-1">
                <img src={bookmark} alt="Save" />
                Saved as favourite
              </div>
            </div>
            <div className="font-[Hind] font-bold text-[11px] bg-formisPeach h-[28px] flex items-center justify-center w-[111px] rounded-[8px] shadow-md shadow-formisOrange text-formisBlack">
              ₹159.96
            </div>
          </div>
          <div className="flex flex-col gap-4 pt-4">
            <ProductCard />
            <ProductCard />
            <ProductCard />
            <ProductCard />
          </div>
          <div className="bg-[#203040] font-semibold text-xs h-[38px] border border-formisPeach flex items-center justify-between w-full rounded-[10px] px-[6px] mt-6">
            <p className="text-xs font-[Hind] font-semibold text-formisPeach">
              Forgot Something?
            </p>
            <Button>Add Now</Button>
          </div>
        </div>
        <div className="bg-[#203040] font-semibold text-xs h-[38px] border border-formisPeach flex items-center justify-between w-full rounded-[10px] px-[6px] mb-3">
          <p className="text-xs font-[Hind] font-semibold text-formisPeach">
            Need help with your order?
          </p>
          <Button>Get Help</Button>
        </div>
        <div className="bg-[#203040] font-semibold text-xs h-[38px] border border-formisPeach flex items-center justify-between w-full rounded-[10px] px-[6px] mb-3">
          <p className="text-xs font-[Hind] font-semibold text-formisPeach">
            Changed your mind?
          </p>
          <Button>Cancel Order</Button>
        </div>
      </div>

      <div className="flex flex-col gap-12 mt-10">
        <SponsorCard featured={featured.slice(2, 6)} />
        <SponsorCard featured={featured.slice(6, 10)} />
      </div>

      <div className="text-[#666666]/50 mt-14 px-3">
        <h1 className="text-[67px] font-black leading-none">Delivered!</h1>
        <h2 className="text-[42px] font-extrabold leading-none mb-3">
          Before The Panic.
        </h2>
        <p className="text-lg font-bold mb-8">
          It is FORMIS® for You from Gurugram, India.
        </p>
        <img src={heart} alt="FORMIS" className="mx-auto" />
      </div>

      <div className="fixed z-[30] bottom-0 w-full left-0">
        <PlaceOrderButton />
        <MobileFooter />
      </div>

      <Suspense fallback={<div>Loading...</div>}>
        <Search />
        <Profile />
      </Suspense>
    </div>
  );
};

export default OrderTracking;

const PlaceOrderButton = () => {
  // const dispatch = useDispatch();
  // const isAuthenticated = localStorage.getItem("isAuthenticated");
  // const { items } = useSelector((state) => state.cartItems);
  // const userCart = useSelector((state) => state.apiCart.userCartItems);

  return (
    <div className="relative flex items-center justify-center gap-3 mb-3">
      <Link
        to="/"
        className={`relative flex items-center gap-2 justify-center text-formisPeach`}
        // onClick={() => dispatch(setApproval(true))}
      >
        <div className="bg-[#203040] w-fit px-2 py-1 rounded-full flex items-center gap-3">
          <div className="size-7 border border-formisPeach rounded-full flex items-center justify-center font-[Hind] text-[11px] font-bold">
            0
          </div>
          <div className="text-xs font-bold">Place a new order</div>
          <img src={back} alt="next" className="rotate-180 w-4" />
        </div>
      </Link>

      <Link
        to="/"
        className="bg-[#203040] h-full border border-formisPeach p-[7px] rounded-full"
      >
        <img src={home} alt="Home" />
      </Link>
    </div>
  );
};
