import { useDispatch, useSelector } from "react-redux";
import etaImg from "../../assets/icons/eta.svg";
import imageNA from "../../assets/Image-NA.webp";

import { FaMinus, FaPlus } from "react-icons/fa6";

import discount from "../../assets/icons/discount.svg";
import { useEffect, useState } from "react";
import { addToCartApi, deleteFromCartApi } from "../../api";
import { setShowComingSoon } from "../../features/slidersSlice";
import { setUserCartItems } from "../../features/apiCartSlice";

const CartProducts = ({ userCart }) => {
  const { eta } = useSelector((state) => state.googleMapLocation);
  return (
    <div className="text-formisPeach shadow-peachBox rounded-[20px] mx-3 px-2 py-4">
      <div className="flex items-center justify-between mb-6">
        <h5 className="text-sm font-bold">
          Shipment of {userCart?.length} essentials
        </h5>
        <div className="flex items-center border border-formisPeach gap-2 px-[5px] py-[2px] rounded-full">
          <p className="text-[11px] tracking-[-0.28px] font-bold leading-none">
            {eta.eta_minutes} mins
          </p>
          <img src={etaImg} alt="ETA" />
        </div>
      </div>

      <div className="flex flex-col gap-3">
        {userCart.map((product) => (
          <ProductCard
            userCart={userCart}
            product={product}
            key={product.product_id}
          />
        ))}
      </div>
    </div>
  );
};

export default CartProducts;

const ProductCard = ({ product }) => {
  const dispatch = useDispatch();
  const [productQuantity, setProductQuantity] = useState(product.quantity);
  useEffect(() => {
    setProductQuantity(product.quantity);
  }, [product]);

  const handleDecrement = () => {
    if (productQuantity > 1) {
      const updatedQuantity = productQuantity - 1;
      setProductQuantity(updatedQuantity);
      handleAddToCartApiCall(updatedQuantity);
    } else {
      handleDeleteApiCall();
    }
  };

  const handleIncrement = () => {
    const updatedQuantity = productQuantity + 1;
    setProductQuantity(updatedQuantity);
    handleAddToCartApiCall(updatedQuantity);
  };

  const handleAddToCartApiCall = async (quantity) => {
    const access = localStorage.getItem("accessToken");
    try {
      const response = await fetch(addToCartApi, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
        body: JSON.stringify({
          cart_data: [
            {
              product_id: product.product_id,
              quantity,
            },
          ],
        }),
      });

      const data = await response.json();

      if (response.ok) {
        dispatch(setUserCartItems(data));
      } else {
      }
    } catch (error) {
      // console.error("Failed to add to cart:", error);
    }
  };

  const handleDeleteApiCall = async () => {
    const access = localStorage.getItem("accessToken");
    try {
      const response = await fetch(deleteFromCartApi, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
        body: JSON.stringify({
          product_ids: [product.product_id],
        }),
      });

      const data = await response.json();

      if (response.ok) {
        dispatch(setUserCartItems(data));
      } else {
      }
    } catch (error) {
      // console.error("Failed to delete:", error);
    }
  };
  return (
    <div className="flex gap-4 justify-between">
      <div className="flex gap-4">
        <div className="p-[5px] bg-white rounded-[10px] relative shrink-0 h-[77px] w-[78px]">
          <img
            src={
              product.product_image !== "NA" &&
              product.product_image !==
                "http://34.131.96.18/wp-content/uploads/2024/11/Image-NA.png"
                ? product.product_image
                : imageNA
            }
            loading="lazy"
            alt={product.product_name}
            className="aspect-square rounded-[10px]"
          />
          <div className="h-[23px] w-[19px] absolute -top-[7px] -left-[1px]">
            <img src={discount} alt="discount!" className="" />
            <div className="absolute -top-1 -left-[2.5px] h-[28px] w-[23px] flex items-center justify-center">
              <p className="font-[Hind] text-[8px] text-formisBlack font-bold leading-none">
                {parseInt(product.discount_percentage)}%
                <br /> OFF
              </p>
            </div>
          </div>
        </div>

        <div className="shrink">
          <p className="font-[Hind] text-xs font-bold mb-1">
            {product.product_name}
          </p>
          <button
            onClick={() => dispatch(setShowComingSoon(true))}
            className="font-[Hind] text-[10px] font-bold text-formisWhite border-b border-formisWhite leading-none"
          >
            Add to Auto Refill Schedule
          </button>
        </div>
      </div>

      <div className="">
        <div className="bg-formisPeach text-formisBlack flex items-center w-fit place-self-end rounded-[8px] py-[2px] shadow-md shadow-formisOrange">
          <button onClick={handleDecrement} className="w-[20px] pl-2">
            <FaMinus />
          </button>
          <input
            value={productQuantity}
            className="font-[Hind] text-sm font-bold bg-transparent outline-none w-[40px] text-center"
            readOnly
          />
          <button className="w-[20px] pr-2">
            <FaPlus onClick={handleIncrement} />
          </button>
        </div>

        <div className="w-full flex justify-end">
          <button onClick={handleDeleteApiCall} className="font-[Hind] text-[10px] font-bold text-formisWhite border-b border-formisWhite leading-none pt-4 mb-2">
            Remove Item
          </button>
        </div>

        <div className="flex items-center gap-1 font-[Hind] font-bold place-self-end">
          <p className="text-[12px]">
            ₹{(product.selling_price_at_time * product.quantity).toFixed(2)}
          </p>
          <span className="text-[10px]">
            <s>₹{(product.mrp * product.quantity).toFixed(2)}</s>
          </span>
        </div>
      </div>
    </div>
  );
};
