import Topbar from "../components/Topbar";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import imageNA from "../assets/Image-NA.webp";
import { MdNavigateNext } from "react-icons/md";
import bg from "../assets/bg-green.png";
import { useDispatch, useSelector } from "react-redux";
import {
  addToCart,
  decrementQuantity,
  incrementQuantity,
  removeFromCart,
} from "../features/cartSlice";
import { addToCartApi, deleteFromCartApi } from "../api";

import discount from "../assets/icons/discount.svg";
import { RiDeleteBin6Line } from "react-icons/ri";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import AddressSlider from "../components/AddressSlider";
import SurgeSlider from "../components/SurgeSlider";
import MobileFooter from "../components/MobileFooterMenu";
import Search from "../components/Search";
import Profile from "../components/Profile";

import ViewCartButton from "../components/Footer Menu Buttons/ViewCartButton";
import { setUserCartItems } from "../features/apiCartSlice";

const AllProducts = () => {
  const { categoryName } = useParams();
  const [productsData, setProductsData] = useState();
  const [currentPage, setCurrentPage] = useState(1);


  const userCart = useSelector((state) => state.apiCart.userCartItems);

  const fetchProducts = async () => {
    try {
      const response = await fetch(
        `https://kitsa.co.in/api/v1/medicines-es?page=${currentPage}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();

      if (response.ok) {
        setProductsData(data);
      }
    } catch {}
  };

  useEffect(() => {
    fetchProducts();
  }, [currentPage]);

  const handlePreviousClick = () => {
    setCurrentPage(currentPage - 1);
    window.scrollTo(0, 300);
  };
  const handleNextClick = () => {
    setCurrentPage(currentPage + 1);
    window.scrollTo(0, 300);
  };

  return (
    <div className="bg-formisBlack text-formisPeach">
      <Topbar />
      <div className="bg-formisBlack">
        <div
          style={{
            backgroundImage: `url(${bg})`,
          }}
          className="relative h-[31vh] md:h-[45vh] bg-no-repeat bg-cover bg-[50%] bg-formisBlack rounded-t-[20px]"
        >
          <div className="h-full w-full absolute top-0 left-0 bg-formisBlack/20" />

          <div className="bg-[#203040] pt-12 md:pt-14">
            <Header />
          </div>

          <div className="relative flex items-end h-[calc(100%-8rem)] md:h-[calc(100%-13rem)] px-4 text-formisPeach">
            <div>
              <div className="flex items-center gap-4 md:gap-6 text-[13px] lg:text-[14px] xl:text-[16px] leading-none">
                <svg
                  className="w-4"
                  viewBox="0 0 20 21"
                  stroke="currentColor"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M18.333 14.667v-3.713c0-1.355 0-2.033-.165-2.66a5 5 0 0 0-.818-1.702c-.387-.521-.916-.945-1.974-1.791l-.378-.303h0c-1.784-1.427-2.676-2.14-3.665-2.414a5 5 0 0 0-2.666 0c-.99.274-1.881.987-3.665 2.414h0l-.378.303c-1.058.846-1.587 1.27-1.974 1.79a5 5 0 0 0-.818 1.703c-.165.627-.165 1.305-.165 2.66v3.713a4.167 4.167 0 0 0 4.166 4.166c.92 0 1.667-.746 1.667-1.666v-3.334a2.5 2.5 0 0 1 5 0v3.334c0 .92.746 1.666 1.667 1.666a4.167 4.167 0 0 0 4.166-4.166Z"
                  ></path>
                </svg>
                <span>/</span>
                <p>Categories</p>
                <span>/</span>
                <p>All Products</p>
              </div>
              <h2 className="title-md leading-none font-bold my-5">
                All Products
              </h2>
            </div>
          </div>

          <div className="bg-formisBlack absolute bottom-0 left-0 w-full h-4 lg:h-10 rounded-t-[15px]"></div>
        </div>
      </div>

      <div className="flex flex-wrap gap-y-8 justify-between rounded-t-[10px] px-4 pb-10">
        {productsData &&
          productsData.results.map((product, index) => (
            <ProductCard
              key={index}
              res={product}
              userCart={userCart}
            />
          ))}
      </div>

      <div className="flex gap-5 items-center justify-center pb-10">
        {currentPage !== 1 && (
          <button
            className="bg-formisPeach text-formisBlack py-3 px-5 rounded-full flex items-center justify-center gap-2"
            onClick={handlePreviousClick}
          >
            <MdNavigateNext className="rotate-180 text-xl" />
            Previous
          </button>
        )}
        <button
          className="bg-formisPeach text-formisBlack py-3 px-5 rounded-full flex items-center justify-center gap-2"
          onClick={handleNextClick}
        >
          Next <MdNavigateNext />
        </button>
      </div>

      <Footer />

      <AddressSlider />
      <SurgeSlider />
      <div className="fixed z-[30] bottom-0 w-full left-0">
        <ViewCartButton />
        <MobileFooter />
      </div>
      <Search />
      <Profile />
    </div>
  );
};

export default AllProducts;

//------------------------------------------------------------------------------------------------------------------------------------------------//

const ProductCard = ({ res, userCart }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cartItem = useSelector((state) =>
    state.cartItems.items.find(
      (cartItem) => cartItem.product_id === res.product_id
    )
  );

  const userCartItem = userCart?.find(
    (item) => item.product_id === res.product_id
  );

  const isAuthenticated = localStorage.getItem("isAuthenticated");
  const [productQuantity, setProductQuantity] = useState(
    userCartItem?.quantity || 1
  );

  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    navigate(`/product/${res.slug}`);
  };

  const handleAddToCart = () => {
    if (loading) return;
    if (isAuthenticated === "true") {
      handleAddToCartApiCall(productQuantity);
    } else dispatch(addToCart(res));
  };

  const handleDecrement = () => {
    if (loading) return;
    if (productQuantity > 1) {
      const updatedQuantity = productQuantity - 1;
      setProductQuantity(updatedQuantity);
      handleAddToCartApiCall(updatedQuantity);
    } else {
      handleDeleteApiCall();
    }
  };

  const handleReduxDecrement = () => {
    if (cartItem?.quantity === 1) dispatch(removeFromCart(cartItem));
    if (cartItem?.quantity > 1) {
      dispatch(decrementQuantity({ product_id: res.product_id }));
    }
  };

  const handleIncrement = () => {
    if (loading) return;
    const updatedQuantity = productQuantity + 1;
    setProductQuantity(updatedQuantity);
    if (isAuthenticated === "true") {
      handleAddToCartApiCall(updatedQuantity);
      // dispatch(incrementQuantity({ product_id: res.product_id }));
    } else dispatch(incrementQuantity({ product_id: res.product_id }));
  };

  const handleAddToCartApiCall = async (quantity) => {
    const access = localStorage.getItem("accessToken");
    setLoading(true);
    try {
      const response = await fetch(addToCartApi, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
        body: JSON.stringify({
          cart_data: [
            {
              product_id: res.product_id,
              quantity,
            },
          ],
        }),
      });

      const data = await response.json();

      if (response.ok) {
        // dispatch(addToCart(res));
        dispatch(setUserCartItems(data));
        // localStorage.setItem("userCart", true)
      } else {
      }
    } catch (error) {
      // console.error("Failed to add to cart:", error);
    } finally {
      setLoading(false); // Stop loader
    }
  };

  const handleDeleteApiCall = async () => {
    const access = localStorage.getItem("accessToken");
    setLoading(true);
    try {
      const response = await fetch(deleteFromCartApi, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
        body: JSON.stringify({
          product_ids: [res.product_id],
        }),
      });

      const data = await response.json();

      if (response.ok) {
        dispatch(removeFromCart(cartItem));
        dispatch(setUserCartItems(data));
      } else {
      }
    } catch (error) {
      // console.error("Failed to delete:", error);
    } finally {
      setLoading(false); // Stop loader
    }
  };

  return (
    <div className="w-[145.65px]">
      <div className="relative mb-4">
        <div
          onClick={handleClick}
          className="p-[5px] bg-white rounded-[10px] h-[144px] w-[145.65px]"
        >
          <img
            src={
              res.image_url !== "NA" &&
              res.image_url !==
                "http://34.131.96.18/wp-content/uploads/2024/11/Image-NA.png"
                ? res.image_url
                : imageNA
            }
            loading="lazy"
            className="aspect-square rounded-[10px]"
            alt="Product"
          />
          <div className="h-[28px] w-[23px] absolute -top-[7px] -left-[1px]">
            <img src={discount} alt="discount!" className="" />
            <div className="absolute top-0 left-0 h-[28px] w-[23px] flex items-center justify-center">
              <p className="font-[Hind] text-[10px] text-formisBlack font-bold leading-none">
                {parseInt(Math.floor(res.discount_percentage.slice(0, -1)))}%
                <br /> OFF
              </p>
            </div>
          </div>
        </div>

        {isAuthenticated === "true" ? (
          <div className="absolute -bottom-3 -right-2 font-bold rounded-[10px] text-sm bg-formisWhite border border-formisBlack text">
            {userCartItem ? (
              <div className="bg-formisPeach text-formisBlack h-[37px] rounded-[10px] border border-formisBlack absolute -bottom-3 -right-0 flex gap-2 items-center mb-3">
                <button
                  disabled={loading}
                  className="pl-3 py-2"
                  onClick={handleDecrement}
                >
                  {userCartItem?.quantity === 1 || productQuantity === 1 ? (
                    <RiDeleteBin6Line className="text-formisBlack/50" />
                  ) : (
                    <IoIosArrowDown className="rotate-90" />
                  )}
                </button>
                {!loading ? (
                  <input
                    type="number"
                    min="1"
                    value={productQuantity}
                    readOnly
                    className="max-w-4 bg-transparent rounded-[5px] text-center outline-none"
                  />
                ) : (
                  <div className="loader2"></div>
                )}
                <button className="pr-3 py-2" onClick={handleIncrement}>
                  <IoIosArrowUp className="rotate-90" />
                </button>
              </div>
            ) : (
              <div
                onClick={handleAddToCart}
                className="absolute -bottom-0 right-1 h-[37px] font-bold rounded-[10px] text-sm bg-formisPeach/90 text-formisBlack"
              >
                <button className="h-full w-[79.65px] text-lg font-semibold rounded-[10px]">
                  ADD
                </button>
              </div>
            )}
          </div>
        ) : (
          <div className="absolute -bottom-3 -right-2 font-bold rounded-[10px] text-sm bg-formisWhite border border-formisBlack text">
            {cartItem ? (
              <div className="bg-formisPeach text-formisBlack h-[37px] rounded-[10px] border border-formisBlack absolute -bottom-3 -right-0 flex gap-2 items-center mb-3">
                <div className="pl-3 py-2" onClick={handleReduxDecrement}>
                  {cartItem?.quantity === 1 ? (
                    <RiDeleteBin6Line className="text-formisBlack/50" />
                  ) : (
                    <IoIosArrowDown className="rotate-90" />
                  )}
                </div>
                <input
                  type="number"
                  min="1"
                  value={cartItem?.quantity}
                  readOnly
                  className="max-w-4 bg-transparent rounded-[5px] text-center outline-none"
                />
                <div className="pr-3 py-2" onClick={handleIncrement}>
                  <IoIosArrowUp className="rotate-90" />
                </div>
              </div>
            ) : (
              <div
                onClick={handleAddToCart}
                className="absolute -bottom-0 right-1 h-[37px] font-bold rounded-[10px] text-sm bg-formisPeach/90 text-formisBlack"
              >
                <button className="h-full w-[79.65px] text-lg font-semibold rounded-[10px]">
                  ADD
                </button>
              </div>
            )}
          </div>
        )}
      </div>

      <div className="flex items-center gap-1 mb-[6px]">
        {res.package && (
          <div className="bg-formisOrange rounded-full">
            <span className="text-[10px] font-extrabold px-1 leading-none h-[13px] tracking-[-0.25px] flex items-center justify-center rounded-[10px] bg-gradient-to-b from-formisOrange to-formisBlack/70 border border-formisOrange">
              {res.package}
            </span>
          </div>
        )}
      </div>

      <div className="flex w-full items-center justify-between">
        <div>
          <p className="font-[Hind] font-[700] text-[13px] leading-4">
            {res.name.length > 42 ? res.name.slice(0, 42) + "..." : res.name}
          </p>
          {/* <div className="flex items-center gap-2 mb-[2px]">
            <img src={stars} alt="stars" className="w-[66px]" />
            <p className="text-[10px] font-[Hind] relative top-[1px] font-bold">
              3.4K reviews
            </p>
          </div> */}

          <div className="flex items-center gap-2 font-[Hind]">
            <div className="flex items-center gap-1">
              <p className="text-[12px] font-[600]">
                ₹{res.final_price.toFixed(2)}
              </p>
              <span className="text-[10px] font-[600]">
                MRP <s>₹{res.mrp.toFixed(2)}</s>
              </span>
            </div>
            <div className="h-[23px] w-[19px] relative">
              <img src={discount} alt="discount!" />
              <div className="absolute -top-[2px] -left-[2px] h-[28px] w-[23px] flex items-center justify-center">
                <p className="font-[Hind] text-[8px] text-formisBlack font-bold leading-none">
                  {parseInt(Math.floor(res.discount_percentage.slice(0, -1)))}%
                  <br /> OFF
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
