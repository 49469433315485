import { createSlice } from "@reduxjs/toolkit";

export const profileSlice = createSlice({
  name: "profile",

  initialState: {
    isProfileOpen: false,
  },

  reducers: {
    toggleProfileComponent: (state) => {
      state.isProfileOpen = !state.isProfileOpen;
    },
    setProfileComponent: (state, action) => {
      state.isProfileOpen = action.payload;
    },
  },
});

export const { toggleProfileComponent, setProfileComponent } =
  profileSlice.actions;
export default profileSlice.reducer;
