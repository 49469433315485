import all from "../../assets/icons/all.svg";
import beauty from "../../assets/icons/beauty.svg";
import sports from "../../assets/icons/sports.svg";
import everyday from "../../assets/icons/everyday.svg";
import ayurved from "../../assets/icons/ayurved.svg";
import remedies from "../../assets/icons/remedies.svg";
import wellness from "../../assets/icons/wellness.svg";
import protect from "../../assets/icons/protect+.svg";

import back from "../../assets/icons/back-peach.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toggleSearchComponent } from "../../features/searchSlice";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/autoplay";

const items = [
  { icon: all, label: "All", link: "all-products" },
  { icon: beauty, label: "Beauty", link: "/categories/Skin, hair & oral care" },
  { icon: sports, label: "Sports", link: "/categories/Fitness supplements" },
  {
    icon: everyday,
    label: "Everyday",
    link: "/categories/Vitamins & nutrition",
  },
  {
    icon: ayurved,
    label: "Ayurved",
    link: "/categories/Ayurvedic Supplements",
  },
  { icon: wellness, label: "Wellness", link: "/categories/Wellness" },
  {
    icon: remedies,
    label: "Remedies",
    link: "/categories/Cold, Cough & Fever",
  },
  { icon: protect, label: "Protect+", link: "/categories/Protect+ Essentials" },
];

const CategoriesSlider = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <div className="pt-3 pb-7 rounded-b-[25px] shadow-peach">
      {/* <div className="flex items-center md:justify-center md:overflow-hidden gap-[calc(12.70vw)] scrollbar-hide overflow-x-scroll text-formisPeach px-[22px] md:px-10 md2:px-14 lg:px-10 xl:px-12 pb-[51px]">
        <div
          className="flex flex-col gap-[6px] items-center shrink-0 transition-all duration-150"
          onClick={() => navigate("all-products")}
          onTouchStart={(e) =>
            e.currentTarget.classList.add("scale-95", "opacity-70")
          }
          onTouchEnd={(e) =>
            e.currentTarget.classList.remove("scale-95", "opacity-70")
          }
        >
          <img src={all} alt="All" className="w-[46px]" />
          <p className="text-xs font-bold tracking-wider font-[Hind]">All</p>
        </div>
        <div
          className="flex flex-col gap-[6px] items-center shrink-0 transition-all duration-150"
          onClick={() => navigate(`/categories/Skin, hair & oral care`)}
          onTouchStart={(e) =>
            e.currentTarget.classList.add("scale-95", "opacity-70")
          }
          onTouchEnd={(e) =>
            e.currentTarget.classList.remove("scale-95", "opacity-70")
          }
        >
          <img src={beauty} alt="All" className="w-[46px]" />
          <p className="text-xs font-bold tracking-wider font-[Hind]">Beauty</p>
        </div>
        <div
          className="flex flex-col gap-[6px] items-center shrink-0 transition-all duration-150"
          onClick={() => navigate(`/categories/Fitness supplements`)}
          onTouchStart={(e) =>
            e.currentTarget.classList.add("scale-95", "opacity-70")
          }
          onTouchEnd={(e) =>
            e.currentTarget.classList.remove("scale-95", "opacity-70")
          }
        >
          <img src={sports} alt="All" className="w-[46px]" />
          <p className="text-xs font-bold tracking-wider font-[Hind]">Sports</p>
        </div>
        <div
          className="flex flex-col gap-[6px] items-center shrink-0 transition-all duration-150"
          onClick={() => navigate(`/categories/Vitamins & nutrition`)}
          onTouchStart={(e) =>
            e.currentTarget.classList.add("scale-95", "opacity-70")
          }
          onTouchEnd={(e) =>
            e.currentTarget.classList.remove("scale-95", "opacity-70")
          }
        >
          <img src={everyday} alt="All" className="w-[46px]" />
          <p className="text-xs font-bold tracking-wider font-[Hind]">
            Everyday
          </p>
        </div>
        <div
          className="flex flex-col gap-[6px] items-center shrink-0 transition-all duration-150"
          onClick={() => navigate("/categories/Ayurvedic Supplements")}
          onTouchStart={(e) =>
            e.currentTarget.classList.add("scale-95", "opacity-70")
          }
          onTouchEnd={(e) =>
            e.currentTarget.classList.remove("scale-95", "opacity-70")
          }
        >
          <img src={ayurved} alt="All" className="w-[46px]" />
          <p className="text-xs font-bold tracking-wider font-[Hind]">
            Ayurved
          </p>
        </div>
        <div
          className="flex flex-col gap-[6px] items-center shrink-0 transition-all duration-150"
          onClick={() => navigate(`/categories/Wellness`)}
          onTouchStart={(e) =>
            e.currentTarget.classList.add("scale-95", "opacity-70")
          }
          onTouchEnd={(e) =>
            e.currentTarget.classList.remove("scale-95", "opacity-70")
          }
        >
          <img src={wellness} alt="All" className="w-[46px]" />
          <p className="text-xs font-bold tracking-wider font-[Hind]">
            Wellness
          </p>
        </div>
        <div
          className="flex flex-col gap-[6px] items-center shrink-0 transition-all duration-150"
          onClick={() => navigate(`/categories/Cold, Cough & Fever`)}
          onTouchStart={(e) =>
            e.currentTarget.classList.add("scale-95", "opacity-70")
          }
          onTouchEnd={(e) =>
            e.currentTarget.classList.remove("scale-95", "opacity-70")
          }
        >
          <img src={remedies} alt="All" className="w-[46px]" />
          <p className="text-xs font-bold tracking-wider font-[Hind]">
            Remedies
          </p>
        </div>
        <div
          className="flex flex-col gap-[6px] items-center shrink-0 transition-all duration-150"
          onClick={() => navigate(`/categories/Protect+ Essentials`)}
          onTouchStart={(e) =>
            e.currentTarget.classList.add("scale-95", "opacity-70")
          }
          onTouchEnd={(e) =>
            e.currentTarget.classList.remove("scale-95", "opacity-70")
          }
        >
          <img src={protect} alt="All" className="w-[46px]" />
          <p className="text-xs font-bold tracking-wider font-[Hind]">
            Protect+
          </p>
        </div>
      </div> */}

      <div className="px-4 md:px-10 lg:px-12 pb-[51px] text-formisPeach">
        <Swiper slidesPerView={4} spaceBetween={20}>
          {items.map(({ icon, label, link }) => (
            <SwiperSlide key={label}>
              <div
                className="flex flex-col items-center"
                onClick={() => navigate(link)}
                onTouchStart={(e) =>
                  e.currentTarget.classList.add("scale-95", "opacity-70")
                }
                onTouchEnd={(e) =>
                  e.currentTarget.classList.remove("scale-95", "opacity-70")
                }
              >
                <img src={icon} alt={label} className="w-[46px]" />
                <p className="text-xs font-bold tracking-wider font-[Hind]">
                  {label}
                </p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <button
        className="text-formisPeach font-bold text-xs flex items-center gap-8 mx-auto"
        onClick={() => dispatch(toggleSearchComponent())}
        onTouchStart={(e) =>
          e.currentTarget.classList.add("scale-95", "opacity-70")
        }
        onTouchEnd={(e) =>
          e.currentTarget.classList.remove("scale-95", "opacity-70")
        }
      >
        View all products
        <img
          src={back}
          alt=">"
          className="rotate-180 w-5 transition-all duration-150"
        />
      </button>
    </div>
  );
};

export default CategoriesSlider;
