import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setCoordinates,
  setAddressDetails,
  setAddressStep,
  setAddressSearchQuery,
} from "../../features/mapSlice";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { PiMapPinLineFill } from "react-icons/pi";
import { BiTargetLock } from "react-icons/bi";
import searchIcon from "../../assets/icons/search.svg";
import { useGeolocation } from "../../hooks/useGeolocation";
import close from "../../assets/icons/close.svg";

const SearchAddress = () => {
  const google = window.google;
  const dispatch = useDispatch();
  const { locationInfo, getGeolocation } = useGeolocation();

  const { locationPermission, previousStep, addressStep, addressSearchQuery } =
    useSelector((state) => state.googleMapLocation);
  const [searchResults, setSearchResults] = useState([]);
  const inputRef = useRef(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [showLocationButton, setShowLocationButton] = useState(true);

  const handlePlaceSearch = (query) => {
    if (!query) {
      setSearchResults([]);
      return;
    }
    const service = new google.maps.places.AutocompleteService();
    service.getPlacePredictions({ input: query }, (predictions, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        setSearchResults(predictions || []);
      } else {
        // console.error("Place search failed:", status);
        setSearchResults([]);
      }
    });
  };

  const handleSearchResultClick = (placeId, mainText) => {
    const service = new google.maps.places.PlacesService(
      document.createElement("div")
    );
    service.getDetails({ placeId }, (place, status) => {
      if (
        status === google.maps.places.PlacesServiceStatus.OK &&
        place.geometry
      ) {
        const addressComponents = place.address_components;

        const getAddressComponent = (type) => {
          const component = addressComponents.find((comp) =>
            comp.types.includes(type)
          );
          return component ? component.long_name : null;
        };

        const city = getAddressComponent("locality");
        const state = getAddressComponent("administrative_area_level_1");
        const pinCode = getAddressComponent("postal_code");

        let cleanedAddress = place.formatted_address;
        if (city) cleanedAddress = cleanedAddress.replace(city, "").trim();
        if (state) cleanedAddress = cleanedAddress.replace(state, "").trim();
        if (pinCode)
          cleanedAddress = cleanedAddress.replace(pinCode, "").trim();
        cleanedAddress = cleanedAddress
          .replace(/,\s*$/, "")
          .replace(/\s{2,}/g, " ");

        dispatch(
          setCoordinates({
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          })
        );
        dispatch(
          setAddressDetails({
            first: mainText,
            address: cleanedAddress || "",
            city,
            state,
            pinCode,
          })
        );
        dispatch(setAddressSearchQuery(place.formatted_address || ""));
        dispatch(setAddressStep(1));
        // setSearchResults([]);
      } else {
        console.error("Failed to fetch place details:", status);
      }
    });
  };

  useEffect(() => {
    navigator.permissions.query({ name: "geolocation" }).then((result) => {
      if (result.state === "denied") {
        setShowLocationButton(false);
      }
    });
  }, [locationPermission]);

  const waitForLocation = () => {
    if (locationInfo) {
      dispatch(
        setCoordinates({
          lat: locationInfo.latitude,
          lng: locationInfo.longitude,
        })
      );
      dispatch(setAddressStep(1));
    }
  };

  const handleGoToLocationClick = () => {
    getGeolocation();
    // waitForLocation();
  };

  useEffect(() => {
    waitForLocation();
  }, [locationInfo]);

  useEffect(() => {
    if (addressStep === 3) inputRef.current.focus();
  }, [addressStep]);

  return (
    <div className="overflow-scroll min-h-full">
      <button
        className="bg-formisPeach px-4 w-full grid grid-cols-5 items-center justify-center rounded-b-[30px] h-[52px] mb-5"
        onClick={() => {
          if (previousStep === 1) dispatch(setAddressStep(1));
          else dispatch(setAddressStep(null));
        }}
      >
        <MdOutlineKeyboardArrowLeft className="text-2xl" />
        <p className="text-sm font-[700] col-span-3">
          Search for street, area or city
        </p>
      </button>

      <div className="mx-3 bg-formisWhite flex flex-col gap-4 rounded-[10px] mb-2">
        <div className="flex items-center h-[51px] px-3 gap-5">
          <img src={searchIcon} alt="Search" className="w-4" />
          <input
            ref={inputRef}
            className="bg-transparent outline-none w-full font-[700] text-formisBlack placeholder:text-formisBlack text-xs"
            placeholder="Search for area, street name..."
            onChange={(e) => {
              dispatch(setAddressSearchQuery(e.target.value));
              setSearchQuery(e.target.value);
              handlePlaceSearch(e.target.value);
            }}
            value={previousStep === null ? searchQuery : addressSearchQuery}
          />
          {searchQuery && (
            <img
              src={close}
              alt="Clear"
              className="w-5"
              onClick={() => {
                setSearchQuery("");
                dispatch(setAddressSearchQuery(""));
              }}
            />
          )}
        </div>
      </div>

      {searchResults.length > 0 && (
        <div className="p-3 px-2 bg-formisWhite mx-3 rounded-[10px] mb-5">
          <h6 className="font-bold text-xs mb-5">Suggestions</h6>
          <div className="space-y-5">
            {searchResults.map((result) => (
              <div
                key={result.place_id}
                onClick={() =>
                  handleSearchResultClick(
                    result.place_id,
                    result.structured_formatting.main_text
                  )
                }
                className="p-2 flex items-center justify-between gap-3 shadow-custom rounded-[10px] cursor-pointer"
              >
                <div className="flex items-center gap-6">
                  <div className="rounded-[10px]">
                    <PiMapPinLineFill className="text-2xl text-red-600" />
                  </div>
                  <div>
                    <p className="text-xs font-bold">
                      {result.structured_formatting.main_text}
                    </p>
                    <p className="text-xs font- font-[Hind]">
                      {result.structured_formatting.secondary_text}
                    </p>
                  </div>
                </div>
                <button className="text-xs text-formisPeach px-3 py-1 rounded-[10px] font-bold bg-[#203040]">
                  Select
                </button>
              </div>
            ))}
          </div>
        </div>
      )}
      {showLocationButton && (
        <div className="flex items-center justify-center">
          <button
            onClick={handleGoToLocationClick}
            className="bg-formisPeach text-formisBlack rounded-[10px] py-[6px] w-[calc(100%-24px)] font-extrabold
                    grid grid-cols-5 items-center px-5"
          >
            <BiTargetLock className="text-4xl" />
            <p className="tracking-[-0.4px] col-span-3">
              Go to current location
            </p>
          </button>
        </div>
      )}
    </div>
  );
};

export default SearchAddress;
