import { useEffect, useState } from "react";
import { useGeolocation } from "../../hooks/useGeolocation";
import { useDispatch, useSelector } from "react-redux";
import {
  setCoordinates,
  setAddressDetails,
  setAddressStep,
  setAddressSearchQuery,
} from "../../features/mapSlice";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { BiTargetLock } from "react-icons/bi";
import searchIcon from "../../assets/icons/search.svg";
import buttonNext from "../../assets/icons/button-next.svg";
import GoogleMap from "../GoogleMap";
import RemainingDetails from "./RemainingDetails";
import pinIcon from "../../assets/icons/map-pin.svg";

const LocationPage = () => {
  const dispatch = useDispatch();
  const [fillRemainingDetails, setFillRemainingDetails] = useState(false);
  const { coordinates, locationPermission, addressSearchQuery } = useSelector(
    (state) => state.googleMapLocation
  );
  const { locationInfo, getGeolocation } = useGeolocation();
  const [showLocationButton, setShowLocationButton] = useState(true);

  const [area, setArea] = useState(null);

  const waitForLocation = () => {
    if (locationInfo) {
      dispatch(
        setCoordinates({
          lat: locationInfo.latitude,
          lng: locationInfo.longitude,
        })
      );
      dispatch(setAddressStep(1));
    }
  };

  const handleGoToLocationClick = () => {
    getGeolocation();
    // waitForLocation();
  };

  useEffect(() => {
    waitForLocation();
  }, [locationInfo]);

  const fetchAddressDetails = async (lat, lng) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyCdkLiUoLbs1D0IrliCQYp-nJz8OgQV2pU`
      );
      const data = await response.json();

      if (data.results[0]) {
        const addressComponents = data.results[0].address_components;
        const formattedAddress = data.results[0].formatted_address;
        dispatch(setAddressSearchQuery(formattedAddress));
        let houseNumber = "";
        let route = "";
        let neighbourhood = "";
        let state = "";
        let city = "";
        let pincode = "";
        addressComponents.forEach((component) => {
          if (
            component.types.includes("street_number") ||
            component.types.includes("premise")
          ) {
            houseNumber = component.long_name;
          }
          if (component.types.includes("route")) {
            route = component.long_name;
          }
          if (
            component.types.includes("sublocality_level_1") ||
            component.types.includes("sublocality_level_2") ||
            component.types.includes("sublocality_level_3")
          ) {
            neighbourhood = component.long_name;
          }
          if (component.types.includes("administrative_area_level_1")) {
            state = component.long_name;
          }
          if (component.types.includes("locality")) {
            city = component.long_name;
          }
          if (component.types.includes("postal_code")) {
            pincode = component.long_name;
          }
        });
        // const firstPartOfAddress = `${houseNumber} ${route}`.trim();
        // console.log(data);
        // console.log("setting from locationpage");
        setArea({
          area: neighbourhood,
          areaCity: city,
          areaState: state,
          areaPin: pincode,
        });
      }
    } catch (error) {
      // console.error("Error fetching address details:", error);
    }
  };

  useEffect(() => {
    fetchAddressDetails(
      coordinates.lat || 28.48218,
      coordinates.lng || 77.0933
    );
  }, [coordinates]);

  useEffect(() => {
    navigator.permissions.query({ name: "geolocation" }).then((result) => {
      if (result.state === "denied") {
        setShowLocationButton(false);
      }
    });
  }, [locationPermission]);

  return (
    <div className="overflow-y-scroll overflow-x-hidden relative h-[100dvh]">
      <button
        className="bg-formisPeach px-4 w-full grid grid-cols-4 items-center justify-center rounded-b-[30px] h-[52px] mb-5"
        onClick={() => dispatch(setAddressStep(null))}
      >
        <MdOutlineKeyboardArrowLeft className="text-2xl" />
        <p className="text-sm font-[700] col-span-2">
          Confirm map pin location
        </p>
      </button>

      <div
        onClick={() => dispatch(setAddressStep(3))}
        className="absolute z-[1] top-20 left-4 w-[calc(100%-2rem)] shadow-custom bg-formisWhite flex flex-col gap-4 rounded-[10px] mb-[6px]"
      >
        <div className="flex items-center py-[18px] px-3 gap-5">
          <img src={searchIcon} alt="Search" className="w-4" />
          <input
            className="bg-transparent outline-none w-full font-[700] text-formisBlack placeholder:text-formisBlack text-xs"
            placeholder="Search for area, street name..."
            readOnly
            value={addressSearchQuery || ""}
          />
        </div>
      </div>

      <div className="relative mx-3 mb-[6px] rounded-[10px]">
        <GoogleMap />
        <div className="pointer-events-none absolute w-fit text-[10px] top-[55%] p-1 rounded-[10px] font-semibold left-[48%] shadow-custom bg-[#203040] text-formisPeach">
          Move the pin to adjust your location
        </div>
      </div>

      <div className="fixed bottom-2 w-full">
        {showLocationButton && (
          <div className="mb-2 w-full flex items-center justify-center pointer-events-auto">
            <button
              onClick={handleGoToLocationClick}
              onTouchStart={(e) =>
                e.currentTarget.classList.add("scale-95", "opacity-70")
              }
              onTouchEnd={(e) =>
                e.currentTarget.classList.remove("scale-95", "opacity-70")
              }
              className="flex items-center gap-5 bg-formisWhite px-4 py-1 rounded-[10px] shadow-custom"
            >
              <BiTargetLock />
              <p className="relative bottom-[1.5px]">Go to current location</p>
            </button>
          </div>
        )}
        <div className="bg-formisWhite mx-3 p-3 rounded-[10px] mb-3  shadow-custom">
          <p className="text-xs font-[700] mb-2">Delivering your order to</p>

          <div
            onClick={() => dispatch(setAddressStep(3))}
            className="flex items-center justify-between shadow-custom px-3 py-2 rounded-[10px] mb-2"
          >
            <div className="flex items-center gap-7">
              {/* <ImLocation className="text-3xl text-red-600" /> */}
              <img src={pinIcon} alt="Location" className="w-5" />
              <div className="text-xs">
                <p className="font-[700]">{area?.area}</p>
                <p className="font-[500]">
                  {area?.areaCity}, {area?.areaPin}
                </p>
              </div>
            </div>
            {/* <button className="text-xs font-bold bg-[#203040] text-formisPeach rounded-[10px] py-1 px-2">
            Change
          </button> */}
          </div>

          {/* <p className="text-xs font-[700]">
          Pin location is 3.4km away from your current location.
        </p> */}
        </div>

        <div className="flex items-center justify-center">
          <button
            className="bg-formisPeach text-formisBlack rounded-[10px] py-3 w-[calc(100%-24px)] font-extrabold
                            flex items-center justify-center gap-7"
            onClick={() => {
              dispatch(
                setAddressDetails({
                  address2: area?.area,
                  city: area?.areaCity,
                  state: area?.areaState,
                  pinCode: area?.areaPin,
                })
              );
              setFillRemainingDetails(true);
            }}
          >
            <p className="tracking-[-0.4px]">Add more address details</p>
            <img src={buttonNext} alt="Next" />
          </button>
        </div>
      </div>

      <div
        className={`overflow-scroll absolute bottom-0 left-0 z-10 bg-gradient-to-b from-[#203040] via-[#203040] to-[#15171c] transition-all duration-300 ease-out h-full w-full text-formisBlack ${
          fillRemainingDetails ? "translate-x-0" : "translate-x-full"
        }`}
      >
        <RemainingDetails setFillRemainingDetails={setFillRemainingDetails} />
      </div>
    </div>
  );
};

export default LocationPage;
