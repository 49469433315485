import { useState } from "react";
import closePeach from "../../assets/icons/close-peach.svg";
import notify from "../../assets/icons/notify.svg";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { requestProductApi } from "../../api";
import { useDispatch, useSelector } from "react-redux";
import { setIsRequestOpen } from "../../features/searchSlice";

const RequestFlow = () => {
  const dispatch = useDispatch();

  const [step, setStep] = useState(1);
  const [request, setRequest] = useState("");
  const [fullName, setFullName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const { isRequestOpen } = useSelector((state) => state.search);

  gsap.config({ nullTargetWarn: false });

  useGSAP(() => {
    gsap.fromTo(
      ".header-step",
      {
        y: 250,
        x: -15,
        opacity: 0.25,
      },
      {
        y: 0,
        x: 0,
        opacity: 1,
        duration: 0.3,
      }
    );

    gsap.fromTo(
      ".input-step",
      {
        opacity: 0.25,
      },
      {
        opacity: 1,
        duration: 0.3,
      }
    );

    gsap.fromTo(
      ".button-step",
      {
        x: -15,
        opacity: 0.25,
      },
      {
        x: 0,
        opacity: 1,
        duration: 0.3,
      }
    );

    gsap.fromTo(
      ".final-step",
      {
        y: 100,
        opacity: 0.25,
      },
      {
        y: 0,
        opacity: 1,
        duration: 0.3,
      }
    );
  }, [step]);

  const sendRequest = async () => {
    try {
      const response = await fetch(requestProductApi, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          contact_number: phoneNumber,
          contact_name: fullName,
          requested_item: request,
        }),
      });
      // const data = await response.json();
      if (response.ok) {
        // console.log("Request Sent", data);
      } else {
      }
    } catch (err) {
      // console.log("Error sending request", err);
    }
  };

  return (
    <div
      className={`${
        isRequestOpen ? "opacity-100 z-[31]" : "opacity-0 -z-50"
      } transition-opacity duration-300 absolute top-0 left-0 bg-gradient-to-b from-formisBlack via-[#203040] to-formisBlack h-full w-full flex flex-col justify-end p-3`}
    >
      <img
        onClick={() => {
          if (step === 2) setStep(1);
          else {
            setStep(1);
            dispatch(setIsRequestOpen(false));
          }
        }}
        onTouchStart={(e) =>
          e.currentTarget.classList.add("scale-95", "opacity-70")
        }
        onTouchEnd={(e) =>
          e.currentTarget.classList.remove("scale-95", "opacity-70")
        }
        src={closePeach}
        alt="Close"
        className="w-14 mx-auto mb-[42px] transition-all"
      />
      <div className="h-[364.5px] w-full bg-formisBlack rounded-[20px] shadow-peachBox px-2 pt-6 pb-3">
        {step === 1 && (
          <Step1 setStep={setStep} request={request} setRequest={setRequest} />
        )}
        {step === 2 && (
          <Step2
            setStep={setStep}
            fullName={fullName}
            setFullName={setFullName}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            sendRequest={sendRequest}
          />
        )}
        {step === 3 && <Step3 setStep={setStep} />}
      </div>
    </div>
  );
};

export default RequestFlow;

const Step1 = ({ setStep, request, setRequest }) => {
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        setStep(2);
      }}
      className="flex flex-col h-full justify-between"
    >
      <div>
        <h5 className="header-step text-xs font-bold mb-2">
          Tell us what you need
        </h5>
        <p className="input-step text-[10px] max-w-[255px] font-medium font-[Hind] mb-2">
          Enter the name of the product that you would like to see on Formis®
          forEssentials.
        </p>
        <p className="input-step text-[10px] max-w-[255px] font-medium font-[Hind] mb-[43px]">
          Once requested we will make sure the requested product is available
          for you.
        </p>

        <textarea
          onChange={(e) => setRequest(e.target.value)}
          maxLength={250}
          required
          className="input-step w-full h-[91px] rounded-[10px] outline-none text-xs font-bold text-formisBlack p-2"
        />
        <div className="text-xs font-medium font-[Hind] flex justify-end mt-1">
          <p>{250 - request.length}/250 characters left.</p>
        </div>
      </div>

      <button
        type="submit"
        className="button-step bg-formisPeach text-formisBlack text-sm font-[Hind] h-10 rounded-[10px] font-semibold w-full"
      >
        Add a few more details
      </button>
    </form>
  );
};

const Step2 = ({
  setStep,
  setPhoneNumber,
  phoneNumber,
  setFullName,
  fullName,
  sendRequest,
}) => {
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        sendRequest();
        setStep(3);
      }}
      className="flex flex-col h-full justify-between"
    >
      <div>
        <h5 className="header-step text-xs font-bold mb-2">Get Notified</h5>
        <p className="input-step text-[10px] max-w-[277px] font-medium font-[Hind] mb-[43px]">
          Once the product is made available you will be notified through
          WhatsApp to view the same.
        </p>

        <div className="mb-2">
          <input
            onChange={(e) => setFullName(e.target.value)}
            maxLength={100}
            required
            className="input-step w-full h-[45px] rounded-[10px] outline-none text-sm font-semibold placeholder:text-formisBlack text-formisBlack px-2"
            placeholder="Your Full Name"
          />
          <div className="text-xs font-medium font-[Hind] flex justify-end mt-1">
            <p>{100 - fullName.length}/100 characters left.</p>
          </div>
        </div>

        <div>
          <div className="bg-formisWhite flex items-center rounded-[10px] text-formisBlack px-2">
            <p className="text-sm font-semibold">+91-</p>
            <input
              required
              onChange={(e) => {
                if (e.target.value.length <= 10) {
                  setPhoneNumber(e.target.value);
                }
              }}
              value={phoneNumber}
              className="input-step w-full h-[45px] bg-formisWhite rounded-[10px] outline-none text-sm font-semibold placeholder:text-formisBlack text-formisBlack px-2"
              type="number"
            />
          </div>
          <div className="text-xs font-medium font-[Hind] flex justify-end mt-1">
            <p>{10 - phoneNumber.length}/10 numerical digits left.</p>
          </div>
        </div>
      </div>

      <button
        type="submit"
        className="button-step bg-formisPeach text-formisBlack text-sm font-[Hind] h-10 rounded-[10px] font-semibold w-full"
      >
        Submit request
      </button>
    </form>
  );
};

const Step3 = () => {
  return (
    <div className="flex flex-col justify-center items-center text-center">
      <img src={notify} alt="Success" className="input-step w-32 mx-auto" />
      <div className="final-step flex flex-col justify-center items-center">
        <h4 className="text-lg font-bold mb-8">Thank You!</h4>
        <p className="font-[Hind] font-medium text-sm mb-3 max-w-[90%]">
          We have received your product request and our team will start working
          on it now.
        </p>
        <p className="font-[Hind] font-medium text-sm max-w-[65%]">
          Once, the product is made available we will notify you.
        </p>
      </div>
    </div>
  );
};
