import { FaFacebookF, FaYoutube } from "react-icons/fa";
import { FaXTwitter, FaInstagram } from "react-icons/fa6";
import { HiArrowSmLeft, HiArrowSmRight } from "react-icons/hi";
import { TiArrowSortedDown } from "react-icons/ti";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/autoplay";

import { topbarInfoApi } from "../api";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import close from "../assets/icons/close-peach.svg";
import back from "../assets/icons/back-peach.svg";

import { toast } from "sonner";

const Topbar = () => {
  const [info, setInfo] = useState();
  const [showPopup, setShowPopup] = useState(false);
  const { eta } = useSelector((state) => state.googleMapLocation);

  //POPUP TO DISPLAY COUPON CODES AND DESCRIPTIONS
  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  //INFO TO DISPLAY IN THE POPUP
  const fetchInfo = async () => {
    try {
      const response = await fetch(topbarInfoApi, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      if (response.ok) {
        setInfo(data);
        // console.log("Topbar data", data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchInfo();
  }, []);

  // DISABLE SCROLL IF POPUP IS OEN
  useEffect(() => {
    if (showPopup) {
      document.documentElement.style.overflow = "hidden";
    } else {
      document.documentElement.style.overflow = "auto";
    }
    return () => {
      document.documentElement.style.overflow = "auto";
    };
  }, [showPopup]);

  return (
    <div
      className={`fixed w-full ${
        showPopup ? "z-[100]" : "z-[19]"
      } bg-formisBlack text-formisPeach grid grid-cols-1 lg:grid-cols-3 h-12 md:h-14 text-[13px] md:text-[14px]`}
    >
      <div className="hidden lg:flex items-center gap-6 text-xl pl-[22px] md:pl-10 md2:pl-14 lg:pl-9 xl:pl-12">
        <FaFacebookF />
        <FaXTwitter />
        <FaInstagram />
        <FaYoutube />
      </div>

      <div className="flex items-center justify-between gap-4 px-[22px] md:px-10 md2:px-14 lg:px-9 xl:px-12">
        <HiArrowSmLeft
          onTouchStart={(e) =>
            e.currentTarget.classList.add("scale-95", "opacity-70")
          }
          onTouchEnd={(e) =>
            e.currentTarget.classList.remove("scale-95", "opacity-70")
          }
          className="text-lg cursor-pointer shrink-0 prev-btn transition-all duration-150"
        />
        <Swiper
          onClick={togglePopup}
          modules={[Navigation, Autoplay]}
          autoplay={{
            delay: 8000,
            disableOnInteraction: false,
          }}
          navigation={{
            nextEl: ".next-btn",
            prevEl: ".prev-btn",
          }}
          loop={true}
          className="lg:w-[30vw] text-center"
        >
          {info &&
            info.map((result) => (
              <SwiperSlide key={result.id}>
                <p className="font-[600] font-[Hind]">{result.info}</p>
              </SwiperSlide>
            ))}
        </Swiper>
        <HiArrowSmRight
          onTouchStart={(e) =>
            e.currentTarget.classList.add("scale-95", "opacity-70")
          }
          onTouchEnd={(e) =>
            e.currentTarget.classList.remove("scale-95", "opacity-70")
          }
          className="text-lg cursor-pointer shrink-0 next-btn transition-all duration-150"
        />
      </div>

      <Link
        to="/get-location"
        className="hidden lg:flex items-center justify-end gap-2 pr-[22px] md:pr-10 md2:pr-14 lg:pr-9 xl:pr-12"
      >
        <TiArrowSortedDown />
        <p>Delivery in</p>
        <p className="text-[16px] xl:text-xl font-bold">
          {eta && eta.eta_minutes} minutes
        </p>
      </Link>

      <hr className="absolute bottom-0 w-full seperator" />

      {/* POPUP */}
      <div
        className={`absolute top-0 left-0 flex items-center justify-center h-[100svh] w-screen bg-[#203040]/90 px-12 transition-all duration-300 ${
          showPopup
            ? "opacity-100 pointer-events-auto z-[100]"
            : "opacity-0 pointer-events-none -z-50"
        }`}
      >
        <Swiper
          modules={[Navigation, Autoplay]}
          autoplay={{
            delay: 10000,
            disableOnInteraction: true,
          }}
          navigation={{
            nextEl: ".coupon-next",
            prevEl: ".coupon-prev",
          }}
          loop={true}
          className="lg:w-[30vw] text-center"
        >
          {info &&
            info.map((result, index) => (
              <SwiperSlide key={index}>
                <div className="font-[Hind] text-lg font-bold leading-10 mb-16">
                  {result.code && <p>Copy Code “{result.code}”</p>}
                  <p>{result.info}</p>
                  <p>valid until December 31, 2024</p>
                  {result.code && (
                    <button
                      onClick={(e) => {
                        navigator.clipboard.writeText(result.code);
                        toast.success("Code Copied to Clipboard");
                      }}
                      onTouchStart={(e) =>
                        e.currentTarget.classList.add("scale-95", "opacity-70")
                      }
                      onTouchEnd={(e) =>
                        e.currentTarget.classList.remove(
                          "scale-95",
                          "opacity-70"
                        )
                      }
                      className="bg-formisPeach text-formisBlack leading-none px-8 py-2 rounded-[10px] text-xl mt-16 transition-all duration-150"
                    >
                      Copy Code
                    </button>
                  )}
                </div>
              </SwiperSlide>
            ))}
        </Swiper>

        <div className="absolute flex gap-16 bottom-[25%]">
          <img src={back} alt="Prev" className="coupon-prev w-8" />
          <img src={close} alt="Close" className="w-8" onClick={togglePopup} />
          <img src={back} alt="Next" className="coupon-next w-8 rotate-180" />
        </div>
      </div>
    </div>
  );
};

export default Topbar;
