export const etaApi = "https://kitsa.co.in/api/v1/eta?"
export const searchAllApi = `https://kitsa.co.in/api/v1/medicine-search?search=`
export const categoryProductSearch = `https://kitsa.co.in/api/v1/medicine-search-by-category?search=`
export const categoryProductsApi = `https://kitsa.co.in/api/v1/medicines/category/`
export const productDataApi = `https://kitsa.co.in/api/v1/medicines/`  //Send Slug
export const guestTokenApi = `https://kitsa.co.in/api/v1/guest-token`
export const searchTermsApi = `https://kitsa.co.in/api/v1/search-animation-terms`
export const searchSuggestionsApi = `https://kitsa.co.in/api/v1/search-bar-suggested-terms`

export const advertisementsApi = "https://kitsa.co.in/api/v1/advertisements"
export const categoriesMainPageApi = "https://kitsa.co.in/api/v1/categories"
export const topbarInfoApi = "https://kitsa.co.in/api/v1/topbar-info"
export const weatherApi = "https://kitsa.co.in/api/v1/weather-info?"
export const surgeApi = "https://kitsa.co.in/api/v1/surge"
export const featuredSectionsApi = "https://kitsa.co.in/api/v1/featured-sections"
export const availableCouponsApi = "https://kitsa.co.in/api/v1/active-valid-coupons"
export const applyCouponApi = "https://kitsa.co.in/api/v1/apply-coupon"
export const removeCouponApi = "https://kitsa.co.in/api/v1/remove-coupon"

export const requestProductApi = "https://kitsa.co.in/api/v1/request-item"
export const sendOtpApi = "https://kitsa.co.in/api/v1/auth/send-otp"
export const verifyOtpApi = "https://kitsa.co.in/api/v1/auth/verify-otp"
export const addressesApi = "https://kitsa.co.in/api/v1/addresses"
export const profileApi = "https://kitsa.co.in/api/v1/profile"
export const addToCartApi = `https://kitsa.co.in/api/v1/cart/add`
export const deleteFromCartApi = `https://kitsa.co.in/api/v1/cart/remove`
export const getCartDetailsApi = `https://kitsa.co.in/api/v1/cart/detail`
export const placeOrderApi = `https://kitsa.co.in/api/v1/place-order`
export const recentSearchApi = "https://kitsa.co.in/api/v1/search-query"
export const uploadPrescriptionApi = "https://kitsa.co.in/api/v1/upload-prescription"