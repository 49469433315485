import { useEffect, useState } from "react";
import closePeach from "../../assets/icons/close-peach.svg";
import notify from "../../assets/icons/notify.svg";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { uploadPrescriptionApi } from "../../api";
import { toast } from "sonner";

gsap.config({ nullTargetWarn: false });

const UploadPrescription = ({ showUploadFlow, setShowUploadFlow }) => {
  const [step, setStep] = useState(1);
  const [files, setFiles] = useState([]);
  const [comments, setComments] = useState("");

  useEffect(() => {
    if (files.length === 0) setStep(1);
  }, [files]);

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setFiles(selectedFiles);
    setStep(2);
  };

  useGSAP(() => {
    gsap.fromTo(
      ".header-step",
      {
        y: 250,
        x: -15,
        opacity: 0.25,
      },
      {
        y: 0,
        x: 0,
        opacity: 1,
        duration: 0.3,
      }
    );

    gsap.fromTo(
      ".input-step",
      {
        opacity: 0.25,
      },
      {
        opacity: 1,
        duration: 0.3,
      }
    );

    gsap.fromTo(
      ".button-step",
      {
        x: -15,
        opacity: 0.25,
      },
      {
        x: 0,
        opacity: 1,
        duration: 0.3,
      }
    );

    gsap.fromTo(
      ".final-step",
      {
        y: 100,
        opacity: 0.25,
      },
      {
        y: 0,
        opacity: 1,
        duration: 0.3,
      }
    );
  }, [step]);

  return (
    <div
      className={`${
        showUploadFlow ? "opacity-100" : "opacity-0 -z-50 pointer-events-none"
      } transition-opacity duration-300 fixed z-[31] text-formisPeach top-0 left-0 bg-gradient-to-b from-formisBlack via-[#203040] to-formisBlack h-[100dvh] w-full flex flex-col justify-end p-3`}
    >
      <img
        onClick={() => {
          if (step === 2) setStep(1);
          else {
            setStep(1);
            setShowUploadFlow(false);
          }
        }}
        onTouchStart={(e) =>
          e.currentTarget.classList.add("scale-95", "opacity-70")
        }
        onTouchEnd={(e) =>
          e.currentTarget.classList.remove("scale-95", "opacity-70")
        }
        src={closePeach}
        alt="Close"
        className="w-14 mx-auto mb-[42px] transition-all duration-150"
      />
      <div className="h-[364.5px] w-full bg-formisBlack rounded-[20px] shadow-peachBox px-2 pt-6 pb-3">
        {step === 1 && (
          <Step1
            setStep={setStep}
            handleFileChange={handleFileChange}
            comments={comments}
            setComments={setComments}
          />
        )}
        {step === 2 && (
          <Step2
            setStep={setStep}
            files={files}
            setFiles={setFiles}
            comments={comments}
            setComments={setComments}
          />
        )}
        {step === 3 && <Step3 />}
      </div>
    </div>
  );
};

export default UploadPrescription;

const Step1 = ({ handleFileChange, comments, setComments }) => {
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
      }}
      className="flex flex-col h-full justify-between"
    >
      <div>
        <h5 className="header-step text-xs font-bold mb-2">
          Upload your doctor’s advice
        </h5>
        <p className="input-step text-[10px] max-w-[255px] font-medium font-[Hind] mb-2">
          Please upload a clear photograph or attach a pdf for our pharmacist to
          read, verify and place an order on your behalf.
        </p>
        <p className="input-step text-[10px] max-w-[255px] font-medium font-[Hind] mb-[43px]">
          If in case, we aren’t able to scan it through, our team will get in in
          touch with you to confirm the essentials.
        </p>

        <div className="flex flex-col h-[91px]">
          <div className="h-[] bg-formisWhite rounded-t-[10px] text-formisBlack text-[10px] font-bold font-[Hind] px-2 pt-2">
            Instructions
          </div>
          <textarea
            maxLength={250}
            required
            onChange={(e) => setComments(e.target.value)}
            className="input-step w-full flex-1 rounded-b-[10px] outline-none text-xs font-bold bg-formisWhite text-formisBlack p-2"
          />
        </div>
        <div className="text-xs font-medium font-[Hind] flex justify-end mt-1">
          <p>{250 - comments?.length}/250 characters left.</p>
        </div>
      </div>

      <button
        type="submit"
        className="button-step bg-formisPeach text-formisBlack text-sm font-[Hind] h-10 rounded-[10px] font-semibold w-full"
        onClick={() => {
          if (comments.length > 0) {
            document.getElementById("fileInput").click();
          }
        }}
      >
        Upload Files
      </button>
      <input
        id="fileInput"
        type="file"
        multiple
        className="hidden"
        onChange={handleFileChange}
      />
    </form>
  );
};

const Step2 = ({ setStep, files, setFiles, comments, setComments }) => {
  const handleFileUpload = async () => {
    const access = localStorage.getItem("accessToken");

    if (!files || files.length === 0) {
      alert("Please select files first.");
      return;
    }

    const formData = new FormData();
    files.forEach((file) => formData.append("image", file));
    formData.append("comments", comments);

    try {
      const response = await fetch(uploadPrescriptionApi, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${access}`,
        },
        body: formData,
      });

      const result = await response.json();
      if (response.ok) {
        setFiles([]);
        setComments("");
        setStep(3);
        toast.success("Your Prescription has been Uploaded");
      }
    } catch (error) {
      // console.error("Something went wrong. Please try again.", error);
    }
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleFileUpload();
      }}
      className="flex flex-col h-full justify-between"
    >
      <div>
        <h5 className="header-step text-xs font-bold mb-6">
          Please review your attachments before <br />
          submitting.
        </h5>

        <div className="space-y-5 h-[225px] overflow-y-scroll">
          {files.map((file, index) => (
            <div key={index}>
              <div className="bg-[#203040] font-semibold text-xs h-[38px] border border-formisPeach flex items-center justify-between w-full rounded-[10px] px-3 mb-4">
                <p className="text-xs font-[Hind] font-semibold text-formisPeach">
                  {file.name}
                </p>
              </div>
              <div className="flex justify-center items-center px-[6px]">
                <button
                  type="button"
                  className="font-bold text-xs flex items-center justify-center rounded-[5px] gap-3 w-[122px] py-2 shadow-peachBox"
                  onClick={() =>
                    setFiles((prev) => prev.filter((_, i) => i !== index))
                  }
                >
                  <img src={closePeach} alt="Remove" className="w-7" />
                  Remove
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>

      <button
        type="submit"
        className="button-step bg-formisPeach text-formisBlack text-sm font-[Hind] h-10 rounded-[10px] font-semibold w-full"
      >
        Submit
      </button>
    </form>
  );
};

const Step3 = () => {
  return (
    <div className="flex flex-col justify-center items-center text-center">
      <img src={notify} alt="Success" className="input-step w-32 mx-auto" />
      <div className="final-step flex flex-col justify-center items-center">
        <h4 className="text-lg font-bold mb-8">Doctor's Advice Received</h4>
        <p className="font-[Hind] font-medium text-sm mb-3 max-w-[90%]">
          We have received your essentials request and our team will start
          working on it now.
        </p>
        <p className="font-[Hind] font-medium text-sm max-w-[65%]">
          Once, the essentials are listed and is made available we will notify
          you.
        </p>
      </div>
    </div>
  );
};
