import FAQ from "../components/FAQ/FAQ";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Topbar from "../components/Topbar";
import SearchBar from "../components/Main Page Components/SearchBar";
import SplashPage from "../components/SplashPage";
import {
  categoryProductsApi,
  featuredSectionsApi,
  guestTokenApi,
} from "../api";
import { useEffect, useRef, useState } from "react";
import MobileFooter from "../components/MobileFooterMenu";
import CategoriesSlider from "../components/Main Page Components/CategoriesSlider";
import heart from "../assets/icons/heart.svg";

import CategoryMain from "../components/Main Page Components/CategoryMain";

import UploadPrescription from "../components/Main Page Components/UploadPrescription";
import Search from "../components/Search";
import Profile from "../components/Profile";
import AddressSlider from "../components/AddressSlider";
import SurgeSlider from "../components/SurgeSlider";
import ViewCartButton from "../components/Footer Menu Buttons/ViewCartButton";
import useCartDetails from "../hooks/useCartDetails";
import SponsorCard from "../components/Main Page Components/SponsorCard";

const categories = [
  "Skin, hair & oral care",
  "Stomach care",
  "Sexual wellness",
  "Feminine Care",
  "Fitness Supplements",
  "Vitamins & Nutrition",
  "Ayurvedic Supplements",
  "Nutritional Drinks",
  "Health Conditions",
  "Wellness",
  "Pain Relief",
  "Skin Care",
  "Personal Care",
  "Supports & Braces",
  "Healthcare Devices",
  "Elderly Care",
  "Diabetes",
  "Monitoring Devices",
  "Hair Care",
  "First Aid",
  "Baby Care",
  "Protect+ Essentials",
  "Immunity Boosters",
  "Healthy Snacks",
  "Homeopathy",
  "Eye & Ear Care",
  "Pet Care",
  "Herbal Juice",
  "Cold, Cough & Fever",
  "Rehydration Beverages",
  "Fever & Headache",
  "Skin Infection",
];

const Main = () => {
  const isAuthenticated = localStorage.getItem("isAuthenticated");
  const [showUploadFlow, setShowUploadFlow] = useState();
  const [showSplash, setShowSplash] = useState(false);

  const [results, setResults] = useState([]);
  const [featured, setFeatured] = useState([]);
  const [isCategoriesLoading, setIsCategoriesLoading] = useState(false);
  const lastCategoryRef = useRef(null);
  const isEffectCalled = useRef(false);
  useCartDetails();

  //GUEST TOKEN IF USER IS NOT AUTHENTICATED
  const getGuestToken = async () => {
    try {
      const response = await fetch(guestTokenApi, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      if (response.ok) {
        localStorage.setItem("guestToken", data.guest_token);
      }
    } catch {}
  };

  //MAINTAIN SESSION STORAGE TO NOT DISPLAY SPLASH PAGE REPEATEDLY ON NAVIGATION
  useEffect(() => {
    if (!sessionStorage.getItem("hasSeenSplash")) {
      setShowSplash(true);
      sessionStorage.setItem("hasSeenSplash", "true");
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated !== "true") getGuestToken();
  }, []);

  const fetchCategory = async () => {
    setIsCategoriesLoading(true);
    try {
      const nextCategories = categories.slice(
        results.length,
        results.length + 5
      );
      const responses = await Promise.all(
        nextCategories.map(async (category) => {
          const response = await fetch(categoryProductsApi + category);
          if (response.ok) {
            const data = await response.json();
            // console.log("Fetched for category " + category);
            return { category, data };
          } else {
            throw new Error(`Failed to fetch for parameter: ${category}`);
          }
        })
      );

      setResults((prevResults) => [...prevResults, ...responses]);
    } catch (err) {
      console.error("ERROR IN CATEGORIES FETCHING", err);
    } finally {
      setIsCategoriesLoading(false);
    }
  };

  const featuredBlogs = async () => {
    try {
      const response = await fetch(featuredSectionsApi, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      if (response.ok) {
        setFeatured(data);
      }
    } catch {}
  };

  useEffect(() => {
    if (isEffectCalled.current === false) {
      featuredBlogs();
      fetchCategory();
    }
    return () => (isEffectCalled.current = true);
  }, []);

  useEffect(() => {
    if (!lastCategoryRef.current || results.length === 0) return;
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && results.length < categories.length)
          fetchCategory();
      },
      { root: null, threshold: 1 }
    );

    observer.observe(lastCategoryRef.current);

    return () => {
      observer.disconnect();
    };
  }, [results]);

  return (
    <div
      id="main-page"
      className="h-screen overflow-x-hidden overflow-y-scroll scrollbar-hide"
    >
      {showSplash && <SplashPage />}

      <Topbar />
      <div className="bg-[#203040] pt-12 md:pt-14">
        <Header />
      </div>
      <div className="sticky left-0 top-12 md:top-14 z-[18] ">
        <SearchBar
          bg="bg-[#203040]"
          showUpload={true}
          setShowUploadFlow={setShowUploadFlow}
        />
      </div>

      <div className="bg-formisBlack pb-7">
        <div className="bg-gradient-to-b from-[#203040] to-formisBlack rounded-b-[30px]">
          <CategoriesSlider />
        </div>
      </div>

      <div>
        {results.map((res, index) => {
          const isLastCategory = index === results.length - 1;
          const shouldShowSponsorCard = (index + 1) % 10 === 0;
          const sponsorCardIndex = Math.floor(index / 10) * 4;

          return (
            <div key={index}>
              <div
                ref={
                  isLastCategory && !shouldShowSponsorCard
                    ? lastCategoryRef
                    : null
                }
              >
                <CategoryMain result={res.data} name={res.category} />
              </div>

              {shouldShowSponsorCard && (
                <div
                  ref={
                    isLastCategory && shouldShowSponsorCard
                      ? lastCategoryRef
                      : null
                  }
                >
                  <SponsorCard
                    featured={featured.slice(
                      sponsorCardIndex,
                      sponsorCardIndex + 4
                    )}
                  />
                </div>
              )}
            </div>
          );
        })}
        {isCategoriesLoading && (
          <div className="w-full pt-4 bg-formisBlack mx-auto flex items-center justify-center">
            <div className="loader4"></div>
          </div>
        )}
      </div>

      <div className="text-[#666666]/50 pt-14 px-3 bg-formisBlack">
        <h1 className="text-[67px] font-black leading-none">Delivered!</h1>
        <h2 className="text-[42px] font-extrabold leading-none mb-3">
          Before The Panic.
        </h2>
        <p className="text-lg font-bold mb-8">
          It is FORMIS® for You from Gurugram, India.
        </p>
        <img src={heart} alt="FORMIS" className="mx-auto" />
      </div>

      <div className="bg-formisBlack pt-10">
        <FAQ />
      </div>

      <Footer />
      <div className="fixed z-[30] bottom-0 w-full left-0">
        <ViewCartButton />
        <MobileFooter />
      </div>
      <UploadPrescription
        showUploadFlow={showUploadFlow}
        setShowUploadFlow={setShowUploadFlow}
      />
      <Search />
      <Profile />
      <AddressSlider />
      <SurgeSlider />
      <UploadPrescription
        showUploadFlow={showUploadFlow}
        setShowUploadFlow={setShowUploadFlow}
      />
    </div>
  );
};

export default Main;
