import { createSlice } from "@reduxjs/toolkit";

export const slidersSlice = createSlice({
  name: "sliders",

  initialState: {
    isSurgeSliderOpen: false,
    showSurge: null,
    showComingSoon: false,
  },

  reducers: {
    setIsSurgeSliderOpen: (state, action) => {
      state.isSurgeSliderOpen = action.payload;
    },
    setShowSurge: (state, action) => {
      state.showSurge = action.payload;
    },
    setShowComingSoon: (state, action) => {
      state.showComingSoon = action.payload;
    },
  },
});

export const { setIsSurgeSliderOpen, setShowSurge, setShowComingSoon } = slidersSlice.actions;
export default slidersSlice.reducer;
