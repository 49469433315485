import { useEffect, useState } from "react";
import { IoSearchOutline } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { toggleSearchComponent } from "../../features/searchSlice";
import { searchTermsApi } from "../../api";
import { useNavigate } from "react-router-dom";

const SearchBar = ({ bg, showUpload, setShowUploadFlow }) => {
  const isAuthenticated = localStorage.getItem("isAuthenticated");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentText, setCurrentText] = useState("");
  const [visible, setVisible] = useState(true);
  const [list, setList] = useState([]);

  const getSearchTerms = async () => {
    try {
      const response = await fetch(searchTermsApi, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      if (response.ok) {
        setList(data.results);
      }
    } catch {}
  };

  useEffect(() => {
    getSearchTerms();
  }, []);

  let i = 0;

  useEffect(() => {
    const textAnimation = () => {
      if (list && list.length > 0) setCurrentText(list[i].term);
      setVisible(true);
      setTimeout(() => setVisible(false), 1700);
      i = (i + 1) % list?.length;
    };
    textAnimation();
    const interval = setInterval(() => {
      textAnimation();
    }, 2000);
    return () => clearInterval(interval);
  }, [list]);

  return (
    <div
      id="search-bar"
      className={`px-[10px] md:px-10 ${bg} md2:px-14 lg:px-10 xl:px-12 pt-3 pb-5`}
    >
      <div
        onClick={() => dispatch(toggleSearchComponent())}
        className="flex items-center gap-3 bg-formisWhite rounded-[10px] px-3 h-[51px] backdrop-blur-sm"
      >
        <IoSearchOutline className="text-xl" />
        <div className="flex items-center gap-2 bg-transparent font-[500] w-full placeholder:text-formisBlack/60 outline-none">
          {/* <div>Search For</div> */}

          <div
            className={`text-[13px] font-[600] leading-[-0.3px] md:text-base slider left-[-5px] ${
              visible
                ? "opacity-100 translate-y-0"
                : "opacity-0 -translate-y-full"
            } transition-all`}
          >
            {currentText}
          </div>
        </div>
      </div>

      {showUpload && (
        <div className="pt-5">
          <div className="flex items-center justify-center gap-5">
            <p className="text-formisPeach font-bold text-sm">
              Or simply order using your Rx
            </p>
            <button
              onClick={() => {
                if (isAuthenticated === "true") setShowUploadFlow(true);
                else navigate("/login");
              }}
              onTouchStart={(e) =>
                e.currentTarget.classList.add("scale-95", "opacity-70")
              }
              onTouchEnd={(e) =>
                e.currentTarget.classList.remove("scale-95", "opacity-70")
              }
              className="bg-[#203040] font-bold text-formisPeach text-sm border border-formisPeach/30 px-4 py-[2px] rounded-[10px] transition-all duration-150"
            >
              Upload Here
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchBar;
