import { createSlice } from "@reduxjs/toolkit";

export const searchSlice = createSlice({
  name: "search",

  initialState: {
    isSearchOpen: false,
    whichProduct: "",
    whichCategory: "",

    isRequestOpen: false,
  },

  reducers: {
    toggleSearchComponent: (state) => {
      state.isSearchOpen = !state.isSearchOpen;
    },
    handleWhichProduct: (state, action) => {
      state.whichProduct = action.payload;
    },
    handleWhichCategory: (state, action) => {
      state.whichCategory = action.payload;
    },
    setSearchComponent: (state, action) => {
      state.isSearchOpen = action.payload;
    },
    setIsRequestOpen: (state, action) => {
      state.isRequestOpen = action.payload;
    },
  },
});

export const {
  toggleSearchComponent,
  handleWhichProduct,
  handleWhichCategory,
  setSearchComponent,
  setIsRequestOpen
} = searchSlice.actions;
export default searchSlice.reducer;
