import { useEffect, useState } from "react";
import logo from "../assets/foressentials-logo-peach.png";
import splash from "../assets/splash.svg";

const SplashPage = () => {
  const [translate, setTranslate] = useState(true);
  const [hidden, setHidden] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setTranslate(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setHidden(true);
    }, 2500);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (hidden === false) {
      document.documentElement.style.overflow = "hidden";
      document.body.style.height = "100vh";
      document.body.style.overflow = "hidden";
    } else {
      document.documentElement.style.overflow = "auto";
      document.body.style.height = "auto";
      document.body.style.overflow = "auto";
    }

    return () => {
      document.documentElement.style.overflow = "auto";
      document.body.style.height = "auto";
      document.body.style.overflow = "auto";
    };
  }, [hidden]);

  return (
    <div
      className={`transition-transform duration-500 overflow-hidden h-[100svh] w-screen absolute top-0 left-0 bg-[#203040] z-50 ${
        !translate ? "translate-y-[-100dvh]" : "translate-y-0"
      } ${hidden && "hidden"}`}
    >
      <div className="flex items-center justify-center bg-[#203040] h-[80%]">
        <img src={logo} alt="FORMIS" className="w-72" />
      </div>

      <div className="relative flex items-center justify-center pt-16 h-[20%]">
        <img
          src={splash}
          alt="FORMIS"
          className="scale-150 absolute top-5 left-0"
        />
        <div className="relative z-10 text-center">
          <h3 className="text-formisBlack font-extrabold text-xl">
            Delivered! Before The Panic.
          </h3>
          <p className="text-sm font-medium">
            It is <b>FORMIS® for You</b> from Gurugram, <b>India.</b>
          </p>
        </div>
      </div>
    </div>
  );
};

export default SplashPage;
