const Button = ({ children, onClick }) => {
  return (
    <button
      onTouchStart={(e) =>
        e.currentTarget.classList.add("scale-95", "opacity-70")
      }
      onTouchEnd={(e) =>
        e.currentTarget.classList.remove("scale-95", "opacity-70")
      }
      onClick={onClick}
      className="font-[Hind] font-bold text-[11px] bg-formisPeach h-[28px] flex items-center justify-center w-[111px] rounded-[8px] shadow-md shadow-formisOrange text-formisBlack transition-all"
    >
      {children}
    </button>
  );
};

export default Button;
