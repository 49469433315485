import { configureStore } from "@reduxjs/toolkit";
import cartReducer from "../features/cartSlice";
import mapReducer from "../features/mapSlice";
import searchReducer from "../features/searchSlice";
import profileReducer from "../features/profileSlice";
import apiCartReducer from "../features/apiCartSlice"
import slidersReducer from "../features/slidersSlice"

export const store = configureStore({
  reducer: {
    cartItems: cartReducer,
    googleMapLocation: mapReducer,
    search: searchReducer,
    profile: profileReducer,
    apiCart: apiCartReducer,
    sliders: slidersReducer
  },
});
