import { lazy, Suspense, useEffect } from "react";

import Header from "../components/Header";

import dosage from "../assets/icons/dosage.svg";
import salt from "../assets/icons/salt-composition.svg";
import certified from "../assets/icons/certified.svg";
import forAlt from "../assets/icons/forAlt.svg";
import swap from "../assets/icons/swap.svg";
import etaImg from "../assets/icons/eta.svg";

import MobileFooter from "../components/MobileFooterMenu";
// import ProductCard from "../components/Main Page Components/ProductCard";
// import { useDispatch, useSelector } from "react-redux";
// import { Link } from "react-router-dom";

import back from "../assets/icons/back-peach.svg";
import keep from "../assets/icons/keep-tick.svg";
import closePeach from "../assets/icons/close-peach.svg";
// import Search from "../components/Search";
// import AddressSlider from "../components/AddressSlider";

import { FaMinus, FaPlus } from "react-icons/fa";
import imageNA from "../assets/Image-NA.webp";
import discount from "../assets/icons/discount.svg";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useSelector } from "react-redux";

const Search = lazy(() => import("../components/Search"));
const Profile = lazy(() => import("../components/Profile"));
const AddressSlider = lazy(() => import("../components/AddressSlider"));
const SurgeSlider = lazy(() => import("../components/SurgeSlider"));

const Alternates = () => {
  const { updates } = useSelector((state) => state.apiCart);
  useEffect(() => {
    console.log("alternates", updates);
  }, []);

  return (
    <div className="text-formisPeach bg-formisBlack min-h-[100dvh] pb-72">
      <div className="h-10 bg-formisBlack font-[Hind] font-bold text-xs flex items-center justify-center">
        <p>A few of your essentials are not available for immediate delivery</p>
      </div>
      <Header />

      <div className="pt-8">
        <div className="px-3 text-xs font-bold font-[Hind] pb-4">
          <p className="mb-2">We have SWITCH options for you</p>
          <p>Switch Options are products that have 100% same:</p>
        </div>

        <div className="pl-3 flex gap-[20px] overflow-x-scroll scrollbar-hide pt-[2px] pb-[6px] mb-7">
          <div className="shadow-peachBox w-[110px] h-[91px] px-2 pt-[14px] rounded-[10px] shrink-0">
            <img src={salt} alt="Salt Composition" className="mb-5" />
            <p className="text-[11px] font-bold">Salt and Composition</p>
          </div>
          <div className="shadow-peachBox w-[110px] h-[91px] px-2 pt-[14px] rounded-[10px] shrink-0">
            <img src={dosage} alt="Salt Composition" className="mb-2" />
            <p className="text-[11px] font-bold">Pattern and Dosage</p>
          </div>
          <div className="shadow-peachBox w-[110px] h-[91px] px-2 pt-[14px] rounded-[10px] shrink-0">
            <img src={dosage} alt="Salt Composition" className="mb-2" />
            <p className="text-[11px] font-bold">Product Concentration</p>
          </div>
          <div className="shadow-peachBox w-[110px] h-[91px] px-2 pt-[14px] rounded-[10px] shrink-0">
            <img src={dosage} alt="Salt Composition" className="mb-2" />
            <p className="text-[11px] font-bold">Product Concentration</p>
          </div>
        </div>

        <div className="flex items-center px-3 justify-between mb-12">
          <div className="flex items-center gap-2">
            <img src={certified} alt="Cert" className="w-[34px]" />
            <p className="text-xs font-bold">Verified by Experts</p>
          </div>
          <div className="flex items-center gap-2">
            <img src={forAlt} alt="Cert" className="w-[34px]" />
            <p className="text-xs font-bold">FORMIS® forAlt Engine</p>
          </div>
        </div>

        <div className="flex flex-col gap-12">
          {updates?.order_items
            .filter((item) => item.user_action_required === true)
            .map((item, index) => (
              <AlternateCard num={index} key={index} data={item} />
            ))}
        </div>
      </div>

      {/* Available Items */}
      <div className="shadow-alternateCard rounded-[10px] px-[6px] py-2 mb-3 mx-2 mt-10">
        <div className="flex items-center justify-between">
          <div className="font-[Hind] font-bold">
            <p className="text-xs">Available essentials from your cart</p>
          </div>
        </div>
        <div className="flex flex-col gap-4 pt-4">
          {updates?.order_items
            .filter((item) => item.user_action_required !== true)
            .map((item, index) => (
              <ProductCardAvailable key={index} product={item} />
            ))}
        </div>
      </div>

      <div className="fixed z-[30] bottom-0 w-full left-0">
        <AlternateButton />
        <MobileFooter />
      </div>

      <Suspense fallback={<div>Loading...</div>}>
        <AddressSlider />
        <Search />
        <Profile />
        <SurgeSlider />
      </Suspense>
    </div>
  );
};

export default Alternates;

const AlternateButton = () => {
  // const isAuthenticated = localStorage.getItem("isAuthenticated");
  // const { items } = useSelector((state) => state.cartItems);
  // const userCart = useSelector((state) => state.apiCart.userCartItems);
  const navigate = useNavigate();

  return (
    <div className="relative flex items-center justify-center gap-3 mb-3">
      <div
        className={`relative flex items-center gap-2 justify-center text-formisPeach`}
      >
        <div className="bg-[#203040] w-fit px-2 py-1 rounded-full flex items-center gap-3">
          <div className="size-7 border border-formisPeach rounded-full flex items-center justify-center font-[Hind] text-[11px] font-bold">
            0
          </div>
          <div
            className="text-xs font-bold"
            onClick={() => navigate("/track-order")}
          >
            Submit your cart
          </div>
          <img src={back} alt="next" className="rotate-180 w-4" />
        </div>
      </div>

      <button className="bg-[#203040] border border-formisPeach rounded-full text-[10px] font-bold h-[30px] w-[101px] shadow-cancel">
        Cancel order
      </button>
    </div>
  );
};

const AlternateCard = ({ num, data }) => {
  const [keepProduct, setKeepProduct] = useState(false);
  const [switchProduct, setSwitchProduct] = useState(false);

  return (
    <div className="px-2">
      <div className="border border-[#666666] size-[35px] flex items-center justify-center rounded-full mx-auto font-[Hind] text-[17px] font-bold">
        {num}
      </div>
      <div className="shadow-alternateCard rounded-[20px] pl-2 pr-[15px] pt-2 pb-6 mb-5">
        <div className="flex justify-between">
          <div>
            <div className="flex items-center border border-formisPeach gap-2 px-[5px] py-[2px] rounded-full w-fit mx-auto mb-2">
              <p className="text-[11px] tracking-[-0.28px] font-bold leading-none">
                Out of stock
              </p>
              <img src={etaImg} alt="ETA" />
            </div>
            <div className={`${switchProduct && "opacity-45"} transition-opacity duration-150`}>
              <ProductCard data={data} product={data.product} />
            </div>
          </div>
          <img src={swap} alt="Swap" />
          <div>
            <div className="flex items-center border border-formisPeach gap-2 px-[5px] py-[2px] rounded-full w-fit mx-auto mb-2">
              <p className="text-[11px] tracking-[-0.28px] font-bold leading-none">
                16 mins
              </p>
              <img src={etaImg} alt="ETA" />
            </div>
            <div className={`${keepProduct && "opacity-45"} transition-opacity duration-150`}>
              <ProductCard
                data={data.alternate_product}
                product={data.alternate_product.product}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-between px-2">
        <button
          onClick={() => {
            setKeepProduct(true);
            setSwitchProduct(false);
          }}
          className={`${
            keepProduct ? "bg-[#666666]" : "bg-formisBlack shadow-peachBox"
          } font-bold text-xs flex items-center justify-center rounded-[5px] gap-3 w-[90px] py-2`}
        >
          <img src={keep} alt="Check" className="w-7" />
          Keep
        </button>
        <button
          onClick={() => {
            setSwitchProduct(true);
            setKeepProduct(false);
          }}
          className={`${
            switchProduct ? "bg-[#666666]" : "bg-formisBlack shadow-peachBox"
          } font-bold text-xs flex items-center justify-center rounded-[5px] gap-3 w-[90px] py-2`}
        >
          <img src={swap} alt="Check" />
          Switch
        </button>
        <button className="font-bold text-xs flex items-center justify-center rounded-[5px] gap-3 w-[122px] py-2 shadow-peachBox">
          <img src={closePeach} alt="Check" className="w-7" />
          Remove
        </button>
      </div>
    </div>
  );
};

const ProductCard = ({ product, data }) => {
  const navigate = useNavigate();

  return (
    <div className="w-[145.65px]">
      <div className="relative mb-4 w-[145.65px]">
        <div
          onClick={() => navigate(`/product/${product.slug_field}`)}
          className="p-[5px] bg-white rounded-[10px] h-[144px] w-[145.65px]"
        >
          <img
            src={
              product?.primary_image_url ===
              "http://34.131.96.18/wp-content/uploads/2024/11/Image-NA.png"
                ? imageNA
                : product.primary_image_url
            }
            loading="lazy"
            className="aspect-square rounded-[10px]"
            alt="Product"
          />
          <div className="h-[28px] w-[23px] absolute -top-[7px] z-10 -left-[1px]">
            <img src={discount} alt="discount!" className="" />
            <div className="absolute top-0 left-0 h-[28px] w-[23px] flex items-center justify-center">
              <p className="font-[Hind] text-[10px] text-formisBlack font-bold leading-none">
                {parseInt(data.discount_percentage)}%
                <br /> OFF
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="flex items-center gap-1 mb-[6px]">
        <div className="bg-formisOrange rounded-full">
          <span className="capitalize text-[10px] font-extrabold px-1 leading-none h-[13px] tracking-[-0.25px] flex items-center justify-center rounded-[10px] bg-gradient-to-b from-formisOrange to-formisBlack/70 border border-formisOrange">
            {product.packaging}
          </span>
        </div>
      </div>

      <div className="flex w-full items-center justify-between">
        <div>
          <p className="font-[Hind] font-[700] text-[13px] leading-4 pr-1">
            {product.name}
          </p>

          <p className="text-[8px] font-bold mb-1">Quantity: {data.quantity}</p>

          <div className="flex justify-between text-formisBlue font-[Hind] text-[8px] font-bold">
            <div>
              <p>Salt Composition</p>
              <p>
                {product.salt_composition !== ""
                  ? product.salt_composition
                  : "NA"}
              </p>
            </div>
            <button className="shrink-0 bg-formisBlue text-formisBlack rounded-full h-fit px-1 py-[1px]">
              Dosage Calculator
            </button>
          </div>

          <div className="flex items-center gap-2 font-[Hind]">
            <div className="flex items-center gap-1">
              <p className="text-[12px] font-[600]">
                ₹{data.selling_price_at_time}
              </p>
              <span className="text-[10px] font-[600]">
                MRP <s>₹{data.mrp}</s>
              </span>
            </div>
            <div className="h-[23px] w-[19px] relative">
              <img src={discount} alt="discount!" />
              <div className="absolute -top-[2px] -left-[2px] h-[28px] w-[23px] flex items-center justify-center">
                <p className="font-[Hind] text-[8px] text-formisBlack font-bold leading-none">
                  {parseInt(data.discount_percentage)}%
                  <br /> OFF
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ProductCardAvailable = ({ product }) => {
  return (
    <div className="flex gap-4 justify-between">
      <div className="flex gap-4">
        <div className="bg-white rounded-[10px] relative shrink-0 h-[77px] w-[78px]">
          <img
            src={
              product?.product.primary_image_url ===
              "http://34.131.96.18/wp-content/uploads/2024/11/Image-NA.png"
                ? imageNA
                : product.product.primary_image_url
            }
            loading="lazy"
            alt="image"
            className="h-[77px] w-[78px] rounded-[10px]"
          />
          <div className="h-[23px] w-[19px] absolute -top-[7px] z-10 -left-[1px]">
            <img src={discount} alt="discount!" className="" />
            <div className="absolute -top-1 -left-[2.5px] h-[28px] w-[23px] flex items-center justify-center">
              <p className="font-[Hind] text-[8px] text-formisBlack font-bold leading-none">
                {parseInt(product.discount_percentage)}%
                <br /> OFF
              </p>
            </div>
          </div>
        </div>

        <div className="shrink">
          <p className="font-[Hind] text-xs font-bold mb-1">
            {product.product.name}
          </p>
          <div className="flex items-center gap-1">
            <div className="bg-formisOrange rounded-full">
            <span className="capitalize text-[10px] font-extrabold px-1 leading-none h-[13px] tracking-[-0.25px] flex items-center justify-center rounded-[10px] bg-gradient-to-b from-formisOrange to-formisBlack/70 border border-formisOrange">
                {product.product.packaging}
              </span>
            </div>
          </div>

          <button className="font-[Hind] text-[10px] font-bold text-formisWhite border-b border-formisWhite leading-none">
            Add to Auto Refill Schedule
          </button>
        </div>
      </div>

      <div className="">
        <div className="bg-formisPeach text-formisBlack flex items-center w-full rounded-[8px] py-[2px] shadow-md shadow-formisOrange mb-4">
          <input
            value={3}
            className="font-[Hind] text-sm font-bold bg-transparent outline-none w-[40px] text-center"
            readOnly
          />
        </div>

        <div className="flex items-center gap-1 font-[Hind] font-bold place-self-end">
          <p className="text-[12px]">₹109.96</p>
          <span className="text-[10px]">
            <s>₹115.75</s>
          </span>
        </div>
      </div>
    </div>
  );
};
