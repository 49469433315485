import { createSlice } from "@reduxjs/toolkit";

export const apiCartSlice = createSlice({
  name: "apiCart",

  initialState: {
    userCartData: null,
    userCartItems: [],
    approval: false,
    updates: null
  },

  reducers: {
    setUserCartItems: (state, action) => {
      if (Array.isArray(action.payload)) {
        state.userCartData = null;
        state.userCartItems = [];
      } else {
        state.userCartData = action.payload;
        state.userCartItems = action.payload?.data?.items || [];
      }
    },
    setApproval: (state, action) => {
      state.approval = action.payload;
    },
    setUpdates: (state, action) => {
      state.updates = action.payload
    }
  },
});

export const { setUserCartItems, setApproval, setUpdates } = apiCartSlice.actions;
export default apiCartSlice.reducer;
