import cart from "../../assets/icons/cart.svg";
import back from "../../assets/icons/back-peach.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setSearchComponent } from "../../features/searchSlice";
import { useEffect, useState } from "react";

const ViewCartButton = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isAuthenticated = localStorage.getItem("isAuthenticated");
  const { items } = useSelector((state) => state.cartItems);
  const userCart = useSelector((state) => state.apiCart.userCartItems);

  const [cartCount, setCartCount] = useState(userCart?.length || 0);
  const [cartCountLocal, setCartCountLocal] = useState(items?.length || 0);
  const [isBouncing, setIsBouncing] = useState(false);

  useEffect(() => {
    if (isAuthenticated === "true" && userCart?.length !== cartCount) {
      setIsBouncing(true);
      setCartCount(userCart?.length);
      return;
    }
    if (isAuthenticated !== "true" && items?.length !== cartCountLocal) {
      setIsBouncing(true);
      setCartCountLocal(items?.length);
      return;
    }
  }, [userCart, cartCount, items, cartCountLocal]);

  useEffect(() => {
    if (isBouncing) {
      const timeout = setTimeout(() => {
        setIsBouncing(false);
      }, 500); // Match the animation duration
      return () => clearTimeout(timeout);
    }
  }, [isBouncing]);

  return (
    <div className="relative w-fit left-0 right-0 mx-auto">
      {isAuthenticated === "true" && userCart?.length > 0 && (
        <div
          className={`relative flex items-center gap-2 justify-center mb-3 text-formisPeach w-fit mx-auto`}
          onClick={() => {
            navigate("/cart");
            dispatch(setSearchComponent(false));
          }}
        >
          <div className="bg-[#203040] w-fit p-[0.65rem] rounded-full flex items-center gap-3 shadow-cartButtons border border-[#666666]">
            <img src={cart} alt="Cart" className="w-5" />
            <div className="text-xs font-bold">View Cart</div>
            <img src={back} alt="next" className="rotate-180 w-4" />
          </div>

          <div
            className={`bg-[#203040] size-10 rounded-full flex items-center justify-center gap-3 text-[17px] font-bold shadow-cartButtons border border-[#666666]`}
          >
            <p className={`${isBouncing ? "bounce" : ""}`}>
              {userCart?.length}
            </p>
          </div>
        </div>
      )}
      {isAuthenticated !== "true" && items.length > 0 && (
        <div
          className={`relative flex items-center gap-2 justify-center mb-3 text-formisPeach`}
          onClick={() => {
            navigate("/cart");
            dispatch(setSearchComponent(false));
          }}
        >
          <div className="bg-[#203040] w-fit p-[0.65rem] rounded-full flex items-center gap-3 shadow-cartButtons border border-[#666666]">
            <img src={cart} alt="Cart" className="w-5" />
            <div className="text-xs font-bold">View Cart</div>
            <img src={back} alt="next" className="rotate-180 w-4" />
          </div>

          <div className="bg-[#203040] size-10 rounded-full flex items-center justify-center gap-3 text-[17px] font-bold shadow-cartButtons border border-[#666666]">
            <p className={`${isBouncing ? "bounce" : ""}`}>{items.length}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewCartButton;
