import { useEffect, useRef } from "react";
import customPin from "../../assets/pin.png";

const EditAddressMap = ({ editLat, editLng, setEditLat, setEditLng }) => {
  const google = window.google;

  const mapRef = useRef(null);
  const markerRef = useRef(null);
  const mapInstance = useRef(null);

  // Convert strings to numbers
  const lat = Number(editLat);
  const lng = Number(editLng);

  // Function to check if the latitude and longitude are valid numbers
  const isValidLatLng = (lat, lng) => {
    return typeof lat === "number" && !isNaN(lat) && typeof lng === "number" && !isNaN(lng);
  };

  useEffect(() => {
    if (mapRef.current && google) {
      const initialCenter = isValidLatLng(lat, lng)
        ? { lat, lng }
        : { lat: 28.48218, lng: 77.0933 };

      const mapOptions = {
        center: initialCenter,
        zoom: 15,
        disableDefaultUI: true,
        gestureHandling: "greedy",
        styles: [
            {
              featureType: "administrative",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#686868",
                },
              ],
            },
            {
              featureType: "landscape",
              elementType: "all",
              stylers: [
                {
                  color: "#f2f2f2",
                },
              ],
            },
            {
              featureType: "poi",
              elementType: "all",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              featureType: "road",
              elementType: "all",
              stylers: [
                {
                  saturation: -100,
                },
                {
                  lightness: 45,
                },
              ],
            },
            {
              featureType: "road.highway",
              elementType: "all",
              stylers: [
                {
                  visibility: "simplified",
                },
              ],
            },
            {
              featureType: "road.highway",
              elementType: "geometry.fill",
              stylers: [
                {
                  lightness: "-22",
                },
              ],
            },
            {
              featureType: "road.highway",
              elementType: "geometry.stroke",
              stylers: [
                {
                  saturation: "11",
                },
                {
                  lightness: "-51",
                },
              ],
            },
            {
              featureType: "road.highway",
              elementType: "labels.text",
              stylers: [
                {
                  saturation: "3",
                },
                {
                  lightness: "-56",
                },
                {
                  weight: "2.20",
                },
              ],
            },
            {
              featureType: "road.highway",
              elementType: "labels.text.fill",
              stylers: [
                {
                  lightness: "-52",
                },
              ],
            },
            {
              featureType: "road.highway",
              elementType: "labels.text.stroke",
              stylers: [
                {
                  weight: "6.13",
                },
              ],
            },
            {
              featureType: "road.highway",
              elementType: "labels.icon",
              stylers: [
                {
                  lightness: "-10",
                },
                {
                  gamma: "0.94",
                },
                {
                  weight: "1.24",
                },
                {
                  saturation: "-100",
                },
                {
                  visibility: "off",
                },
              ],
            },
            {
              featureType: "road.arterial",
              elementType: "geometry",
              stylers: [
                {
                  lightness: "-16",
                },
              ],
            },
            {
              featureType: "road.arterial",
              elementType: "labels.text.fill",
              stylers: [
                {
                  saturation: "-41",
                },
                {
                  lightness: "-41",
                },
              ],
            },
            {
              featureType: "road.arterial",
              elementType: "labels.text.stroke",
              stylers: [
                {
                  weight: "5.46",
                },
              ],
            },
            {
              featureType: "road.arterial",
              elementType: "labels.icon",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              featureType: "road.local",
              elementType: "geometry.fill",
              stylers: [
                {
                  weight: "0.72",
                },
                {
                  lightness: "-16",
                },
              ],
            },
            {
              featureType: "road.local",
              elementType: "labels.text.fill",
              stylers: [
                {
                  lightness: "-37",
                },
              ],
            },
            {
              featureType: "transit",
              elementType: "all",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              featureType: "water",
              elementType: "all",
              stylers: [
                {
                  color: "#b7e4f4",
                },
                {
                  visibility: "on",
                },
              ],
            },
          ],
      };

      mapInstance.current = new google.maps.Map(mapRef.current, mapOptions);

      markerRef.current = new google.maps.Marker({
        position: initialCenter,
        map: mapInstance.current,
        icon: {
          url: customPin,
          scaledSize: new google.maps.Size(30, 30),
        },
      });

      // Update marker position on map drag
      mapInstance.current.addListener("drag", () => {
        if (markerRef.current) {
          const center = mapInstance.current.getCenter();
          markerRef.current.setPosition(center);
        }
      });

      // Update editLat and editLng when the map stops moving
      mapInstance.current.addListener("idle", () => {
        const center = mapInstance.current.getCenter();
        const newLat = center.lat();
        const newLng = center.lng();

        if (isValidLatLng(newLat, newLng)) {
          setEditLat(newLat);
          setEditLng(newLng);
        }
      });
    }
  }, [lat, lng, setEditLat, setEditLng]);

  // Pan to new coordinates when editLat and editLng change
  useEffect(() => {
    if (isValidLatLng(lat, lng) && mapInstance.current) {
      const newCenter = { lat, lng };
      mapInstance.current.panTo(newCenter);
      if (markerRef.current) {
        markerRef.current.setPosition(newCenter);
      }
    }
  }, [lat, lng]);

  return (
    <div
      id="map"
      ref={mapRef}
      className="rounded-[20px] h-[300px] w-full"
    ></div>
  );
};

export default EditAddressMap;
