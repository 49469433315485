import imageNA from "../../assets/Image-NA.webp";
import discount from "../../assets/icons/discount.svg";

const ProductCard = () => {
  return (
    <div className="flex gap-4 justify-between">
      <div className="flex gap-4">
        <div className="relative shrink-0 h-[77px] w-[78px]">
          <img
            src={imageNA}
            loading="lazy"
            alt="image"
            className="h-[77px] w-[78px] rounded-[10px]"
          />
          <div className="h-[23px] w-[19px] absolute -top-[7px] z-10 -left-[1px]">
            <img src={discount} alt="discount!" className="" />
            <div className="absolute -top-1 -left-[2.5px] h-[28px] w-[23px] flex items-center justify-center">
              <p className="font-[Hind] text-[8px] text-formisBlack font-bold leading-none">
                3%
                <br /> OFF
              </p>
            </div>
          </div>
        </div>

        <div className="shrink">
          <p className="font-[Hind] text-xs font-bold mb-1">
            Crocin 240 DS Suspension Mixed Fruit Flavour
          </p>
          <div className="flex items-center gap-1">
            <div className="bg-formisOrange rounded-full">
              <span className="text-[10px] font-extrabold px-1 leading-none h-[13px] tracking-[-0.25px] flex items-center justify-center rounded-[10px] bg-gradient-to-b from-formisOrange to-formisBlack/30 border border-formisOrange">
                bottle
              </span>
            </div>
            <div className="bg-formisOrange rounded-full">
              <span className="text-[10px] font-extrabold px-2 leading-none h-[13px] tracking-[-0.25px] flex items-center justify-center rounded-[10px] bg-gradient-to-b from-formisOrange to-formisBlack/30 border border-formisOrange">
                100ml
              </span>
            </div>
          </div>
          <button className="font-[Hind] text-[10px] font-bold text-formisWhite border-b border-formisWhite leading-none">
            Add to Auto Refill Schedule
          </button>
        </div>
      </div>

      <div className="">
        <div className="bg-formisPeach text-formisBlack flex items-center w-full rounded-[8px] py-[2px] shadow-md shadow-formisOrange mb-4">
          <input
            value={3}
            className="font-[Hind] text-sm font-bold bg-transparent outline-none w-[40px] text-center"
            readOnly
          />
        </div>

        <div className="flex items-center gap-1 font-[Hind] font-bold place-self-end">
          <p className="text-[12px]">₹109.96</p>
          <span className="text-[10px]">
            <s>₹115.75</s>
          </span>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
