import cart from "../../assets/icons/cart.svg";
import surge from "../../assets/icons/surge.svg";
import receipt from "../../assets/icons/receipt.svg";
import truck from "../../assets/icons/delivery-truck.svg";
import discount from "../../assets/icons/discount-tag-peach.svg";
import { useDispatch, useSelector } from "react-redux";
import { setShowComingSoon } from "../../features/slidersSlice";

const BillDetails = ({ userCart }) => {
  const dispatch = useDispatch();
  const isAuthenticated = localStorage.getItem("isAuthenticated") === "true";

  const cartItems = useSelector((state) => state.cartItems.items);
  const activeCart = isAuthenticated ? userCart : cartItems;
  const { userCartData } = useSelector((state) => state.apiCart);

  const totalPrice = activeCart?.reduce((total, item) => {
    const price = item.selling_price_at_time || item.final_price;
    return total + item.quantity * price;
  }, 0);

  const totalMrp = activeCart.reduce((total, item) => {
    return total + item.quantity * item.mrp;
  }, 0);

  // const discountAmount = activeCart.reduce((total, item) => {
  //   return (
  //     total + item.quantity * item.discount_amount ||
  //     item.quantity * item.discounted_amount
  //   );
  // }, 0);

  return (
    <div>
      <div className="rounded-[20px] shadow-peachBox pb-16 mx-4">
        <div className="pt-[26px] border-b border-[#666666]">
          <h5 className="text-[13px] font-bold px-2 pb-2">Bill Details</h5>
        </div>

        <div className="px-2 pt-4 space-y-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-4">
              <img src={cart} alt="cart" className="w-5" />
              <div className="flex items-center gap-2">
                <p className="text-xs font-bold font-[Hind] leading-none">
                  Items total
                </p>
                <div className="font-[Hind] text-[9px] font-bold bg-formisPeach rounded-[4px] text-formisBlack flex items-center justify-center px-1 shadow-md shadow-formisOrange">
                  Saved ₹
                  {(totalMrp.toFixed(2) - totalPrice.toFixed(2)).toFixed(2)}
                </div>
                <div onClick={()=>dispatch(setShowComingSoon(true))} className="font-[Hind] text-[9px] font-bold bg-formisPeach rounded-[4px] text-formisBlack flex items-center justify-center px-1 shadow-md shadow-formisOrange">
                  Need More?
                </div>
              </div>
            </div>

            <div className="flex items-center gap-1 font-[Hind] font-bold">
              <p className="text-[12px]">₹{totalPrice.toFixed(2)}</p>
              <span className="text-[10px]">
                <s>₹{totalMrp.toFixed(2)}</s>
              </span>
            </div>
          </div>

          {isAuthenticated && userCartData?.data.coupon_discount !== "0.00" && (
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-4">
                <img src={discount} alt="cart" className="w-4 mr-1" />
                <div className="flex items-center gap-2">
                  <p className="text-xs font-bold font-[Hind] leading-none">
                    Coupon Discount
                  </p>
                </div>
              </div>

              <div className="flex items-center gap-1 font-[Hind] font-bold">
                <p className="text-[12px]">
                  ₹{userCartData?.data.coupon_discount}
                </p>
              </div>
            </div>
          )}

          <div className="flex items-center justify-between">
            <div className="flex items-center gap-4">
              <img src={truck} alt="cart" className="w-4 mr-1" />
              <div className="flex items-center gap-2">
                <p className="text-xs font-bold font-[Hind] leading-none">
                  Delivery charge
                </p>
                <div onClick={()=>dispatch(setShowComingSoon(true))} className="font-[Hind] text-[9px] font-bold bg-formisPeach rounded-[4px] text-formisBlack flex items-center justify-center px-1 shadow-md shadow-formisOrange">
                  Want it Zero?
                </div>
              </div>
            </div>

            <div className="flex items-center gap-1 font-[Hind] font-bold">
              <p className="text-[12px]">₹0.00</p>
            </div>
          </div>

          <div className="flex items-center justify-between">
            <div className="flex items-center gap-4">
              <img src={receipt} alt="cart" className="w-3 mr-2" />
              <div className="flex items-center gap-2">
                <p className="text-xs font-bold font-[Hind] leading-none">
                  Handling charge
                </p>
                <div onClick={()=>dispatch(setShowComingSoon(true))} className="font-[Hind] text-[9px] font-bold bg-formisPeach rounded-[4px] text-formisBlack flex items-center justify-center px-1 shadow-md shadow-formisOrange">
                  Make it Zero?
                </div>
              </div>
            </div>

            <div className="flex items-center gap-1 font-[Hind] font-bold">
              <p className="text-[12px]">₹0.00</p>
            </div>
          </div>

          <div className="flex items-center justify-between">
            <div className="flex items-center gap-4">
              <img src={surge} alt="cart" className="w-4 mr-1" />
              <div className="flex items-center gap-2">
                <p className="text-xs font-bold font-[Hind] leading-none">
                  Surge Fees
                </p>
                <div onClick={()=>dispatch(setShowComingSoon(true))} className="font-[Hind] text-[9px] font-bold bg-formisPeach rounded-[4px] text-formisBlack flex items-center justify-center px-1 shadow-md shadow-formisOrange">
                  Waive Off?
                </div>
              </div>
            </div>

            <div className="flex items-center gap-1 font-[Hind] font-bold">
              <p className="text-[12px]">₹0.00</p>
            </div>
          </div>
        </div>
      </div>

      <div className="relative bottom-5 bg-formisBlack px-2 shadow-peachBox rounded-[5px] py-3 mx-2">
        <div className="flex items-center justify-between mb-4">
          <h5 className="text-xs font-bold">Grand Total</h5>
          <div className="flex items-center gap-1 font-[Hind] font-bold">
            <p className="text-[12px]">
              ₹
              {isAuthenticated
                ? userCartData?.data.total
                : totalPrice.toFixed(2)}
            </p>
          </div>
        </div>

        <div className="flex items-center justify-end gap-3">
          <p className="text-[8px] font-[Hind] font-bold">
            Valid for Formis® forLife members only*
          </p>
          <div onClick={()=>dispatch(setShowComingSoon(true))} className="font-[Hind] text-[9px] font-bold bg-formisPeach rounded-[4px] text-formisBlack flex items-center justify-center px-1 shadow-md shadow-formisOrange">
            Claim Price Match*
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillDetails;
