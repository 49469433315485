import back from "../../assets/icons/back-peach.svg";
import etaImg from "../../assets/icons/eta.svg";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  addToCart,
  decrementQuantity,
  incrementQuantity,
  removeFromCart,
} from "../../features/cartSlice";
import { addToCartApi, deleteFromCartApi } from "../../api";
import imageNA from "../../assets/Image-NA.webp";
import discount from "../../assets/icons/discount.svg";
import { RiDeleteBin6Line } from "react-icons/ri";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { setUserCartItems } from "../../features/apiCartSlice";

const CategoryMain = ({ result, name }) => {
  const navigate = useNavigate();
  const { eta } = useSelector((state) => state.googleMapLocation);
  const userCart = useSelector((state) => state.apiCart.userCartItems);

  return (
    <div className="bg-formisBlack text-formisPeach pb-5">
      <div>
        <div className="flex items-center justify-between px-4">
          <h5
            onClick={() => navigate(`/categories/${name}`)}
            className="text-sm font-bold"
          >
            {name}
          </h5>
          <div className="flex items-center border border-formisPeach gap-2 px-[5px] py-[2px] rounded-full">
            <p className="text-[11px] tracking-[-0.28px] font-bold leading-none">
              {eta.eta_minutes} mins
            </p>
            <img src={etaImg} alt="ETA" />
          </div>
        </div>
        <div className="flex !gap-5 pt-6 overflow-y-hidden overflow-x-scroll scrollbar-hide pl-4 pr-4">
          {result.results.map((res, index) => (
            <ProductCard
              key={index}
              res={res}
              userCart={userCart}
            />
          ))}
        </div>
      </div>

      <button
        onClick={() => navigate(`/categories/${name}`)}
        onTouchStart={(e) =>
          e.currentTarget.classList.add("scale-95", "opacity-70")
        }
        onTouchEnd={(e) =>
          e.currentTarget.classList.remove("scale-95", "opacity-70")
        }
        className="mx-4 bg-[#203040] font-semibold text-xs h-[38px] border border-formisPeach flex items-center justify-center gap-10 w-[calc(100%-32px)] rounded-[10px] mt-5 transition-all duration-150"
      >
        <p>View all products</p>
        <img src={back} alt="View" className="rotate-180 w-[17px]" />
      </button>
    </div>
  );
};

export default CategoryMain;

const ProductCard = ({ res, userCart }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cartItem = useSelector((state) =>
    state.cartItems.items.find(
      (cartItem) => cartItem.product_id === res.product_id
    )
  );

  const userCartItem = userCart?.find(
    (item) => item.product_id === res.product_id
  );

  const isAuthenticated = localStorage.getItem("isAuthenticated");
  const [productQuantity, setProductQuantity] = useState(
    userCartItem?.quantity || 1
  );

  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    localStorage.setItem("productSlug", res.slug);
    navigate(`/product/${res.slug}`);
  };

  const handleAddToCart = () => {
    if (loading) return;
    if (isAuthenticated === "true") {
      handleAddToCartApiCall(productQuantity);
    } else dispatch(addToCart(res));
  };

  const handleDecrement = () => {
    if (loading) return;
    if (productQuantity > 1) {
      const updatedQuantity = productQuantity - 1;
      setProductQuantity(updatedQuantity);
      handleAddToCartApiCall(updatedQuantity);
    } else {
      handleDeleteApiCall();
    }
  };

  const handleReduxDecrement = () => {
    if (cartItem?.quantity === 1) dispatch(removeFromCart(cartItem));
    if (cartItem?.quantity > 1) {
      dispatch(decrementQuantity({ product_id: res.product_id }));
    }
  };

  const handleIncrement = () => {
    if (loading) return;
    const updatedQuantity = productQuantity + 1;
    setProductQuantity(updatedQuantity);
    if (isAuthenticated === "true") {
      handleAddToCartApiCall(updatedQuantity);
      // dispatch(incrementQuantity({ product_id: res.product_id }));
    } else dispatch(incrementQuantity({ product_id: res.product_id }));
  };

  const handleAddToCartApiCall = async (quantity) => {
    const access = localStorage.getItem("accessToken");
    setLoading(true);
    try {
      const response = await fetch(addToCartApi, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
        body: JSON.stringify({
          cart_data: [
            {
              product_id: res.product_id,
              quantity,
            },
          ],
        }),
      });

      const data = await response.json();

      if (response.ok) {
        // dispatch(addToCart(res));
        dispatch(setUserCartItems(data));
        // localStorage.setItem("userCart", true)
      } else {
      }
    } catch (error) {
      // console.error("Failed to add to cart:", error);
    } finally {
      setLoading(false); // Stop loader
    }
  };

  const handleDeleteApiCall = async () => {
    const access = localStorage.getItem("accessToken");
    setLoading(true);
    try {
      const response = await fetch(deleteFromCartApi, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
        body: JSON.stringify({
          product_ids: [res.product_id],
        }),
      });

      const data = await response.json();

      if (response.ok) {
        dispatch(removeFromCart(cartItem));
        dispatch(setUserCartItems(data));
      } else {
      }
    } catch (error) {
      // console.error("Failed to delete:", error);
    } finally {
      setLoading(false); // Stop loader
    }
  };

  return (
    <div>
      <div className="relative mb-4">
        <div
          onClick={handleClick}
          className="p-[5px] bg-white rounded-[10px] h-[144px] w-[145.65px]"
        >
          <img
            src={
              res.image_url !== "NA" &&
              res.image_url !==
                "http://34.131.96.18/wp-content/uploads/2024/11/Image-NA.png"
                ? res.image_url
                : imageNA
            }
            loading="lazy"
            className="aspect-square rounded-[10px]"
            alt={res.name}
          />
          <div className="h-[28px] w-[23px] absolute -top-[7px] -left-[1px]">
            <img src={discount} alt="discount!" className="" />
            <div className="absolute top-0 left-0 h-[28px] w-[23px] flex items-center justify-center">
              <p className="font-[Hind] text-[10px] text-formisBlack font-bold leading-none">
                {parseInt(Math.floor(res.discount_percentage.slice(0, -1)))}%
                <br /> OFF
              </p>
            </div>
          </div>
        </div>

        {isAuthenticated === "true" ? (
          <div className="absolute -bottom-3 -right-2 font-bold rounded-[10px] text-sm bg-formisWhite border border-formisBlack text">
            {userCartItem ? (
              <div className="bg-formisPeach text-formisBlack h-[37px] rounded-[10px] border border-formisBlack absolute -bottom-3 -right-0 flex gap-2 items-center mb-3">
                <button
                  disabled={loading}
                  className="pl-3 py-2"
                  onClick={handleDecrement}
                >
                  {userCartItem?.quantity === 1 || productQuantity === 1 ? (
                    <RiDeleteBin6Line className="text-formisBlack/50" />
                  ) : (
                    <IoIosArrowDown className="rotate-90" />
                  )}
                </button>
                {!loading ? (
                  <input
                    type="number"
                    min="1"
                    value={userCartItem?.quantity}
                    readOnly
                    className="max-w-4 bg-transparent rounded-[5px] text-center outline-none"
                  />
                ) : (
                  <div className="loader2"></div>
                )}
                <button className="pr-3 py-2" onClick={handleIncrement}>
                  <IoIosArrowUp className="rotate-90" />
                </button>
              </div>
            ) : (
              <div
                onClick={handleAddToCart}
                className="absolute -bottom-0 right-1 h-[37px] font-bold rounded-[10px] text-sm bg-formisPeach/90 text-formisBlack"
              >
                <button className="h-full w-[79.65px] text-lg font-semibold rounded-[10px]">
                  ADD
                </button>
              </div>
            )}
          </div>
        ) : (
          <div className="absolute -bottom-3 -right-2 font-bold rounded-[10px] text-sm bg-formisWhite border border-formisBlack text">
            {cartItem ? (
              <div className="bg-formisPeach text-formisBlack h-[37px] rounded-[10px] border border-formisBlack absolute -bottom-3 -right-0 flex gap-2 items-center mb-3">
                <div className="pl-3 py-2" onClick={handleReduxDecrement}>
                  {cartItem?.quantity === 1 ? (
                    <RiDeleteBin6Line className="text-formisBlack/50" />
                  ) : (
                    <IoIosArrowDown className="rotate-90" />
                  )}
                </div>
                <input
                  type="number"
                  min="1"
                  value={cartItem?.quantity}
                  readOnly
                  className="max-w-4 bg-transparent rounded-[5px] text-center outline-none"
                />
                <div className="pr-3 py-2" onClick={handleIncrement}>
                  <IoIosArrowUp className="rotate-90" />
                </div>
              </div>
            ) : (
              <div
                onClick={handleAddToCart}
                className="absolute -bottom-0 right-1 h-[37px] font-bold rounded-[10px] text-sm bg-formisPeach/90 text-formisBlack"
              >
                <button className="h-full w-[79.65px] text-lg font-semibold rounded-[10px]">
                  ADD
                </button>
              </div>
            )}
          </div>
        )}
      </div>

      <div className="flex items-center gap-1 mb-[6px]">
        {res.package && (
          <div className="bg-formisOrange rounded-full">
            <span className="text-[10px] font-extrabold px-1 leading-none h-[13px] tracking-[-0.25px] flex items-center justify-center rounded-[10px] bg-gradient-to-b from-formisOrange to-formisBlack/70 border border-formisOrange">
              {res.package}
            </span>
          </div>
        )}
      </div>

      <div className="flex w-full items-center justify-between">
        <div>
          <p
            onClick={handleClick}
            className="font-[Hind] font-[700] text-[13px] leading-4"
          >
            {res.name.length > 42 ? res.name.slice(0, 42) + "..." : res.name}
          </p>
          <div className="flex items-center gap-2 font-[Hind]">
            <div className="flex items-center gap-1">
              <p className="text-[12px] font-[600]">
                ₹{res.final_price.toFixed(2)}
              </p>
              <span className="text-[10px] font-[600]">
                MRP <s>₹{res.mrp.toFixed(2)}</s>
              </span>
            </div>
            <div className="h-[23px] w-[19px] relative">
              <img src={discount} alt="discount!" />
              <div className="absolute -top-[2px] -left-[2px] h-[28px] w-[23px] flex items-center justify-center">
                <p className="font-[Hind] text-[8px] text-formisBlack font-bold leading-none">
                  {parseInt(Math.floor(res.discount_percentage.slice(0, -1)))}%
                  <br /> OFF
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
