import { useState } from "react";

export const useGeolocation = () => {
  const [locationInfo, setLocationInfo] = useState(null);
  const [locationError, setLocationError] = useState(null);

  const getGeolocation = () => {
    if (!navigator.geolocation) {
      setLocationError("Geolocation is not supported by your browser.");
      return;
    }

    setLocationError(null)

    navigator.geolocation.getCurrentPosition(
      (res) => {
        setLocationInfo(res.coords);
        setLocationError(null);
      },
      (err) => {
        // console.error("Geolocation error:", err);
        setLocationError(err);
      }
    );
  };

  return { locationInfo, locationError, getGeolocation };
};