import { createSlice } from "@reduxjs/toolkit";

export const mapSlice = createSlice({
  name: "googleMapLocation",

  initialState: {
    coordinates: {
      lat: 28.48218,
      lng: 77.0933,
    },
    first: "",
    addressPerson: "",
    addressType: "",
    address: "",
    address2: "",
    city: "",
    state: "",
    pinCode: "",
    nearbyLandmark: "",
    contactName: "",
    contactNumber: "",
    addressId: null,

    eta: "",
    locationPermission: null,

    addressStep: null,
    previousStep: null,
    addressSearchQuery: "",
    isAddressSliderOpen: false,
    savedAddresses: null,
    loading: false
  },

  reducers: {
    setCoordinates: (state, action) => {
      state.coordinates.lat = action.payload.lat;
      state.coordinates.lng = action.payload.lng;
    },
    setAddressDetails: (state, action) => {
      state.first = action.payload.first;
      state.address = action.payload.address;
      state.address2 = action.payload.address2;
      state.city = action.payload.city;
      state.state = action.payload.state;
      state.pinCode = action.payload.pinCode;
      state.addressType = action.payload.addressType;
      state.addressPerson = action.payload.addressPerson;
      state.nearbyLandmark = action.payload.nearbyLandmark;
      state.contactName = action.payload.contactName;
      state.contactNumber = action.payload.contactNumber;
      state.addressId = action.payload.addressId;
    },

    setEta: (state, action) => {
      state.eta = action.payload;
    },
    toggleIsAddressSliderOpen: (state, action) => {
      state.isAddressSliderOpen = action.payload;
    },
    setAddressStep: (state, action) => {
      state.previousStep = state.addressStep;
      state.addressStep = action.payload;
    },
    setLocationPermission: (state, action) => {
      state.locationPermission = action.payload;
    },
    setAddressSearchQuery: (state, action) => {
      state.addressSearchQuery = action.payload;
    },
    setSavedAddresses: (state, action) => {
      state.savedAddresses = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    }
  },
});

export const {
  setCoordinates,
  setAddressDetails,
  toggleIsAddressSliderOpen,
  setAddressStep,
  setEta,
  setLocationPermission,
  setAddressSearchQuery,
  setSavedAddresses,
  setLoading
} = mapSlice.actions;
export default mapSlice.reducer;
