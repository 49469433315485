import { useEffect, useState, useCallback } from "react";
import { weatherApi } from "../api";

export const useWeather = (locationInfo) => {
  const [weatherData, setWeatherData] = useState();

  const fetchWeather = useCallback(async () => {
    const myHeaders = new Headers();

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const latitude = locationInfo?.latitude || 28.48218399;
    const longitude = locationInfo?.longitude || 77.09330629;

    const apiEndpoint = weatherApi + `lat=${latitude}` + `long=${longitude}`;

    try {
      const response = await fetch(apiEndpoint, requestOptions);
      const data = await response.json();
      if (response.ok) {
        setWeatherData(data);
        // console.log("Weather called", data)
      }
    } catch (err) {
      // console.log("Weather API Error: ", err);
    }
  }, [locationInfo]);

  useEffect(() => {
    if (locationInfo?.latitude && locationInfo?.longitude) {
      fetchWeather();
    }
  }, [locationInfo, fetchWeather]);

  return { weatherData };
};
