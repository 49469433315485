import { useDispatch, useSelector } from "react-redux";

import shop from "../assets/icons/shop.svg";
import labTests from "../assets/icons/lab-tests.svg";
import emergency from "../assets/icons/ambulance.svg";
import doctors from "../assets/icons/doctors.svg";
import healthId from "../assets/icons/health-id.svg";

import discount from "../assets/icons/discount-tag.svg";
import close from "../assets/icons/close.svg";
import backPeach from "../assets/icons/back-peach.svg";
import { useEffect, useState } from "react";
import { toggleProfileComponent } from "../features/profileSlice";
import { setShowComingSoon } from "../features/slidersSlice";
import ComingSoon from "./ComingSoon";
import { Link } from "react-router-dom";
import { toggleSearchComponent } from "../features/searchSlice";

const MobileFooter = ({ searchSlider }) => {
  const dispatch = useDispatch();
  const isAuthenticated = localStorage.getItem("isAuthenticated") === "true";

  const { items } = useSelector((state) => state.cartItems);
  const userCart = useSelector((state) => state.apiCart.userCartItems);
  const { showComingSoon } = useSelector((state) => state.sliders);

  const [showProgressBar, setShowProgressBar] = useState(true);

  const toggleProfile = () => {
    dispatch(toggleProfileComponent());
  };

  const [isLearnMoreClicked, setIsLearnMoreClicked] = useState(false);
  const handleLearnMoreClick = () => {
    dispatch(setShowComingSoon(true));
    setIsLearnMoreClicked(true);
  };

  const targetValue = 1000;
  const totalValue = isAuthenticated
    ? userCart.reduce(
        (acc, item) => acc + item.selling_price_at_time * item.quantity,
        0
      )
    : items.reduce((acc, item) => acc + item.final_price * item.quantity, 0);
  const progressPercentage = Math.min((totalValue / targetValue) * 100, 100);

  const [showFormisPassDiv, setShowFormisPassDiv] = useState(true);
  useEffect(() => {
    if (!sessionStorage.getItem("learnMoreDismissed")) {
      const timer = setTimeout(() => {
        setShowFormisPassDiv(false);
        sessionStorage.setItem("learnMoreDismissed", "true");
      }, 10000);

      return () => clearTimeout(timer);
    } else {
      setShowFormisPassDiv(false);
    }
  }, []);

  useEffect(() => {
    if (showComingSoon) {
      window.history.pushState({ comingSoon: true }, "");
    }
    const handlePopState = (event) => {
      if (event.state?.comingSoon) {
        dispatch(setShowComingSoon(true));
      } else {
        dispatch(setShowComingSoon(false));
      }
    };
    window.addEventListener("popstate", handlePopState);
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [showComingSoon]);

  return (
    <footer className="text-formisBlack">
      {showProgressBar && (items.length > 0 || userCart?.length > 0) && (
        <div
          className={`relative flex items-center justify-between bg-formisPeach px-[13px] pt-2 pb-2 rounded-t-[20px]`}
        >
          <div className="flex items-center gap-2">
            <img src={discount} alt="discount!" className="w-9" />
            <div className="font-bold text-xs">
              <p>Get a voucher worth ₹1000 from Bblunt Salon</p>

              {targetValue - totalValue >= 0 ? (
                <p className="text-[11px] font-semibold mb-1">
                  Add products worth ₹
                  {Math.max(targetValue - totalValue, 0).toFixed(2)} more and it
                  is yours.
                </p>
              ) : (
                <p className="text-[11px] font-semibold mb-1">
                  Voucher Earned!
                </p>
              )}
              <div className="h-[15px] w-[242px] bg-formisBlack/60 rounded-[2px] overflow-hidden">
                <div
                  className="h-full bg-[#203040] transition-all duration-300"
                  style={{ width: `${progressPercentage}%` }}
                />
              </div>
            </div>
          </div>

          <img
            src={close}
            alt="close"
            className="w-7"
            onClick={() => setShowProgressBar(false)}
          />
        </div>
      )}

      {showFormisPassDiv && !isLearnMoreClicked && (
        <div className="flex items-center justify-between pt-2 pb-2 bg-[#203040] px-[13px] text-formisPeach font-[Hind] ">
          <div className="flex items-center gap-2">
            <img src={healthId} alt="Shop" className="w-9" />
            <div className="font-bold text-xs pt-2">
              <p>Get your FORMIS® forLife Pass today</p>
              <p className="text-[11px] font-semibold">
                Exclusive access to life events, offers and more.
              </p>
            </div>
          </div>

          <button
            onClick={handleLearnMoreClick}
            className="text-[11px] rounded-[10px] font-bold bg-formisPeach text-formisBlack px-2 py-[1px]"
          >
            Learn More
          </button>
        </div>
      )}

      {/* <div className={`flex justify-between bg-[#203040] px-[15px] md:px-10 md2:px-14 pb-3 pt-2 ${!showFormisPassDiv && "rounded-t-[20px]"}`}> */}
      <div
        className={`flex justify-between bg-[#203040] px-[15px] md:px-10 md2:px-14 pb-3 pt-2`}
      >
        {searchSlider ? (
          <div
            onClick={() => dispatch(toggleSearchComponent())}
            className="flex flex-col justify-center items-center"
          >
            <img src={backPeach} alt="Shop" className="w-9 p-1" />
            <p className="text-xs font-bold text-formisPeach">Essentials</p>
          </div>
        ) : (
          <Link to="/" className="flex flex-col justify-center items-center">
            <img src={shop} alt="Shop" className="w-9" />
            <p className="text-xs font-bold text-formisPeach">Essentials</p>
          </Link>
        )}

        <div
          className="flex flex-col justify-center items-center"
          onClick={() => dispatch(setShowComingSoon(true))}
        >
          <img src={labTests} alt="Shop" className="w-9" />
          <p className="text-xs font-bold text-formisPeach">Lab</p>
        </div>

        <div
          className="flex flex-col justify-center items-center"
          onClick={() => dispatch(setShowComingSoon(true))}
        >
          <img src={emergency} alt="Shop" className="w-9" />
          <p className="text-xs font-bold text-formisPeach">Emergency</p>
        </div>

        <div
          className="relative flex flex-col justify-center items-center"
          onClick={() => dispatch(setShowComingSoon(true))}
        >
          <img src={doctors} alt="Shop" className="w-9" />
          <p className="text-xs font-bold text-formisPeach">Doctors</p>
        </div>

        <div
          className="flex flex-col justify-center items-center"
          // onClick={toggleProfile}
          onClick={() => {
            if (searchSlider) {
              dispatch(toggleSearchComponent());
              toggleProfile();
            } else toggleProfile();
          }}
        >
          <img src={healthId} alt="Shop" className="w-9" />
          <p className="text-xs font-bold text-formisPeach">Support</p>
        </div>
      </div>

      <ComingSoon />
    </footer>
  );
};

export default MobileFooter;

{
  /* {showProgressBar && (items.length > 0 || userCart?.length > 0) && (
        <div
          className={`relative flex items-center justify-between bg-formisPeach px-[13px] py-2 rounded-t-[30px]`}
        >
          <div className="flex items-center gap-2">
            <img src={discount} alt="discount!" className="w-9" />
            <div className="font-bold text-xs">
              <p>Get a voucher worth ₹1000 from Bblunt Salon</p>
              <p className="text-[11px] font-semibold mb-1">
                Add products worth ₹320 more and it is yours.
              </p>
              <div className="h-[15px] w-[242px] bg-[#203040]" />
            </div>
          </div>

          <img
            src={close}
            alt="close"
            className="w-7"
            onClick={() => setShowProgressBar(false)}
          />
        </div>
      )} */
}

// const dispatch = useDispatch();

// const { items } = useSelector((state) => state.cartItems);
// const userCart = useSelector((state) => state.apiCart.userCartItems);
// const { showComingSoon } = useSelector((state) => state.sliders);

// const [showProgressBar, setShowProgressBar] = useState(true);
// // const [showComingSoon, setShowComingSoon] = useState(false);

// const toggleProfile = () => {
//   dispatch(toggleProfileComponent());
// };

// const [isLearnMoreClicked, setIsLearnMoreClicked] = useState(false);
// const handleLearnMoreClick = () => {
//   dispatch(setShowComingSoon(true));
//   setIsLearnMoreClicked(true);
// };
