import Accordian from "./Accordian";
// import { Link } from "react-router-dom";

const FAQ = () => {
  return (
    <section className="py-20 bg-formisBlack px-4 lg:px-[40px] xl:px-[90px] rounded-t-[25px] shadow-footer" id="faq">
      <div className="text-4xl md:text-6xl lg:text-[5.5rem] text-formisPeach font-medium mb-10 lg:mb-10">
        <h1 className="leading-[2.5rem] md:leading-[4.5rem] lg:leading-[7rem]">
          Frequently Asked Questions
          <span className="text-formisOrange">.</span>
        </h1>
      </div>

      <div className="flex flex-col gap-3">
        <Accordian title="Q: What kind of products does FORMIS deliver?">
          <p className="text-[14px] md:text-[16px] 2xl:text-[18px] 3xl:text-[20px] opacity-60">
            A: <span className="">FORMIS</span> delivers everything from daily
            health essentials to lifestyle-enhancing products . You’ll get every
            cure that contributes to making you feel as healthy as you were in
            your youthful fit —and then some.
          </p>
        </Accordian>
        <Accordian title="Q: Do you charge delivery fees for online health essentials?">
          <p className="text-[14px] md:text-[16px] 2xl:text-[18px] 3xl:text-[20px] opacity-60">
            A: Nope. At <span className="">FORMIS</span> , healthcare is
            simplified —there are no hidden fees, no delivery charges, and no
            minimum order value. Just fast, reliable service.
          </p>
        </Accordian>
        <Accordian title="Q: Do I need an app to use FORMIS?">
          <p className="text-[14px] md:text-[16px] 2xl:text-[18px] 3xl:text-[20px] opacity-60">
            A: Absolutely not. All you need is WhatsApp.{" "}
            <span className="">FORMIS</span> keeps it simple, so you never need
            to worry about downloading another app. The cure is around the
            corner, without the clutter.
          </p>
        </Accordian>
        <Accordian title="Q: How do I join FORMIS forLIFE?" id="how-to-join-forlife" >
          <p className="mb-4 text-[14px] md:text-[16px] 2xl:text-[18px] 3xl:text-[20px] opacity-60">
            A: When you order through <span className="">FORMIS</span> , you
            automatically unlock FORMIS forLIFE. That means free access to
            exclusive life-enhancing events and experiences designed to keep you
            energized and youthful fit.
          </p>
          {/* <a target="_blank"> */}
          <button
            disabled
            className="bg-formisPeach text-formisBlack border border-formisPeach rounded-full text-[12px] md:text-[14px] font-[500] h-[47px] px-4"
          >
            COMING SOON
          </button>
          {/* </a> */}
        </Accordian>
      </div>
    </section>
  );
};

export default FAQ;
