import { lazy, Suspense } from "react";

import Footer from "../../components/Footer";
import { Link, useNavigate } from "react-router-dom";

import { blogPageData, featuredMain, featured } from "./blogData";
import Header from "../../components/Header";
import Topbar from "../../components/Topbar";
import MobileFooter from "../../components/MobileFooterMenu";
import { useSelector } from "react-redux";

import cart from "../../assets/icons/cart.svg";
import back from "../../assets/icons/back-peach.svg";
import ViewCartButton from "../../components/Footer Menu Buttons/ViewCartButton";

const Search = lazy(() => import("../../components/Search"));
const Profile = lazy(() => import("../../components/Profile"));
const AddressSlider = lazy(() => import("../../components/AddressSlider"));
const SurgeSlider = lazy(() => import("../../components/SurgeSlider"));

const Blog = () => {
  return (
    <div>
      <Topbar />
      <div className="bg-[#203040] pt-12 md:pt-14">
        <Header />
      </div>

      <section className="bg-gradient-to-b from-[#203040] via-formisBlack to-formisBlack py-[80px] px-[40px] lg:px-[60px] xl:px-[90px] bg-formisBlack text-formisPeach">
        <h2 className="text-[34px] lg:text-[58px] font-medium mb-5">
          FORMIS BLOG
        </h2>

        {/* <section className="lg:flex lg:gap-[30px] flex-row-reverse items-center justify-between"> */}
        <section className="">
          {/* <div className="lg:flex lg:gap-[30px] lg:justify-between lg:w-[50%]">
            <div
              className="flex items-center justify-between bg-white text-formisBlack15 
                        py-2 px-6 mb-[30px] border rounded-[10px] lg:w-[50%]"
            >
              <input
                placeholder="Search"
                className="outline-none w-full pr-4"
                onChange={handleSearch}
              />
              <FiSearch className="text-2xl" />
            </div>
            <div
              className="flex items-center justify-between bg-white text-formisBlack15 
                        py-2 px-6 mb-[30px] border rounded-[10px] lg:w-[50%]"
            >
              Filter By Tag
            </div>
          </div> */}

          <h4 className="text-[18px] md:text-[20px] lg:text mb-[30px] font-medium lg:w-[50%]">
            Featured
          </h4>
        </section>

        <section className="md2:flex gap-[30px] pb-[60px] mb-[60px]">
          <section className="md2:w-[50%] mb-[30px] md2:mb-0">
            <Link to={`/blog/${featuredMain[0].id}`}>
              <BlogContainer
                img={featuredMain[0].img}
                title={featuredMain[0].title}
              />
            </Link>
          </section>

          <section className="flex flex-col gap-[30px] md2:w-[50%]">
            {featured.map((blog, index) => (
              <Link to={`/blog/${blog.id}`}>
                <FeaturedContainer img={blog.img} title={blog.title} />
              </Link>
            ))}
          </section>
        </section>

        <h4 className="text-[18px] md:text-[20px] lg:text mb-[40px] font-medium lg:w-[50%]">
          All Blogs
        </h4>
        <section className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-[20px] lg:gap-[30px]">
          {blogPageData.map((blog, index) => (
            <Link to={`/blog/${blog.id}`}>
              <BlogContainer img={blog.img} title={blog.title} />
            </Link>
          ))}
        </section>
      </section>

      <Footer />

      <div className="fixed z-[30] bottom-0 w-full left-0">
        <ViewCartButton />
        <MobileFooter />
      </div>

      <Suspense fallback={<div>Loading...</div>}>
        <Search />
        <Profile />
        <AddressSlider />
        <SurgeSlider />
      </Suspense>
    </div>
  );
};

export default Blog;

const BlogContainer = ({ title, img }) => {
  return (
    <div className="flex flex-col gap-[16px] group">
      <img src={img} alt="blog" className="aspect-[16/9]" />
      <div className="flex flex-col gap-[16px]">
        <h6 className="text-[12px] font-medium tracking-widest">COMPANY</h6>
        <h3 className="text-[18px] md:text-[22px] lg:text-[18px] xl:text-[22px] font-medium opacity-50 transition-opacity group-hover:opacity-100">
          {title}
        </h3>
      </div>
    </div>
  );
};

const FeaturedContainer = ({ title, img }) => {
  return (
    <div className="flex flex-col md2:flex-row gap-[16px] group">
      <img src={img} alt="blog" className="md2:w-[260px] md2:h-[146px]" />
      <div className="flex flex-col gap-[16px]">
        <h6 className="text-[12px] font-medium tracking-widest">COMPANY</h6>
        <h3 className="text-[18px] md:text-[22px] lg:text-[18px] xl:text-[22px] font-medium opacity-50 transition-opacity group-hover:opacity-100">
          {title}
        </h3>
        {/* <p className="text-[14px] md:text-[16px] lg:text-[18px] pb-[16px]">
          {desc}
        </p> */}
      </div>
    </div>
  );
};
