import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCoordinates } from "../features/mapSlice";
import customPin from "../assets/icons/pin.svg";
import Lottie from "lottie-react";
import animatedPin from "../assets/map-pin.json";

const GoogleMap = () => {
  const google = window.google;

  const [mapLoaded, setMapLoaded] = useState(false);
  const mapRef = useRef(null);
  const markerRef = useRef(null);
  const mapInstance = useRef(null);

  const { coordinates } = useSelector((state) => state.googleMapLocation);
  const dispatch = useDispatch();

  useEffect(() => {
    const checkGoogleMaps = setInterval(() => {
      if (window.google && window.google.maps) {
        setMapLoaded(true);
        clearInterval(checkGoogleMaps);
      }
    }, 1000);
  }, []);

  useEffect(() => {
    const initMap = () => {
      if (mapLoaded && mapRef.current) {
        const mapOptions = {
          center: coordinates || { lat: 28.48218, lng: 77.0933 },
          zoom: 16,
          disableDefaultUI: true,
          gestureHandling: "greedy",
          styles: [
            {
              featureType: "administrative",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#686868",
                },
              ],
            },
            {
              featureType: "landscape",
              elementType: "all",
              stylers: [
                {
                  color: "#f2f2f2",
                },
              ],
            },
            {
              featureType: "poi",
              elementType: "all",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              featureType: "road",
              elementType: "all",
              stylers: [
                {
                  saturation: -100,
                },
                {
                  lightness: 45,
                },
              ],
            },
            {
              featureType: "road.highway",
              elementType: "all",
              stylers: [
                {
                  visibility: "simplified",
                },
              ],
            },
            {
              featureType: "road.highway",
              elementType: "geometry.fill",
              stylers: [
                {
                  lightness: "-22",
                },
              ],
            },
            {
              featureType: "road.highway",
              elementType: "geometry.stroke",
              stylers: [
                {
                  saturation: "11",
                },
                {
                  lightness: "-51",
                },
              ],
            },
            {
              featureType: "road.highway",
              elementType: "labels.text",
              stylers: [
                {
                  saturation: "3",
                },
                {
                  lightness: "-56",
                },
                {
                  weight: "2.20",
                },
              ],
            },
            {
              featureType: "road.highway",
              elementType: "labels.text.fill",
              stylers: [
                {
                  lightness: "-52",
                },
              ],
            },
            {
              featureType: "road.highway",
              elementType: "labels.text.stroke",
              stylers: [
                {
                  weight: "6.13",
                },
              ],
            },
            {
              featureType: "road.highway",
              elementType: "labels.icon",
              stylers: [
                {
                  lightness: "-10",
                },
                {
                  gamma: "0.94",
                },
                {
                  weight: "1.24",
                },
                {
                  saturation: "-100",
                },
                {
                  visibility: "off",
                },
              ],
            },
            {
              featureType: "road.arterial",
              elementType: "geometry",
              stylers: [
                {
                  lightness: "-16",
                },
              ],
            },
            {
              featureType: "road.arterial",
              elementType: "labels.text.fill",
              stylers: [
                {
                  saturation: "-41",
                },
                {
                  lightness: "-41",
                },
              ],
            },
            {
              featureType: "road.arterial",
              elementType: "labels.text.stroke",
              stylers: [
                {
                  weight: "5.46",
                },
              ],
            },
            {
              featureType: "road.arterial",
              elementType: "labels.icon",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              featureType: "road.local",
              elementType: "geometry.fill",
              stylers: [
                {
                  weight: "0.72",
                },
                {
                  lightness: "-16",
                },
              ],
            },
            {
              featureType: "road.local",
              elementType: "labels.text.fill",
              stylers: [
                {
                  lightness: "-37",
                },
              ],
            },
            {
              featureType: "transit",
              elementType: "all",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              featureType: "water",
              elementType: "all",
              stylers: [
                {
                  color: "#b7e4f4",
                },
                {
                  visibility: "on",
                },
              ],
            },
          ],
        };

        mapInstance.current = new google.maps.Map(mapRef.current, mapOptions);

        markerRef.current = new google.maps.Marker({
          position: mapOptions.center,
          map: mapInstance.current,
          icon: {
            url: customPin,
            scaledSize: new google.maps.Size(0, 0),
          },
        });
        mapInstance.current.addListener("drag", () => {
          if (markerRef.current) {
            const center = mapInstance.current.getCenter();
            markerRef.current.setPosition(center);
          }
        });
        mapInstance.current.addListener("idle", () => {
          const center = mapInstance.current.getCenter();
          const lat = center.lat();
          const lng = center.lng();

          dispatch(
            setCoordinates({
              lat: lat,
              lng: lng,
            })
          );
        });
      }
    };

    if (window.google && window.google.maps) {
      initMap();
    } else {
      window.addEventListener("load", initMap);
    }

    return () => {
      window.removeEventListener("load", initMap);
    };
  }, [mapLoaded, mapRef, dispatch]);

  useEffect(() => {
    if (coordinates && mapInstance.current) {
      mapInstance.current.panTo(coordinates);
      if (markerRef.current) {
        markerRef.current.setPosition(coordinates);
      }
    }
  }, [coordinates, window.google]);

  return (
    <div className="relative h-[440px] w-full rounded-[inherit]">
      <div className="absolute top-0 left-0 z-10 flex items-center justify-center pointer-events-none h-full w-full">
        <div className="w-[3.25rem]">
          <Lottie animationData={animatedPin} speed={0.5} />
        </div>
      </div>
      <div
        id="map"
        ref={mapRef}
        className="rounded-[inherit] h-[440px] w-full"
      ></div>
    </div>
  );
};

export default GoogleMap;


// import { useEffect, useRef, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { setCoordinates } from "../features/mapSlice";
// import Lottie from "lottie-react";
// import animatedPin from "../assets/map-pin.json";
// import { GoogleMap, useLoadScript } from "@react-google-maps/api";

// const MapComponent = () => {
//   const mapRef = useRef(null);
//   const dispatch = useDispatch();
//   const { coordinates } = useSelector((state) => state.googleMapLocation);

//   const [zoom, setZoom] = useState(16);

//   useEffect(() => {
//     console.log(coordinates);
//   }, [coordinates]);

//   const { isLoaded } = useLoadScript({
//     googleMapsApiKey: "AIzaSyCdkLiUoLbs1D0IrliCQYp-nJz8OgQV2pU",
//   });

//   const handleDragEnd = () => {
//     if (mapRef.current) {
//       const center = mapRef.current.getCenter();
//       const lat = center.lat();
//       const lng = center.lng();

//       dispatch(
//         setCoordinates({
//           lat: lat,
//           lng: lng,
//         })
//       );
//     }
//   };

//   const handleZoomChanged = () => {
//     if (mapRef.current) {
//       setZoom(mapRef.current.getZoom());
//     }
//   };

//   const mapOptions = {
//     center: coordinates || { lat: 28.48218, lng: 77.0933 },
//     zoom: zoom,
//     disableDefaultUI: true,
//     gestureHandling: "greedy",
//     styles: [
//       {
//         featureType: "administrative",
//         elementType: "labels.text.fill",
//         stylers: [
//           {
//             color: "#686868",
//           },
//         ],
//       },
//       {
//         featureType: "landscape",
//         elementType: "all",
//         stylers: [
//           {
//             color: "#f2f2f2",
//           },
//         ],
//       },
//       {
//         featureType: "poi",
//         elementType: "all",
//         stylers: [
//           {
//             visibility: "off",
//           },
//         ],
//       },
//       {
//         featureType: "road",
//         elementType: "all",
//         stylers: [
//           {
//             saturation: -100,
//           },
//           {
//             lightness: 45,
//           },
//         ],
//       },
//       {
//         featureType: "road.highway",
//         elementType: "all",
//         stylers: [
//           {
//             visibility: "simplified",
//           },
//         ],
//       },
//       {
//         featureType: "road.highway",
//         elementType: "geometry.fill",
//         stylers: [
//           {
//             lightness: "-22",
//           },
//         ],
//       },
//       {
//         featureType: "road.highway",
//         elementType: "geometry.stroke",
//         stylers: [
//           {
//             saturation: "11",
//           },
//           {
//             lightness: "-51",
//           },
//         ],
//       },
//       {
//         featureType: "road.highway",
//         elementType: "labels.text",
//         stylers: [
//           {
//             saturation: "3",
//           },
//           {
//             lightness: "-56",
//           },
//           {
//             weight: "2.20",
//           },
//         ],
//       },
//       {
//         featureType: "road.highway",
//         elementType: "labels.text.fill",
//         stylers: [
//           {
//             lightness: "-52",
//           },
//         ],
//       },
//       {
//         featureType: "road.highway",
//         elementType: "labels.text.stroke",
//         stylers: [
//           {
//             weight: "6.13",
//           },
//         ],
//       },
//       {
//         featureType: "road.highway",
//         elementType: "labels.icon",
//         stylers: [
//           {
//             lightness: "-10",
//           },
//           {
//             gamma: "0.94",
//           },
//           {
//             weight: "1.24",
//           },
//           {
//             saturation: "-100",
//           },
//           {
//             visibility: "off",
//           },
//         ],
//       },
//       {
//         featureType: "road.arterial",
//         elementType: "geometry",
//         stylers: [
//           {
//             lightness: "-16",
//           },
//         ],
//       },
//       {
//         featureType: "road.arterial",
//         elementType: "labels.text.fill",
//         stylers: [
//           {
//             saturation: "-41",
//           },
//           {
//             lightness: "-41",
//           },
//         ],
//       },
//       {
//         featureType: "road.arterial",
//         elementType: "labels.text.stroke",
//         stylers: [
//           {
//             weight: "5.46",
//           },
//         ],
//       },
//       {
//         featureType: "road.arterial",
//         elementType: "labels.icon",
//         stylers: [
//           {
//             visibility: "off",
//           },
//         ],
//       },
//       {
//         featureType: "road.local",
//         elementType: "geometry.fill",
//         stylers: [
//           {
//             weight: "0.72",
//           },
//           {
//             lightness: "-16",
//           },
//         ],
//       },
//       {
//         featureType: "road.local",
//         elementType: "labels.text.fill",
//         stylers: [
//           {
//             lightness: "-37",
//           },
//         ],
//       },
//       {
//         featureType: "transit",
//         elementType: "all",
//         stylers: [
//           {
//             visibility: "off",
//           },
//         ],
//       },
//       {
//         featureType: "water",
//         elementType: "all",
//         stylers: [
//           {
//             color: "#b7e4f4",
//           },
//           {
//             visibility: "on",
//           },
//         ],
//       },
//     ],
//   };

//   if (!isLoaded) return <div>Loading...</div>;

//   return (
//     <div className="relative h-[440px] w-full rounded-[inherit]">
//       {/* Animated Pin */}
//       <div className="absolute top-0 left-0 z-10 flex items-center justify-center pointer-events-none h-full w-full">
//         <div className="w-[3.25rem]">
//           <Lottie animationData={animatedPin} speed={0.5} />
//         </div>
//       </div>

//       {/* Google Map */}
//       <GoogleMap
//         mapContainerClassName="rounded-[inherit] h-[440px] w-full"
//         options={mapOptions}
//         onLoad={(map) => (mapRef.current = map)}
//         onDragEnd={handleDragEnd}
//         onIdle={handleDragEnd}
//         onZoomChanged={handleZoomChanged}
//       >
//       </GoogleMap>
//     </div>
//   );
// };

// export default MapComponent;