import { useEffect, useMemo, useState } from "react";
import { TiArrowSortedDown } from "react-icons/ti";

import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toggleSearchComponent } from "../features/searchSlice";
import { toggleProfileComponent } from "../features/profileSlice";
import { addressesApi, etaApi } from "../api";

import { useWeather } from "../hooks/useWeather";
import {
  setAddressDetails,
  setCoordinates,
  setEta,
  setLoading,
  setSavedAddresses,
  toggleIsAddressSliderOpen,
} from "../features/mapSlice";
import { setIsSurgeSliderOpen } from "../features/slidersSlice";

import surge from "../assets/icons/surge.svg";
//CUSTOM WEATHER ICONS
import sun from "../assets/icons/weather icons/sun.svg";
import clouds from "../assets/icons/weather icons/clouds.svg";
import brokenClouds from "../assets/icons/weather icons/broken-clouds.svg";
import fewClouds from "../assets/icons/weather icons/few-clouds.svg";
import rain from "../assets/icons/weather icons/rain.svg";
import thunderstorm from "../assets/icons/weather icons/thunderstorm.svg";
import snow from "../assets/icons/weather icons/snow.svg";
import mist from "../assets/icons/weather icons/mist.svg";

const Header = ({ transparent }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isAuthenticated = localStorage.getItem("isAuthenticated") === "true";

  const { isSurgeSliderOpen, showSurge } = useSelector(
    (state) => state.sliders
  );
  const userCart = useSelector((state) => state.apiCart.userCartItems);
  const { items } = useSelector((state) => state.cartItems);
  const { address, address2, addressType, coordinates, eta, savedAddresses } =
    useSelector((state) => state.googleMapLocation);

  // WEATHER DATA, WEATHER ICON MAPPING, WEATHER AQI MAPPING
  const locationInfo = useMemo(() => {
    return {
      latitude: coordinates.lat,
      longitude: coordinates.lng,
    };
  }, [coordinates]);
  const { weatherData } = useWeather(locationInfo);
  const [weatherIcon, setWeatherIcon] = useState(sun);
  const aqiDescriptions = {
    1: "Good",
    2: "Fair",
    3: "Moderate",
    4: "Poor",
    5: "Poor",
  };
  const weatherIcons = {
    "01d": sun,
    "01n": sun,
    "02d": fewClouds,
    "02n": fewClouds,
    "03d": clouds,
    "03n": clouds,
    "04d": brokenClouds,
    "04n": brokenClouds,
    "09d": rain,
    "09n": rain,
    "10d": rain,
    "10n": rain,
    "11d": thunderstorm,
    "11n": thunderstorm,
    "13d": snow,
    "13n": snow,
    "50d": mist,
    "50n": mist,
  };
  useEffect(() => {
    if (weatherData?.icon) {
      const iconCode = weatherData?.icon;
      setWeatherIcon(weatherIcons[iconCode]);
    }
  }, [weatherData]);
  const getAqiDescription = (aqiValue) => {
    return aqiDescriptions[aqiValue] || "Unknown";
  };
  const aqiDescription = getAqiDescription(weatherData?.aqi);

  // FETCH ETA
  const fetchETA = async (lat, lng) => {
    try {
      const response = await fetch(
        etaApi +
          `latitude=${lat || 28.48218399}&longitude=${lng || 77.09330629}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (response.ok) {
        dispatch(setEta(data));
        // console.log("Called eta")
      }
    } catch (error) {
      // console.log("eta error", error)
    }
  };
  useEffect(() => {
    if (!savedAddresses) {
      fetchETA(coordinates.lat, coordinates.lng);
    }
  }, [addressType, address, address2]);

  // DISABLE SCROLL ON OPENING ANY SURGE SLIDER OR DESKTOP HOVER MENU'S
  useEffect(() => {
    if (isSurgeSliderOpen) {
      document.documentElement.style.overflow = "hidden";
      document.body.style.overflow = "hidden";
    } else {
      document.documentElement.style.overflow = "auto";
      document.body.style.overflow = "auto";
    }
    return () => {
      document.documentElement.style.overflow = "auto";
      document.body.style.overflow = "auto";
    };
  }, [isSurgeSliderOpen]);

  const fetchAddresses = async () => {
    dispatch(setLoading(true));
    const access = localStorage.getItem("accessToken");
    try {
      const response = await fetch(addressesApi, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
      });
      const data = await response.json();
      if (response.ok) {
        dispatch(setSavedAddresses(data));
      } else if (response.status === 401) {
        localStorage.setItem("isAuthenticated", false);
      } else {
      }
    } catch {
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    fetchAddresses();
  }, []);

  //SET DEFAULT ADDRESS OR LATEST ADDRESS FROM SAVED ADDRESSES
  useEffect(() => {
    const defaultAddress = savedAddresses?.results?.find(
      (address) => address.is_default === true
    );
    dispatch(setLoading(true));

    if (defaultAddress) {
      const defaultAddressLat = parseFloat(defaultAddress.latitude);
      const defaultAddressLng = parseFloat(defaultAddress.longitude);
      dispatch(
        setAddressDetails({
          address: defaultAddress.address_line_1,
          address2: defaultAddress.address_line_2,
          addressType: defaultAddress.name,
          addressId: defaultAddress.id,
        })
      );
      fetchETA(defaultAddressLat, defaultAddressLng);
      return;
    }
    if (savedAddresses) {
      dispatch(
        setAddressDetails({
          address: savedAddresses?.results[0]?.address_line_1,
          address2: savedAddresses?.results[0]?.address_line_2,
          addressType: savedAddresses?.results[0]?.name,
          addressId: savedAddresses?.results[0]?.id,
        })
      );
      fetchETA(
        parseFloat(savedAddresses?.results[0]?.latitude),
        parseFloat(savedAddresses?.results[0]?.longitude)
      );
    }

    dispatch(setLoading(false));
  }, [savedAddresses]);

  return (
    <div className="">
      <header
        id="main-header"
        className={`relative px-4 md:px-10 md2:px-14 lg:px-10 xl:px-12 pt-[11px] pb-[10px] bg-formisPeach md:py-8 ${
          transparent
            ? "bg-transparent text-formisPeach"
            : "bg-formisPeach text-formisBlack"
        } transition-all duration-500 rounded-b-[25px]`}
      >
        <div
          className={`flex justify-between xl:grid xl:grid-cols-4 items-center w-full lg:px-0`}
        >
          <div>
            <p className="text-xs font-bold leading-none font-[Hind]">
              Essentials in
            </p>
            <div className="flex items-center gap-4">
              <p className="tracking-[0.38px] text-base font-bold leading-none">
                {eta && eta.eta_minutes} minutes
              </p>
              {showSurge?.active && (
                <div
                  onClick={() => dispatch(setIsSurgeSliderOpen(true))}
                  className="bg-[#203040] rounded-full w-fit px-2 h-[20px] flex items-center justify-center gap-1"
                >
                  <span className="text-[10px] text-formisPeach font-bold leading-none">
                    Surge Applied
                  </span>
                  <img src={surge} alt="surge" className="w-3" />
                </div>
              )}
            </div>

            {address && addressType ? (
              <div
                onClick={() => dispatch(toggleIsAddressSliderOpen(true))}
                className="flex items-center gap-1"
              >
                <p className="text-sm font-medium">
                  <span className="font-bold capitalize">{addressType} - </span>
                  {address.length > 10 ? address.slice(0, 10) + "..." : address}
                </p>
                <TiArrowSortedDown />
              </div>
            ) : (
              <p
                onClick={() => dispatch(toggleIsAddressSliderOpen(true))}
                className="font-bold flex items-center text-sm"
              >
                Add Address
                <TiArrowSortedDown className="ml-2" />
              </p>
            )}
          </div>

          {/* Desktop Hovered Menu Items */}
          <ul className="hidden xl:flex justify-center font-[500] gap-2 text-[15px] xl:text-[17px] col-span-2">
            <li
              className="header-menu"
              onClick={() => navigate("/all-products")}
            >
              Shop
            </li>
            <li className="header-menu" onClick={() => navigate("/contact")}>
              Contact
            </li>
            <li className="header-menu" onClick={() => navigate("/blog")}>
              Blogs
            </li>
          </ul>

          <div className="flex items-center justify-center text-2xl gap-4 lg:gap-7 w-fit ml-auto">
            {/* Search Icon Desktop */}
            <svg
              className="w-6 hidden xl:block cursor-pointer"
              viewBox="0 0 24 24"
              stroke="currentColor"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => dispatch(toggleSearchComponent())}
            >
              <path
                strokeLinecap="round"
                d="m21 21-3.636-3.636m0 0A9 9 0 1 0 4.636 4.636a9 9 0 0 0 12.728 12.728Z"
              ></path>
            </svg>
            {/* Profile Icon Desktop */}
            <svg
              className="w-6 cursor-pointer hidden xl:block"
              viewBox="0 0 24 24"
              stroke="currentColor"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => dispatch(toggleProfileComponent())}
            >
              <rect
                width="10.5"
                height="10.5"
                x="6.75"
                y="1.75"
                rx="5.25"
              ></rect>
              <path
                strokeLinecap="round"
                d="M12 15.5c1.5 0 4 .333 4.5.5.5.167 3.7.8 4.5 2 1 1.5 1 2 1 4m-10-6.5c-1.5 0-4 .333-4.5.5-.5.167-3.7.8-4.5 2-1 1.5-1 2-1 4"
              ></path>
            </svg>
            {/* Cart Icon Desktop */}
            <div className=" hidden xl:block relative">
              {!isAuthenticated && items?.length !== 0 && (
                <span className="absolute -right-2 -top-5 text-[12px] font-semibold">
                  {items?.length}
                </span>
              )}
              {isAuthenticated && userCart?.length !== 0 && (
                <span className="absolute -right-2 -top-5 text-[12px] font-semibold">
                  {userCart?.length}
                </span>
              )}
              <svg
                className="w-6 cursor-pointer"
                viewBox="0 0 24 24"
                stroke="currentColor"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => navigate("/cart")}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M1 1h.5v0c.226 0 .339 0 .44.007a3 3 0 0 1 2.62 1.976c.034.095.065.204.127.42l.17.597m0 0 1.817 6.358c.475 1.664.713 2.496 1.198 3.114a4 4 0 0 0 1.633 1.231c.727.297 1.592.297 3.322.297h2.285c1.75 0 2.626 0 3.359-.302a4 4 0 0 0 1.64-1.253c.484-.627.715-1.472 1.175-3.161l.06-.221c.563-2.061.844-3.092.605-3.906a3 3 0 0 0-1.308-1.713C19.92 4 18.853 4 16.716 4H4.857ZM12 20a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm8 0a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z"
                ></path>
              </svg>
            </div>

            <Link to="/weather" className="flex items-center gap-[18px]">
              <div>
                <img src={weatherIcon} alt="Weather" className="w-[35.5px]" />
              </div>
              <div className="flex flex-col gap-[4px] leading-none col-span-2">
                <p className="text-lg flex gap-[1px] font-bold leading-none">
                  {Math.round(weatherData?.temperature)}
                  <span className="text-[10px] relative top-[2px]">℃</span>
                </p>
                <p className="text-[11px] font-bold">AQI - {aqiDescription}</p>
              </div>
            </Link>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
