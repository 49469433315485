import { lazy, Suspense } from "react";

import JoinContact from "../components/About Page Components/JoinContact";
import Team from "../components/About Page Components/Team";
import Values from "../components/About Page Components/Values";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Topbar from "../components/Topbar";
import MobileFooter from "../components/MobileFooterMenu";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import cart from "../assets/icons/cart.svg";
import back from "../assets/icons/back-peach.svg";
import ViewCartButton from "../components/Footer Menu Buttons/ViewCartButton";

const Search = lazy(() => import("../components/Search"));
const Profile = lazy(() => import("../components/Profile"));
const AddressSlider = lazy(() => import("../components/AddressSlider"));
const SurgeSlider = lazy(() => import("../components/SurgeSlider"));

const About = () => {
  return (
    <main>
      <Topbar />
      <div className="bg-[#203040] pt-12 md:pt-14">
        <Header />
      </div>

      <div className="pt-[160px] bg-gradient-to-b from-[#203040] via-formisBlack to-formisBlack">
        <section className="text-formisPeach px-5 lg:px-9 xl:px-14 pb-[60px]">
          <h6 className="text-[12px] font-medium tracking-widest mb-5">
            ABOUT
          </h6>
          <h1 className="text-[34px] md2:text-[58px] xl:text-[72px] 3xl:text-[92px] font-medium mb-5">
            MEET FORMIS
          </h1>
        </section>
        <Team />
        <Values />
        <JoinContact />
      </div>

      <Footer />

      <div className="fixed z-[30] bottom-0 w-full left-0">
        <ViewCartButton />
        <MobileFooter />
      </div>
      <Suspense fallback={<div>Loading...</div>}>
        <Search />
        <Profile />
        <AddressSlider />
        <SurgeSlider />
      </Suspense>
    </main>
  );
};

export default About;
