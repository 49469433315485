import searchIcon from "../../assets/icons/search.svg";
import { useDispatch } from "react-redux";
import {
  setAddressStep,
  toggleIsAddressSliderOpen,
} from "../../features/mapSlice";
import LocationPageButtons from "./LocationPageButtons";
import close from "../../assets/icons/close.svg";
import { useEffect, useState } from "react";

const Main = () => {
  const dispatch = useDispatch();
  const [currentText, setCurrentText] = useState("");
  const [visible, setVisible] = useState(true);
  const list = ["Area", "Street", "City"];
  let i = 0;

  const textAnimation = () => {
    setCurrentText(list[i]);
    setVisible(true);
    setTimeout(() => setVisible(false), 1700);
    i = (i + 1) % list.length;
  };

  useEffect(() => {
    textAnimation();
    const interval = setInterval(() => {
      textAnimation();
    }, 2000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div
      id="main-location-page"
      className="overflow-scroll bg-formisPeach text-formisPeach min-h-full"
    >
      <div className="flex items-center justify-center bg-formisPeach h-[122px] w-full">
        <img
          src={close}
          alt="Close"
          onClick={() => dispatch(toggleIsAddressSliderOpen(false))}
        />
      </div>

      <div className="bg-gradient-to-b from-[#203040] via-[#203040] to-[#15171c] rounded-t-[30px] min-h-[calc(100%-122px)] pb-10">
        <h3 className="font-bold px-3 pt-10">Select Location</h3>

        <div className="my-3">
          <div
            className="mx-3 bg-formisWhite flex flex-col gap-4 rounded-[10px] mb-5"
            onClick={() => dispatch(setAddressStep(3))}
          >
            <div className="flex items-center py-[18px] px-3 gap-5">
              <img src={searchIcon} alt="Search" className="w-4" />
              <div
                className={`bg-transparent outline-none w-full font-[700] text-formisBlack placeholder:text-formisBlack text-xs ${
                  visible
                    ? "opacity-100 translate-y-0"
                    : "opacity-0 -translate-y-full"
                } transition-all`}
              >
                Search for {currentText}
              </div>
            </div>
          </div>

          <LocationPageButtons />

          {/* FAQ */}
          <div className="px-3 pt-5">
            <h3 className="font-bold mb-3">FAQ</h3>
            <div className="flex flex-col gap-8">
              <div>
                <div className="text-[10px] font-[600] bg-formisWhite text-formisBlack py-2 px-3 rounded-[10px] mb-2">
                  Residing in Delhi or New Delhi?
                </div>
                <p className="text-[11px] font-[600] px-2">
                  - Yes our Standard Delivery is serviceable in these locations.
                </p>
              </div>
              <div>
                <div className="text-[10px] font-[600] bg-formisWhite text-formisBlack py-2 px-3 rounded-[10px] mb-2">
                  Greater Noida, Noida, Bahadurgarh, Faridabad, Ghaziabad or
                  Manesar?
                </div>
                <p className="text-[11px] font-[600] px-2">
                  - Yes our Standard Delivery is serviceable in these locations.
                </p>
              </div>
              <div>
                <div className="text-[10px] font-[600] bg-formisWhite text-formisBlack py-2 px-3 rounded-[10px] mb-2">
                  Residing in Delhi or New Delhi?
                </div>
                <p className="text-[11px] font-[600] px-2">
                  - Yes our Priority Delivery is serviceable in these locations.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
