import { useSelector } from "react-redux";
import ProductCard from "./Search Product Components/ProductCard";
import ProductFAQ from "./Search Product Components/ProductFAQ";
import ProductFeatures from "./Search Product Components/ProductFeatures";
import { useEffect, useState } from "react";
import { productDataApi } from "../../api";
import AnimatedSearch from "./AnimatedSearch";
import useCartDetails from "../../hooks/useCartDetails";

const ProductPage = ({ handlePageChange }) => {
  //STORED SLUG IN LOCALSTORAGE TO SEND AS A PARAMETER IN THE API
  const slug = localStorage.getItem("productSlug");

  const userCart = useSelector((state) => state.apiCart.userCartItems);
  const [productData, setProductData] = useState();

  //FETCH THE PRODUCT DETAILS BASED ON SLUG
  const fetchProduct = async () => {
    try {
      const response = await fetch(productDataApi + slug, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = await response.json();

      if (response.ok) {
        setProductData(data);
        // console.log("Product Data", data)
      }
    } catch {}
  };

  useEffect(() => {
    fetchProduct();
  }, []);

  return (
    <div className="bg-gradient-to-b from-formisBlack to-[#203040] overflow-scroll pb-56">
      <div className="pt-2 px-2 pb-5">
        <AnimatedSearch handlePageChange={handlePageChange} />
      </div>
      {productData && (
        <div className="px-4 pb-20">
          <ProductCard
            product={productData}
            userCart={userCart}
          />
          <ProductFeatures product={productData} />
          {productData.questions !== "NA" && (
            <ProductFAQ product={productData} />
          )}
        </div>
      )}
    </div>
  );
};

export default ProductPage;
