import { lazy, Suspense } from "react";

import Topbar from "../components/Topbar";
import Header from "../components/Header";
import SearchBar from "../components/Main Page Components/SearchBar";
import CartProducts from "../components/Checkout Cart Components/CartProducts";
import BillDetails from "../components/Checkout Cart Components/BillDetails";
import close from "../assets/icons/close.svg";

import useCartDetails from "../hooks/useCartDetails";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import CartProductsLocal from "../components/Checkout Cart Components/CartProductsLocal";
import MobileFooter from "../components/MobileFooterMenu";

import { categoryProductsApi, removeCouponApi } from "../api";
import Coupons from "../components/Checkout Cart Components/Coupons";
import { setShowComingSoon } from "../features/slidersSlice";
import CategoryMain from "../components/Main Page Components/CategoryMain";
import PlaceOrderButton from "../components/Footer Menu Buttons/PlaceOrderButton";

const Search = lazy(() => import("../components/Search"));
const Profile = lazy(() => import("../components/Profile"));
const AddressSlider = lazy(() => import("../components/AddressSlider"));
const SurgeSlider = lazy(() => import("../components/SurgeSlider"));
const PendingApproval = lazy(() =>
  import("../components/Checkout Cart Components/PendingApproval")
);

const CheckoutCart = () => {
  const dispatch = useDispatch();
  const isAuthenticated = localStorage.getItem("isAuthenticated");
  const userCart = useSelector((state) => state.apiCart.userCartItems);
  const userCartData = useSelector((state) => state.apiCart.userCartData);
  const { getCartDetails } = useCartDetails();
  const { approval } = useSelector((state) => state.apiCart);

  const [showCouponPopup, setShowCouponPopup] = useState(false);


  const removeCoupon = async () => {
    const access = localStorage.getItem("accessToken");
    try {
      const response = await fetch(removeCouponApi, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
      });
      // const data = await response.json();
      if (response.ok) {
        getCartDetails();
        // dispatch(setUserCartItems(data));
      }
    } catch {}
  };

  useEffect(() => {
    if (approval) {
      document.documentElement.style.overflow = "hidden";
      document.body.style.overflow = "hidden";
    } else {
      document.documentElement.style.overflow = "auto";
      document.body.style.overflow = "auto";
    }
    return () => {
      document.documentElement.style.overflow = "auto";
      document.body.style.overflow = "auto";
    };
  }, [approval]);

  const categories = [
    "Skin, hair & oral care",
    "Stomach care",
    "Sexual wellness",
    "Feminine Care",
    "Fitness Supplements",
  ];

  const [results, setResults] = useState([]);
  const fetchCategory = async () => {
    try {
      const responses = await Promise.all(
        categories.map((category) =>
          fetch(categoryProductsApi + category).then((response) => {
            if (!response.ok) {
              throw new Error(`Failed to fetch for parameter: ${category}`);
            }
            return response.json();
          })
        )
      );

      setResults(responses);
    } catch {}
  };

  useEffect(() => {
    fetchCategory();
  }, []);

  return (
    <div className="relative bg-formisBlack min-h-screen pb-80 overflow-y-scroll overflow-x-hidden">
      <Topbar />
      <div className="bg-formisBlack pt-12 md:pt-14">
        <Header />
      </div>

      <div className="pt-2 bg-formisBlack mb-6">
        <SearchBar bg="bg-formisBlack" showUpload={false} />
      </div>

      {isAuthenticated === "true" ? (
        <CartProducts userCart={userCart} getCartDetails={getCartDetails} />
      ) : (
        <CartProductsLocal getCartDetails={getCartDetails} />
      )}

      <div className="px-4 mt-14 mb-10 flex flex-col items-end">
        <div className="bg-[#203040] font-semibold text-xs h-[38px] border border-formisPeach flex items-center justify-between gap-10 w-full rounded-[10px] pl-3 pr-[6px] mb-3">
          <p className="text-xs font-semibold text-formisPeach">
            Redeem Coupons
          </p>
          {userCartData?.data?.applied_coupon ? (
            <div className="relative font-[Hind] font-bold text-[11px] bg-formisPeach h-[28px] flex items-center justify-center px-4 rounded-[8px] shadow-md shadow-formisOrange">
              {userCartData?.data?.applied_coupon.code}
              <img
                onClick={removeCoupon}
                src={close}
                alt="Clear"
                className="w-3 absolute -top-[1px] right-[1px]"
              />
            </div>
          ) : (
            <button
              onClick={() => setShowCouponPopup(true)}
              className="w-fit font-[Hind] text-[10px] font-bold text-formisWhite border-b border-formisWhite leading-none"
            >
              View All Available Coupons
            </button>
          )}
        </div>
        {userCartData?.data?.applied_coupon && (
          <button
            onClick={() => setShowCouponPopup(true)}
            className="w-fit font-[Hind] text-[10px] font-bold text-formisWhite border-b border-formisWhite leading-none"
          >
            View All Available Coupons
          </button>
        )}
      </div>

      <div className="text-formisPeach">
        <BillDetails userCart={userCart} getCartDetails={getCartDetails} />
      </div>

      <div className="px-4 my-10 flex flex-col items-end">
        <div className="bg-[#203040] font-semibold text-xs h-[38px] border border-formisPeach flex items-center justify-between gap-10 w-full rounded-[10px] pl-3 pr-[6px] mb-3">
          <p className="text-xs font-semibold text-formisPeach">
            Get FORMIS® forLife Pass
          </p>
          <div
            onClick={() => dispatch(setShowComingSoon(true))}
            className="font-[Hind] font-bold text-[11px] bg-formisPeach h-[28px] flex items-center justify-center px-4 rounded-[8px] shadow-md shadow-formisOrange"
          >
            View Details
          </div>
        </div>
        {/* <button className="w-fit font-[Hind] text-[10px] font-bold text-formisWhite border-b border-formisWhite leading-none">
          View All Available Coupons
        </button> */}
      </div>

      {results.map((res, index) => (
        <CategoryMain key={index} result={res} name={categories[index]} />
      ))}

      <Coupons
        setShowCouponPopup={setShowCouponPopup}
        showCouponPopup={showCouponPopup}
      />

      <div className="fixed z-[30] bottom-0 w-full left-0">
        <PlaceOrderButton />
        <MobileFooter />
      </div>

      <Suspense fallback={<div>Loading...</div>}>
        <AddressSlider />
        <Search />
        <Profile />
        <SurgeSlider />
        <PendingApproval />
      </Suspense>
    </div>
  );
};

export default CheckoutCart;
