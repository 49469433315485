import { useNavigate } from "react-router-dom";
import { useWeather } from "../hooks/useWeather";
import { useSelector } from "react-redux";
import { useMemo, useState, useEffect } from "react";
import close from "../assets/icons/close-peach.svg";

//CUSTOM WEATHER ICONS
import sun from "../assets/icons/weather icons peach/sun.svg";
import clouds from "../assets/icons/weather icons peach/clouds.svg";
import brokenClouds from "../assets/icons/weather icons peach/broken-clouds.svg";
import fewClouds from "../assets/icons/weather icons peach/few-clouds.svg";
import rain from "../assets/icons/weather icons peach/rain.svg";
import thunderstorm from "../assets/icons/weather icons peach/thunderstorm.svg";
import snow from "../assets/icons/weather icons peach/snow.svg";
import mist from "../assets/icons/weather icons peach/mist.svg";

const WeatherDetails = () => {
  const navigate = useNavigate();
  const { coordinates } = useSelector((state) => state.googleMapLocation);
  const locationInfo = useMemo(() => {
    return {
      latitude: coordinates.lat,
      longitude: coordinates.lng,
    };
  }, [coordinates]);

  const { weatherData } = useWeather(locationInfo);
  const [weatherIcon, setWeatherIcon] = useState(sun);

  const aqiDescriptions = {
    1: "Good",
    2: "Fair",
    3: "Moderate",
    4: "Poor",
    5: "Poor",
  };

  const weatherIcons = {
    "01d": sun,
    "01n": sun,
    "02d": fewClouds,
    "02n": fewClouds,
    "03d": clouds,
    "03n": clouds,
    "04d": brokenClouds,
    "04n": brokenClouds,
    "09d": rain,
    "09n": rain,
    "10d": rain,
    "10n": rain,
    "11d": thunderstorm,
    "11n": thunderstorm,
    "13d": snow,
    "13n": snow,
    "50d": mist,
    "50n": mist,
  };

  useEffect(() => {
    if (weatherData?.icon) {
      const iconCode = weatherData?.icon;
      setWeatherIcon(weatherIcons[iconCode]);
    }
  }, [weatherData]);

  const getAqiDescription = (aqiValue) => {
    return aqiDescriptions[aqiValue] || "Unknown";
  };
  const aqiDescription = getAqiDescription(weatherData?.aqi);

  const [currentTime, setCurrentTime] = useState("");

  // const formatTime = (date) => {
  //   let hours = date.getHours();
  //   let minutes = date.getMinutes();
  //   const ampm = hours >= 12 ? "PM" : "AM";
  //   hours = hours % 12 || 12;
  //   minutes = minutes < 10 ? `0${minutes}` : minutes;
  //   return `${hours}:${minutes} ${ampm}`;
  // };

  const formatTime = (date) => {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;
    hours = hours < 10 ? `0${hours}` : hours;
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${hours}:${minutes} ${ampm}`;
  };

  useEffect(() => {
    const updateClock = () => {
      const now = new Date();
      setCurrentTime(formatTime(now));
    };

    updateClock();
    const interval = setInterval(updateClock, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="bg-[#203040] h-[100dvh] w-full px-4">
      <div className="text-center pt-14 pb-12">
        <img
          src={close}
          alt="Close"
          className="mx-auto mb-10"
          onClick={() => navigate(-1)}
        />
        <p className="text-formisWhite text-[10px] font-bold">
          Weather Updates are provided by Open Weather using API, <br /> it is
          not a property of FORMIS®.
        </p>
      </div>

      <div className="flex items-start justify-between gap-2 px-4">
        <img
          src={weatherIcon}
          alt={weatherData?.description}
          className="w-28"
        />
        <div>
          <h2 className="text-formisPeach text-7xl font-bold mb-4">
            {weatherData?.temperature}
            <span className="relative bottom-9 text-xl">℃</span>
          </h2>

          <div className="bg-formisWhite text-xl w-fit px-4 py-[2px] mx-auto rounded-[10px] font-semibold">
            AQI - {aqiDescription}
          </div>
        </div>
      </div>

      <div className="mt-16">
        <p className="text-center text-formisWhite font-bold text-sm">
          When the Air Quality Index (AQI) is labeled as “poor,” it means that
          the air contains a high level of pollutants, which can cause
          discomfort or health issues, especially for sensitive groups such as
          children, the elderly, and individuals with pre-existing respiratory
          or heart conditions.
        </p>
      </div>

      <div className="text-center mt-32">
        <h2 className="text-7xl font-bold text-formisPeach">{currentTime}</h2>
      </div>
    </div>
  );
};

export default WeatherDetails;
