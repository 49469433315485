import closePeach from "../../assets/icons/close-peach.svg";
import { applyCouponApi, availableCouponsApi } from "../../api";
import { useEffect, useState } from "react";
import useCartDetails from "../../hooks/useCartDetails";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";

const Coupons = ({ showCouponPopup, setShowCouponPopup }) => {
  const isAuthenticated = localStorage.getItem("isAuthenticated");
  const navigate = useNavigate();
  const [availableCoupons, setAvailableCoupons] = useState();
  const { getCartDetails } = useCartDetails();

  useEffect(() => {
    if (showCouponPopup) {
      document.documentElement.style.overflow = "hidden";
    } else {
      document.documentElement.style.overflow = "auto";
    }
    return () => {
      document.documentElement.style.overflow = "auto";
    };
  }, [showCouponPopup]);

  const getAvailableCoupons = async () => {
    try {
      const response = await fetch(availableCouponsApi, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      if (response.ok) {
        // console.log(data);
        setAvailableCoupons(data);
      }
    } catch {}
  };

  useEffect(() => {
    getAvailableCoupons();
  }, []);

  const applyCoupon = async (couponCode) => {
    const access = localStorage.getItem("accessToken");
    try {
      const response = await fetch(applyCouponApi, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
        body: JSON.stringify({
          coupon_code: couponCode,
        }),
      });
      const data = await response.json();
      if (response.ok) {
        getCartDetails();
        console.log(data);
      } else {
        toast(data.error);
      }
    } catch (err) {}
  };

  return (
    <div
      className={`${
        showCouponPopup ? "opacity-100" : "opacity-0 -z-50 pointer-events-none"
      } transition-opacity duration-300 fixed top-0 left-0 z-[31] h-[100dvh] w-full bg-formisBlack/80 flex flex-col items-center justify-center text-formisPeach px-3`}
    >
      <img
        onClick={() => setShowCouponPopup(false)}
        src={closePeach}
        alt="Close"
        className="w-10 mx-auto mb-[42px]"
      />
      <div className="bg-[#203040] w-full px-3 py-5 rounded-[10px]">
        <h5 className="text-sm font-bold mb-5">Available Coupons</h5>
        <div className="flex flex-col gap-3">
          {availableCoupons?.coupons?.map((coupon, index) => (
            <div
              key={index}
              className="flex items-center justify-between gap-3 shadow-peachBox  rounded-[10px] p-2"
            >
              <div>
                <p className="text-sm font-bold mb-1">{coupon.code}</p>
                <p className="text-xs font-[Hind] font-medium">
                  {coupon.description}
                </p>
              </div>
              {isAuthenticated === "true" ? (
                <button
                  onClick={() => {
                    applyCoupon(coupon.code);
                    setShowCouponPopup(false);
                  }}
                  className="text-formisBlack relative font-[Hind] font-bold text-[11px] bg-formisPeach h-[28px] flex items-center justify-center px-4 rounded-[8px] shadow-md shadow-formisOrange"
                >
                  Apply
                </button>
              ) : (
                <button
                  onClick={() => navigate("/login")}
                  className="shrink-0 text-formisBlack relative font-[Hind] font-bold text-[11px] bg-formisPeach h-[28px] flex items-center justify-center px-4 rounded-[8px] shadow-md shadow-formisOrange"
                >
                  Login to Apply
                </button>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Coupons;
