import { setApproval, setUserCartItems } from "../../features/apiCartSlice";
import { Link } from "react-router-dom";
import home from "../../assets/icons/home-icon.svg";
import back from "../../assets/icons/back-peach.svg";
import {
  setAddressStep,
  toggleIsAddressSliderOpen,
} from "../../features/mapSlice";
import { toast } from "sonner";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { placeOrderApi } from "../../api";

const PlaceOrderButton = () => {
  const dispatch = useDispatch();
  const isAuthenticated = localStorage.getItem("isAuthenticated");
  const { items } = useSelector((state) => state.cartItems);
  const userCart = useSelector((state) => state.apiCart.userCartItems);
  const { addressId } = useSelector((state) => state.googleMapLocation);
  const [loading, setLoading] = useState(false);

  const handlePlaceOrder = async (e) => {
    setLoading(true);
    const access = localStorage.getItem("accessToken");
    try {
      const response = await fetch(placeOrderApi, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
        body: JSON.stringify({
          address_id: addressId,
        }),
      });
      if (response.ok) {
        dispatch(setApproval(true));
        dispatch(setUserCartItems([]));
      } else {
        // toast.error(
        //   "Something went wrong while placing your order. Please try again after some time."
        // );
      }
    } catch (error) {
      toast.error(
        "Something went wrong while placing your order. Please try again after some time."
      );
      console.log("catch error in placing order", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {isAuthenticated === "true" ? (
        <div className="relative flex items-center justify-center gap-3 mb-3">
          {addressId ? (
            <div
              className={`relative flex items-center gap-2 justify-center text-formisPeach`}
              onClick={handlePlaceOrder}
            >
              <div className="bg-[#203040] w-fit px-2 py-2 rounded-full flex items-center gap-3 shadow-cartButtons border border-[#666666]">
                <div className="size-7 border border-formisPeach rounded-full flex items-center justify-center font-[Hind] text-[11px] font-bold">
                  {isAuthenticated === "true" ? userCart.length : items.length}
                </div>
                <div className="text-xs font-bold">
                  {loading ? (
                    <div className="loader4"></div>
                  ) : (
                    "Place your order"
                  )}
                </div>
                <img src={back} alt="next" className="rotate-180 w-4" />
              </div>
            </div>
          ) : (
            <div
              className={`relative flex items-center gap-2 justify-center text-formisPeach `}
              onClick={() => {
                dispatch(setAddressStep(2));
                dispatch(toggleIsAddressSliderOpen(true));
              }}
            >
              <div className="bg-[#203040] w-fit p-[0.65rem] rounded-full flex items-center gap-3 shadow-cartButtons border border-[#666666]">
                <div className="text-xs font-bold">Select Address</div>
                <img src={back} alt="next" className="rotate-180 w-4" />
              </div>
            </div>
          )}

          <Link
            to="/"
            className="bg-[#203040] h-full border border-formisPeach p-[9px] rounded-full"
          >
            <img src={home} alt="Home" />
          </Link>
        </div>
      ) : (
        <div className="relative flex items-center justify-center gap-3 mb-3">
          <Link
            to="/login"
            className={`relative flex items-center gap-2 justify-center text-formisPeach`}
          >
            <div className="bg-[#203040] w-fit px-2 pl-3 py-3 rounded-full flex items-center gap-3 shadow-cartButtons border border-[#666666]">
              <div className="text-xs font-bold">Login to place order</div>
              <img src={back} alt="next" className="rotate-180 w-4" />
            </div>
          </Link>

          <Link
            to="/"
            className="bg-[#203040] h-full border border-formisPeach p-[9px] rounded-full"
          >
            <img src={home} alt="Home" />
          </Link>
        </div>
      )}
    </>
  );
};

export default PlaceOrderButton;

// import { Link, useNavigate } from "react-router-dom";
// import home from "../../assets/icons/home-icon.svg";
// import back from "../../assets/icons/back-peach.svg";
// import {
//   setAddressStep,
//   toggleIsAddressSliderOpen,
// } from "../../features/mapSlice";
// import { useDispatch, useSelector } from "react-redux";
// import { useEffect, useState } from "react";
// import { setApproval, setUpdates } from "../../features/apiCartSlice";

// const PlaceOrderButton = () => {
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const isAuthenticated = localStorage.getItem("isAuthenticated");
//   const { items } = useSelector((state) => state.cartItems);
//   const userCart = useSelector((state) => state.apiCart.userCartItems);
//   const { updates } = useSelector((state) => state.apiCart);
//   const { addressId } = useSelector((state) => state.googleMapLocation);
//   const [loading, setLoading] = useState(false);

//   const handlePlaceOrder = async () => {
//     const access = localStorage.getItem("accessToken");
//     try {
//       const response = await fetch(
//         "https://kitsa.co.in/api/v1/order-update/ZETA-0971-1970",
//         {
//           method: "GET",
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${access}`,
//           },
//         }
//       );
//       const data = await response.json();
//       if (response.ok) {
//         dispatch(setUpdates(data));
//         dispatch(setApproval(true));
//         console.log("updates", data);
//       } else if (response.status === 401) {
//         localStorage.setItem("isAuthenticated", false);
//       } else {
//       }
//     } catch (err) {
//       console.error(err);
//     }
//   };

//   useEffect(() => {
//     if (updates && updates.user_action_required === true)
//       navigate("/alternates");
//   }, [updates]);

//   return (
//     <>
//       {isAuthenticated === "true" ? (
//         <div className="relative flex items-center justify-center gap-3 mb-3">
//           {addressId ? (
//             <div
//               className={`relative flex items-center gap-2 justify-center text-formisPeach`}
//               onClick={handlePlaceOrder}
//             >
//               <div className="bg-[#203040] w-fit px-2 py-2 rounded-full flex items-center gap-3 shadow-cartButtons border border-[#666666]">
//                 <div className="size-7 border border-formisPeach rounded-full flex items-center justify-center font-[Hind] text-[11px] font-bold">
//                   {isAuthenticated === "true" ? userCart.length : items.length}
//                 </div>
//                 <div className="text-xs font-bold">
//                   {loading ? (
//                     <div className="loader4"></div>
//                   ) : (
//                     "Place your order"
//                   )}
//                 </div>
//                 <img src={back} alt="next" className="rotate-180 w-4" />
//               </div>
//             </div>
//           ) : (
//             <div
//               className={`relative flex items-center gap-2 justify-center text-formisPeach `}
//               onClick={() => {
//                 dispatch(setAddressStep(2));
//                 dispatch(toggleIsAddressSliderOpen(true));
//               }}
//             >
//               <div className="bg-[#203040] w-fit p-[0.65rem] rounded-full flex items-center gap-3 shadow-cartButtons border border-[#666666]">
//                 <div className="text-xs font-bold">Select Address</div>
//                 <img src={back} alt="next" className="rotate-180 w-4" />
//               </div>
//             </div>
//           )}

//           <Link
//             to="/"
//             className="bg-[#203040] h-full border border-formisPeach p-[9px] rounded-full"
//           >
//             <img src={home} alt="Home" />
//           </Link>
//         </div>
//       ) : (
//         <div className="relative flex items-center justify-center gap-3 mb-3">
//           <Link
//             to="/login"
//             className={`relative flex items-center gap-2 justify-center text-formisPeach`}
//           >
//             <div className="bg-[#203040] w-fit px-2 pl-3 py-3 rounded-full flex items-center gap-3 shadow-cartButtons border border-[#666666]">
//               <div className="text-xs font-bold">Login to place order</div>
//               <img src={back} alt="next" className="rotate-180 w-4" />
//             </div>
//           </Link>

//           <Link
//             to="/"
//             className="bg-[#203040] h-full border border-formisPeach p-[9px] rounded-full"
//           >
//             <img src={home} alt="Home" />
//           </Link>
//         </div>
//       )}
//     </>
//   );
// };

// export default PlaceOrderButton;
